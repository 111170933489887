import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import 'c3/c3.css';
import {DateRangePicker} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import * as dateFns from "date-fns";
import 'rsuite/lib/styles/index.less';
import {toast} from "react-toastify";
import {css} from "@emotion/core";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import Graph from "./graph";
import {useTranslation} from "react-i18next";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";
import {BeatLoader} from "react-spinners";

function PlotIrrigationDebuggerView(props) {
  const {t, i18n} = useTranslation();
  const [sensorDataSet, setSensorDataSet] = useState([]);
  const [actuatorDataSet, setActuatorDataSet] = useState([]);
  const [dateRange, setDataRange] = useState(null);
  const [valveDetails, setValveDetails] = useState([]);
  const [sensorDetails, setSensorDetails] = useState([]);
  const [sensorVisibleToggleIndex, setSensorVisibleToggleIndex] = useState(0);
  const [actuatorVisibleToggleIndex, setActuatorVisibleToggleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const selectedPlot = useSelector((state) => {
    return state.plotList.selectedPlot;
  });

  const handleSensorToggleChange = (index) => {
    setSensorVisibleToggleIndex(sensorVisibleToggleIndex === index ? null : index);
  };

  const handleActuatorToggleChange = (index) => {
    setActuatorVisibleToggleIndex(actuatorVisibleToggleIndex === index ? null : index);
  };

  const addToSensorDataSet = (dataArray) => {
    setSensorDataSet((prevDataSet) => [...prevDataSet, ...dataArray]);
  };

  const addToActuatorDataSet = (dataArray) => {
    setActuatorDataSet((prevDataSet) => [...prevDataSet, ...dataArray]);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    padding-bottom: 210px;
    border-color: red;
    width: fit-content;
  `;

  const onCheck = (e) => {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  const getSensorData = (sensorDetails) => {
    setIsLoading(true);
    let dataArray = [];
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());

    console.log(sensorDetails);
    sensorDetails = sensorDetails.filter(s => !["CT", "SS"].includes(s.code));
    console.log(sensorDetails);
    const promises = sensorDetails.map((sensor, i) =>
      axios
        .get(process.env.REACT_APP_HOST + `/core/kit/` + sensor.kitId + `/graph-kit-history/` + sensor.number,
          {
            params: {
              from: fromDate,
              to: toDate
            }
          })
        .then((response) => {
          let data = response.data.content;
          let object0 = {};
          if (data.valueX.length != 0 && data.valueY.length != 0) {
            object0 = {
              valueX: data.valueX,
              valueY: data.valueY,
              displayName: sensor.displayName + " [" + sensor.kitId + "]",
            };
            dataArray.push(object0);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            toast.error(t("error.SOMETHING_WENT_WRONG"));
          }
        })
    );

    Promise.all(promises)
      .then(() => {
        addToSensorDataSet(dataArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sensor data:", error);
        setIsLoading(false);
      });
  };

  const getActuatorData = (valveDetails) => {
    setIsLoading(true);
    let dataArray = [];
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    const promises = valveDetails.map((valve, i) =>
      axios
        .get(process.env.REACT_APP_HOST + `/core/kit/` + valve.kitId + `/graph-kit-action-history/` + valve.number,
          {
            params: {
              from: fromDate,
              to: toDate
            }
          }
        )
        .then((response) => {
          let data = response.data.content;
          let object0 = {};
          if (data.valueX.length != 0 && data.valueY.length != 0) {
            object0 = {
              valueX: data.valueX,
              valueY: data.valueY,
              displayName: valve.displayName + " [" + valve.kitId + "][" + valve.number + "]",
            };
            dataArray.push(object0);
          }

        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            toast.error(t("error.SOMETHING_WENT_WRONG"));
          }
        })
    );

    Promise.all(promises)
      .then(() => {
        addToActuatorDataSet(dataArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching actuator data:", error);
        setIsLoading(false);
      });
  };

  const getKitDetails = (kitId, isValve) => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + kitId)
      .then((response) => {
        let sensorDetails = response.data.content.properties;
        setSensorDetails((prevDataSet) => [...prevDataSet, ...sensorDetails]);

        if (isValve) {
          console.log(response.data.content);
          sensorDetails = response.data.content.actions;
          console.log(sensorDetails);
          sensorDetails = sensorDetails.map(e => {
            e.kitId = kitId
            return e;
          });
          console.log(sensorDetails);
          setValveDetails((prevDataSet) => [...prevDataSet, ...sensorDetails]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + selectedPlot.farmId + `/valve`)
      .then((response) => {
        let valves = response.data.content;
        console.log(valves);
        // setValveDetails(valves);
        const uniqueValves = Array.from(new Map(valves.map(item => [item.kitId, item])).values());

        console.log(uniqueValves);

        for (const valve of uniqueValves) {
          console.log(valve);
          getKitDetails(valve.kitId, valve.code);
        }

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    if (!selectedPlot || props.mode !== "Automation") {
      return;
    }
    getKitDetails(selectedPlot.kitId);
  }, [selectedPlot]);

  useEffect(() => {
    setSensorDataSet((prevDataSet) => []);
    setActuatorDataSet((prevDataSet) => []);

    console.log(sensorDetails);
    getSensorData(sensorDetails);
    getActuatorData(valveDetails);
  }, [dateRange]);

  const ToggleLayout = ({
                          title,
                          toggleIndex,
                          visibleToggleIndex,
                          onVisibleToggleIndexChange,
                          children
                        }) => {

    const [isToggle, setIsToggle] = useState(false);


    return (
      <div className="single-card m-rl-m-8">
        <div onClick={() => setIsToggle(!isToggle)}
             className={"row sa-cursor align-items-center px-3 " + (isToggle ? 'toggle-border-bottom' : '')}
             style={{height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div className={"col-auto"}>
            <div className={'toggle-title ' + (isToggle ? 'toggle-text-green' : 'text-dark-gray')}>{title}</div>
          </div>
          <div className={"ml-auto col-auto"} style={{display: 'flex', alignItems: 'center'}}>
            <div className={'toggle-btn-container'}>
              <i className={"fa " + (isToggle ? "fa-angle-up" : "fa-angle-down")}
                 color={'#91949E'}/>
            </div>
          </div>
        </div>
        {isToggle && <div className={'p-a-16'}>
          {children}
        </div>}
      </div>
    );
  };


  return (
    <>
      <div className={"sa-popup-bg "}>
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header sticky-sa-popup-header">
              <span className={"sa-model-heading"}>Debugger</span>
              <div
                className="sa-popup-close-icon"
                onClick={() => props.onClose()}
              >
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="">
              <div style={{marginLeft: 16}}>
                <DateRangePicker
                  style={{zIndex: 9999}}
                  disabledDate={(date) => date > new Date()}
                  disabled={false}
                  onChange={(e) => onCheck(e)}
                  oneTap={false}
                  onOk={(e) => onCheck(e)}
                  showWeekNumbers={true}
                  appearance={"default"}
                  placeholder={t("title.TODAY")}
                  ranges={[
                    {
                      label: "Today",
                      value: [new Date(), new Date()],
                    },
                    {
                      label: "Yesterday",
                      value: [
                        dateFns.addDays(new Date(), -1),
                        dateFns.addDays(new Date(), -1),
                      ],
                    },
                    {
                      label: "Last 7 days",
                      value: [dateFns.subDays(new Date(), 6), new Date()],
                    },
                    {
                      label: "Last 30 days",
                      value: [dateFns.subDays(new Date(), 30), new Date()],
                    },
                  ]}
                />
              </div>
              {isLoading ? (
                <div className="sweet-loading">
                  <BeatLoader
                    css={override}
                    size={18}
                    color={"#11bc4d"}
                    loading={true}
                  />
                </div>
              ) : (
                <div className="graph-box">
                  {sensorDataSet.length === 0 ? (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.THERE_ARE_NO_SENSOR_DATA_FOUND")}
                      </div>
                    </div>
                  ) : (
                    sensorDataSet.map((data, index) => (
                      <ToggleLayout
                        key={index}
                        title={data.displayName}
                        toggleIndex={index}
                        visibleToggleIndex={sensorVisibleToggleIndex}
                        onVisibleToggleIndexChange={handleSensorToggleChange}
                      >
                        <Graph
                          Xvalues={data.valueX.map(toBrowserTimeZone)}
                          Yvalues={data.valueY}
                          displayName={data.displayName}
                          subchart={false}
                          height={200}
                        />
                      </ToggleLayout>
                    ))
                  )}

                  {actuatorDataSet.length === 0 ? (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.THERE_ARE_NO_ACTUATOR_DATA_FOUND")}
                      </div>
                    </div>
                  ) : (
                    actuatorDataSet.map((data, index) => {
                      return (
                        <ToggleLayout
                          key={index}
                          title={data.displayName}
                          toggleIndex={index}
                          visibleToggleIndex={actuatorVisibleToggleIndex}
                          onVisibleToggleIndexChange={handleActuatorToggleChange}
                        >
                          <Graph
                            Xvalues={data.valueX.map(toBrowserTimeZone)}
                            Yvalues={data.valueY}
                            displayName={data.displayName}
                            subchart={false}
                            height={200}
                          />
                        </ToggleLayout>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlotIrrigationDebuggerView;
