import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import 'react-input-range/lib/css/index.css';
import {DAYS} from "../../../utils/Enum";
import {
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader,
  toggleTimePicker
} from "../../../shared/actions/setting";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateIrrigationSchedule} from "../../../utils/FormValidationRules";
import axios from "axios";
import {toast} from "react-toastify";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import * as moment from "moment-timezone";
import {convert12Hto24H, convert24to12, formatDisplayEnumValue, is24HRSTime} from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {
  dateFormat,
  toBrowserTimeZone,
  toBrowserTimeZone24,
  toServerTimeZone,
  toServerTimeZone24
} from "../../../shared/utils/utils";

const PlotIrrigationSchedule = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const dispatch = useDispatch();
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [scheduleHistory, setIrrigationHistory] = useState([]);
  const [editDays, setEditDays] = useState({});
  const [loadMore, setLoadMore] = useState(true);
  const [isTable, setTable] = useState(false);
  const [dateRange, setDataRange] = useState(null);
  const [isOverlapSchedule, setIsOverlapSchedule] = useState(false);
  const {t, i18n} = useTranslation();
  const timeZone = moment.tz.guess();
  const timePicker = useSelector(state => {
    return state.setting.timePicker
  });

  const {
    values,
    errors,
    handleChange,
    initForm,
    handleSubmit,
  } = FormHandler(saveSchedule, validateIrrigationSchedule);

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation' + '/schedule' + '/history' + '?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setIrrigationHistory(response.data.content);
        setLoadMore(false);
      })
      .catch(function (error) {
        setLoadMore(false);
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [loadMore]);


  useEffect(() => {
    if (confirmationDialog && confirmationDialog.onSuccess) {
      props.irrigation.schedules.splice(deleteIndex, 1);
      setIsLoading(true);
    }
    if (confirmationDialog && confirmationDialog.isVisible) {
      props.setIsDeleteIrrigation(true);  
    }
    if (confirmationDialog && !confirmationDialog.isVisible && !confirmationDialog.onSuccess) {
      props.setIsDeleteIrrigation(false);
    }
  }, [confirmationDialog]);

  useEffect(() => {
    setLoadMore(true);
  }, [props.plotId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));

    props.irrigation.schedules.forEach(obj => {
      obj.startTime = is24HRSTime(obj.startTime) ? obj.startTime : convert12Hto24H(obj.startTime);
      obj.stopTime = is24HRSTime(obj.stopTime) ? obj.stopTime : convert12Hto24H(obj.stopTime);
    })

    convertScheduleToServerTimeZone(props.irrigation.schedules);

    if (editIndex !== -1) {
      props.irrigation.schedules.forEach(obj => {
        if (obj.id === values.id) {

          if (timePicker.isDone) {
            obj.days = values.days;
            const browserDate = moment.tz(convert12Hto24H(values.startTime), 'HH:mm', timeZone).format("YYYY-MM-DD HH:mm:ss");
            const serverDate = toServerTimeZone(browserDate);
            obj.startTime = toServerTimeZone24(convert12Hto24H(values.startTime));
            obj.stopTime = toServerTimeZone24(convert12Hto24H(values.stopTime));
            obj.days = alignDate(obj.days, browserDate, serverDate);
          }
        }
      })
    }
    if (props.isAddIrrigation) {
      const val = {...values};
      val.id = null;
      val.plotId = props.plotId;
      const browserDate = moment.tz(convert12Hto24H(val.startTime), 'HH:mm', timeZone).format("YYYY-MM-DD HH:mm:ss");
      const serverDate = toServerTimeZone(browserDate);
      val.startTime = toServerTimeZone24(convert12Hto24H(val.startTime));
      val.stopTime = toServerTimeZone24(convert12Hto24H(val.stopTime));
      val.days = alignDate(val.days, browserDate, serverDate);
      props.irrigation.schedules.push(val);
    }
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation', props.irrigation)
      .then(function (response) {
        if (props.isAddIrrigation) {
          toast.success("Schedule created successfully");
        }
        if (editIndex >= 0) {
          toast.success("Schedule updated successfully");
        }
        if (deleteIndex >= 0) {
          toast.success("Schedule deleted successfully");
        }
        const res = response.data.content.schedules;
        convertScheduleToBrowserTimeZone(res);
        setSchedules(null);
        setSchedules(res);
        setIsLoading(false);
        setEditIndex(-1);
        setLoadMore(true);
        setDeleteIndex(-1);
        props.hide(false);
        props.changeAdd();
        dispatch(resetConfirmationDialog());
        dispatch(toggleLoader(false));
        setIsOverlapSchedule(false);
        timePicker.isDone = false;
        initForm({});
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          errors.message = error.response.data.message;
          if (error.response.data.errorCode === 'ESA2012') setIsOverlapSchedule(true);
        }
        if (props.isAddIrrigation) {
          props.irrigation.schedules.pop();
          convertScheduleToBrowserTimeZone(props.irrigation.schedules);
        }
        dispatch(toggleLoader(false));

      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
        props.setIsDeleteIrrigation(false);
        dispatch(resetConfirmationDialog());
      });
  }, [isLoading]);

  function convertScheduleToBrowserTimeZone(schedule) {
    schedule.forEach(obj => {
      const serverDate = moment.tz(obj.startTime, 'HH:mm', timeZone).format("YYYY-MM-DD HH:mm:ss");
      const browserDate = toBrowserTimeZone(serverDate);
      obj.startTime = toBrowserTimeZone24(obj.startTime);
      obj.stopTime = toBrowserTimeZone24(obj.stopTime);
      obj.days = alignDate(obj.days, serverDate, browserDate)
    })
  }

  function convertScheduleToServerTimeZone(schedule) {
    schedule.forEach(obj => {
      const browserDate = moment.tz(obj.startTime, 'HH:mm', timeZone).format("YYYY-MM-DD HH:mm:ss");
      const serverDate = toServerTimeZone(browserDate);
      obj.startTime = toServerTimeZone24(obj.startTime);
      obj.stopTime = toServerTimeZone24(obj.stopTime);
      obj.days = alignDate(obj.days, browserDate, serverDate)
    })
  }

  function alignDate(scheduleDays, fromDate, toDate) {
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const selectedDays = {};

    days.forEach(day => {
      selectedDays[day] = scheduleDays[day] || false;
    });

    var dayValues = Object.values(selectedDays).map(day => day ? 1 : 0);
    const diffDay = subtractDates(fromDate, toDate);

    if (diffDay === 1) {
      dayValues = circularShift(dayValues);
    } else if (diffDay === -1) {
      dayValues = reverseCircularShift(dayValues);
    }

    const convertedDays = dayValues.reduce((obj, val, index) => {
      obj[days[index]] = val === 1;
      return obj;
    }, {});
    return convertedDays;
  }

  function subtractDates(fromDate, toDate) {
    const [date1, time1] = fromDate.split(' ');
    const [date2, time2] = toDate.split(' ');
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const diffMillis = endDate.getTime() - startDate.getTime();

    const diffDays = Math.floor(diffMillis / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  function circularShift(arr) {
    const n = arr.length;
    const temp = arr[n - 1];
    for (let i = n - 1; i > 0; i--) {
      arr[i] = arr[i - 1];
    }
    arr[0] = temp;
    return arr;
  }

  function reverseCircularShift(arr) {
    const n = arr.length;
    const temp = arr[0];
    for (let i = 0; i < n - 1; i++) {
      arr[i] = arr[i + 1];
    }
    arr[n - 1] = temp;
    return arr;
  }

  useEffect(() => {
    if (props.irrigation) {
      convertScheduleToBrowserTimeZone(props.irrigation.schedules)
    }
  }, []);

  useEffect(() => {
    setSchedules(props.irrigation ? props.irrigation.schedules : []);
  }, [props.irrigation]);

  useEffect(() => {
    setSchedules(props.irrigation ? props.irrigation.schedules : []);
    initForm(getAddScheduleTime())
  }, [props.isAddIrrigation]);

  useEffect(() => {
    if (!schedules || schedules.length === 0) {
      return
    }
    props.irrigation.schedules = schedules;
    schedules.sort((a, b) => Date.parse('20 Aug 2000 ' + a.startTime) - Date.parse('20 Aug 2000 ' + b.startTime));
  }, [schedules]);

  useEffect(() => {
    let event = {target: {}};
    event.target.name = timePicker.fieldName;
    event.target.value = timePicker[timePicker.fieldName];
    if (event.target.value) {
      handleChange(event);
    }
  }, [timePicker]);


  function deleteSchedule(index) {
    dispatch(toggleConfirmationDialog({isVisible: true}));
    setDeleteIndex(index);
  }

  function editSchedule(index) {
    setEditIndex(index);
    props.hide(true);
    const schedule = schedules[index];
    schedule.startTime = is24HRSTime(schedule.startTime) ? convert24to12(schedule.startTime) : schedule.startTime;
    schedule.stopTime = is24HRSTime(schedule.stopTime) ? convert24to12(schedule.stopTime) : schedule.stopTime;
    initForm(schedule);
  }

  function showTimePicker(timeField) {
    const stu = schedules[editIndex];
    if (props.isAddIrrigation) {
      let time = getAddScheduleTime();
      dispatch(toggleTimePicker({
        isVisible: true,
        fieldName: timeField,
        isDone: false,
        time: !values[timeField] ? time[timeField] : values[timeField]
      }));
    } else if (editIndex > -1) {
      dispatch(toggleTimePicker({
        isVisible: true,
        fieldName: timeField,
        isDone: timePicker.isDone,
        time: values[timeField]
      }));
    } else {

      dispatch(toggleTimePicker({isVisible: true, fieldName: timeField}));
    }

  }

  function handleFormDays(day) {
    let schedule = values;
    if (!values.days) {
      values.days = {};
    }
    schedule.days[day] = !schedule.days[day];
    let event = {target: {}};
    event.target.name = "days";
    event.target.value = schedule.days;
    handleChange(event);
  }

  function saveSchedule() {
    setIsLoading(true);
  }

  function handleCancel() {
    initForm({});
    if (props.isAddIrrigation) {
      props.changeAdd();
    } else {
      props.hide(false);
      schedules[editIndex].days = {...editDays};
      setEditIndex(-1);
      setEditDays({});
      if (isOverlapSchedule) {
        props.onReload();
        setIsOverlapSchedule(false);
      }
    }

  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
    setLoadMore(true);

  }

  const cursorStyle = {cursor: "default"};

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll1");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);


  function getAddScheduleTime() {
    const time = {};
    let date = new Date();
    let option = {
      hour12: false,
      hour: "numeric",
      minute: "numeric"
    };
    time.startTime = convert24to12(date.toLocaleTimeString('en-US', option));
    date.setMinutes(date.getMinutes() + 30);
    time.stopTime = convert24to12(date.toLocaleTimeString('en-US', option));
    return time;
  }


  return (
    <div>
      {props.isAddIrrigation && (<div className={"single-card pis-border p-12"}>
          <div className={"mobile-schedule"}>
            <div className={"start-end-time"}>
              <div className="form-group m-b-16">
                <label htmlFor="email"> {t("title.START_TIME")}</label>
                <div onClick={() => showTimePicker('startTime')}>
                  <input type="text" readOnly className={"form-control time-box"} name="username"
                         placeholder={is24HRSTime(values.startTime) ? convert24to12(values.startTime) : values.startTime}/>
                  <span className="field-icon time-box"><FeatherIcon className="toggle-password" icon="clock"/></span>
                </div>
              </div>
            </div>
            <div className={"start-end-time"}>
              <div className="form-group m-b-16">
                <label htmlFor="email"> {t("title.END_TIME")}</label>
                <div onClick={() => showTimePicker('stopTime')}>
                  <input type="text" readOnly className={"form-control time-box"} name="username"
                         placeholder={is24HRSTime(values.stopTime) ? convert24to12(values.stopTime) : values.stopTime}/>
                  <span className="field-icon time-box"><FeatherIcon className="toggle-password" icon="clock"/></span>
                </div>
              </div>
            </div>
          </div>
          {errors.startTime && (
            <p className="warning-input-msg-start-t web-hide">{errors.startTime}</p>)}
          {errors.stopTime && (
            <p className="warning-input-msg-stop-t web-hide">{errors.stopTime}</p>)}

          <div className={"mobile-schedule"}>
            <button onClick={() => handleFormDays(DAYS.MONDAY)}
                    className={"btn " + (values.days && values.days.MONDAY ? "btn-sa-week-selected" : "btn-sa-week")}>M<span
              className={"mobile-hide"}>on<span className={"hide-1315"}>day</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.TUESDAY)}
                    className={"btn " + (values.days && values.days.TUESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
              className={"mobile-hide"}>ue<span className={"hide-1315"}>sday</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.WEDNESDAY)}
                    className={"btn " + (values.days && values.days.WEDNESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>W<span
              className={"mobile-hide"}>ed<span className={"hide-1315"}>nesday</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.THURSDAY)}
                    className={"btn " + (values.days && values.days.THURSDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
              className={"mobile-hide"}>hu<span className={"hide-1315"}>rsday</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.FRIDAY)}
                    className={"btn " + (values.days && values.days.FRIDAY ? "btn-sa-week-selected" : "btn-sa-week")}>F<span
              className={"mobile-hide"}>ri<span className={"hide-1315"}>day</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.SATURDAY)}
                    className={"btn " + (values.days && values.days.SATURDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
              className={"mobile-hide"}>at<span className={"hide-1315"}>urday</span></span>
            </button>
            <button onClick={() => handleFormDays(DAYS.SUNDAY)}
                    className={"btn m-r-0 " + (values.days && values.days.SUNDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
              className={"mobile-hide"}>un<span className={"hide-1315"}>day</span></span>
            </button>
          </div>

          {errors.startTime && !errors.days && (
            <p className="warning-input-msg-start mobile-hide">{errors.startTime}</p>)}
          {errors.stopTime && !errors.days && (
            <p className="warning-input-msg-stop mobile-hide">{errors.stopTime}</p>)}
          {errors.days && !errors.startTime && !errors.stopTime && (
            <p className="warning-input-msg-days">{errors.days}</p>)}
          {errors.startTime && errors.days && (
            <div className="warning-input-msg-container">
              <p className="warning-input-msg-start mobile-hide">{errors.startTime}</p>
              <p className="warning-input-msg-days-start">{errors.days}</p>
            </div>)}
          {errors.stopTime && errors.days && (
            <div className="warning-input-msg-container">
              <p className="warning-input-msg-stop mobile-hide">{errors.stopTime}</p>
              <p className="warning-input-msg-days-stop">{errors.days}</p>
            </div>)}

          <div className={"schedule-display float-r"}>
            <button className="btn btn-sa-text schedule-btn" onClick={handleCancel}>
              {t("button.CANCEL")}
            </button>
            <button className="btn btn-sa-secondary schedule-btn" onClick={handleSubmit}>
              {t("button.SAVE")}
            </button>
          </div>
          {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}

        </div>
      )}

      {schedules && schedules.map((schedule, index) => {
          if (editIndex === index)
            return <div className={"single-card pis-border p-12"} key={schedule.id}>
              <div className={"mobile-schedule"}>
                <div className={"start-end-time"}>
                  <div className="form-group m-b-16">
                    <label htmlFor="email"> {t("title.START_TIME")}</label>
                    <div onClick={() => showTimePicker('startTime')}>
                      <input type="text" readOnly className={"form-control time-box"} name="startTime"
                             placeholder={is24HRSTime(values.startTime) ? convert24to12(values.startTime) : values.startTime}/>
                      <span className="field-icon time-box"><FeatherIcon className="toggle-password" icon="clock"/></span>
                    </div>
                  </div>
                </div>
                <div className={"start-end-time"}>
                  <div className="form-group m-b-16">
                    <label htmlFor="email"> {t("title.END_TIME")}</label>
                    <div onClick={() => showTimePicker('stopTime')}>
                      <input type="text" readOnly className={"form-control time-box"} name="stopTime"
                             placeholder={is24HRSTime(values.stopTime) ? convert24to12(values.stopTime) : values.stopTime}/>
                      <span className="field-icon time-box"><FeatherIcon className="toggle-password" icon="clock"/></span>
                    </div>
                  </div>
                </div>
              </div>
              {errors.startTime && (
                <p className="warning-input-msg-start-t web-hide">{errors.startTime}</p>)}
              {errors.stopTime && (
                <p className="warning-input-msg-stop-t web-hide">{errors.stopTime}</p>)}

              <div className={"mobile-schedule"}>
                <button onClick={() => handleFormDays(DAYS.MONDAY)}
                        className={"btn " + (values.days && values.days.MONDAY ? "btn-sa-week-selected" : "btn-sa-week")}>M<span
                  className={"mobile-hide"}>on<span className={"hide-1315"}>day</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.TUESDAY)}
                        className={"btn " + (values.days && values.days.TUESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
                  className={"mobile-hide"}>ue<span className={"hide-1315"}>sday</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.WEDNESDAY)}
                        className={"btn " + (values.days && values.days.WEDNESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>W<span
                  className={"mobile-hide"}>ed<span className={"hide-1315"}>nesday</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.THURSDAY)}
                        className={"btn " + (values.days && values.days.THURSDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
                  className={"mobile-hide"}>hu<span className={"hide-1315"}>rsday</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.FRIDAY)}
                        className={"btn " + (values.days && values.days.FRIDAY ? "btn-sa-week-selected" : "btn-sa-week")}>F<span
                  className={"mobile-hide"}>ri<span className={"hide-1315"}>day</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.SATURDAY)}
                        className={"btn " + (values.days && values.days.SATURDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
                  className={"mobile-hide"}>at<span className={"hide-1315"}>urday</span></span>
                </button>
                <button onClick={() => handleFormDays(DAYS.SUNDAY)}
                        className={"btn m-r-0 " + (values.days && values.days.SUNDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
                  className={"mobile-hide"}>un<span className={"hide-1315"}>day</span></span>
                </button>
              </div>

              {errors.startTime && !errors.days && (
                <p className="warning-input-msg-start mobile-hide">{errors.startTime}</p>)}
              {errors.stopTime && !errors.days && (
                <p className="warning-input-msg-stop mobile-hide">{errors.stopTime}</p>)}
              {errors.days && !errors.startTime && !errors.stopTime && (
                <p className="warning-input-msg-days">{errors.days}</p>)}
              {errors.startTime && errors.days && (
                <div className="warning-input-msg-container">
                  <p className="warning-input-msg-start mobile-hide">{errors.startTime}</p>
                  <p className="warning-input-msg-days-start">{errors.days}</p>
                </div>)}
              {errors.stopTime && errors.days && (
                <div className="warning-input-msg-container">
                  <p className="warning-input-msg-stop mobile-hide">{errors.stopTime}</p>
                  <p className="warning-input-msg-days-stop">{errors.days}</p>
                </div>)}

              <div className={"schedule-display float-r"}>
                <button className="btn btn-sa-text schedule-btn" onClick={handleCancel}>
                  {t("button.CANCEL")}
                </button>
                <button className="btn btn-sa-secondary schedule-btn" onClick={handleSubmit}>
                  {t("button.UPDATE")}
                </button>
              </div>

              {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}
            </div>
          return <div className={"single-card pis-border p-12"} key={schedule.id}>
            <div className={"mobile-schedule web"}>
              <div className={"start-end-time"}>
                <div className="form-group m-b-16">
                  <label htmlFor="email"> {t("title.START_TIME")}</label>
                  <input disabled={true} style={cursorStyle} type="text" readOnly className={"form-control time-box"}
                         name="username"
                         placeholder={is24HRSTime(schedule.startTime) ? convert24to12(schedule.startTime) : schedule.startTime}/>
                  <span style={cursorStyle} className="field-icon time-box"><FeatherIcon className="toggle-password"
                                                                                         icon="clock"/></span>
                </div>
              </div>
              <div className={"start-end-time"}>
                <div className="form-group m-b-16">
                  <label htmlFor="email"> {t("title.END_TIME")}</label>
                  <input disabled={true} style={cursorStyle} type="text" readOnly className={"form-control"}
                         name="username"
                         placeholder={is24HRSTime(schedule.stopTime) ? convert24to12(schedule.stopTime) : schedule.stopTime}/>
                  <span style={cursorStyle} className="field-icon "><FeatherIcon className="toggle-password"
                                                                                 icon="clock"/></span>
                </div>
              </div>
            </div>
            <div className={"mobile-schedule"}>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.MONDAY ? "btn-sa-week-selected" : "btn-sa-week")}>M<span
                className={"mobile-hide"}>on<span className={"hide-1315"}>day</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.TUESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
                className={"mobile-hide"}>ue<span className={"hide-1315"}>sday</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.WEDNESDAY ? "btn-sa-week-selected" : "btn-sa-week")}>W<span
                className={"mobile-hide"}>ed<span className={"hide-1315"}>nesday</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.THURSDAY ? "btn-sa-week-selected" : "btn-sa-week")}>T<span
                className={"mobile-hide "}>hu<span className={"hide-1315"}>rsday</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.FRIDAY ? "btn-sa-week-selected" : "btn-sa-week")}>F<span
                className={"mobile-hide"}>ri<span className={"hide-1315"}>day</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn " + (schedule.days.SATURDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
                className={"mobile-hide"}>at<span className={"hide-1315"}>urday</span></span>
              </button>
              <button style={cursorStyle}
                      className={"btn m-r-0 " + (schedule.days.SUNDAY ? "btn-sa-week-selected" : "btn-sa-week")}>S<span
                className={"mobile-hide"}>un<span className={"hide-1315"}>day</span></span>
              </button>
            </div>

            <div className={"pis-icon-h"}>
              <div hidden={props.isAddIrrigation || editIndex !== -1} className={"pis-pull-right"}>
                <FeatherIcon className="h-16 btn-icon" icon="edit" onClick={() => {
                  editSchedule(index);
                  setEditDays({...schedule.days});
                }}/>
                <FeatherIcon className="h-16 btn-icon text-red" icon="trash-2" onClick={() => {
                  deleteSchedule(index)
                }}/>
              </div>
            </div>
          </div>
        }
      )}

      {!props.isAddIrrigation && schedules && schedules.length === 0 && (
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_SCHEDULES_PLEASE_ADD")}</div>
        </div>)
      }

      <div className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>
        <hr className="table-toggle-line"/>
        <div className="table-toggle-heading">{t("title.SCHEDULE_MODE_CHANGE_HISTORY")}</div>
        <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i
          className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
      </div>
      <div id={"scroll1"} className={(isTable ? "" : "hide-table")}>
        <div className={"plot-report-filter-box"}>
          <div className={"plot-report-filter m-t-26"}>
            <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                             oneTap={false} onOk={(e) => onCheck(e)}
                             showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                             ranges={[{
                               label: 'Today',
                               value: [new Date(), new Date()]
                             }, {
                               label: 'Yesterday',
                               value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                             }, {
                               label: 'Last 7 days',
                               value: [dateFns.subDays(new Date(), 6), new Date()]
                             }, {
                               label: 'Last 30 days',
                               value: [dateFns.subDays(new Date(), 30), new Date()]
                             }]}
            />
          </div>
        </div>
        <div className={'sa-table-container'}>
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.USERS")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.ACTION")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.CHANGES")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.DATE_AND_TIME")}</th>
            </tr>
            </thead>
            <tbody>
            {scheduleHistory.length > 0 && (scheduleHistory.map((history, index) =>
              <tr key={index}>
                <td className={'sa-table-data'}>{history.user ? history.user.username : 'Unknown'}</td>
                <td className={'sa-table-data'}>
                  {formatDisplayEnumValue(history.action)}
                </td>
                <td className={'sa-table-data'}>{history.info}</td>
                <td className={'sa-table-data'}>{history.actionAt}</td>
              </tr>
            ))}
            </tbody>
          </table>
          {scheduleHistory.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_SCHEDULE_HISTORIES")}</div>
            </div>)
          }
        </div>
      </div>
    </div>
  );
};

export default PlotIrrigationSchedule;
