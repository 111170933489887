import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateKitForm} from "../../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {clusterSensorsByDeviceId} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {changeToggle, toggleLoader} from "../../../shared/actions/setting";
import {TOGGLE} from "../../../utils/Enum";
import {getClusters, getFindLocation, getSelectedCoordinate, getSelectedSensor,} from "../../../actions/sensor";
import * as _ from "underscore";
import UploadKitData from "./upload-kit-data";
import PlotSettingAlert from "./plot-setting-alert";
import danger from "../../../images/alert-triangle.svg";
import valve from "../../../images/add-valve.svg";
import sensor from "../../../images/icons/router-line.svg";
import upload from "../../../images/icons/upload-blue.svg";
import ToggleLayout from "../../toggle-layout";
import DeviceSetting from "./device-setting";
import KitSetting from "./kit-setting";
import SenzagroVisionKitSetting from "./senzagro-vision-kit-setting";
import ValveSetting from "./valve-setting";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotSetting = () => {
  const {t, i18n} = useTranslation();

  const [irrigation, setIrrigation] = useState({});
  const [isEditKit, setIsEditKit] = useState(null);
  const [error, setError] = useState(null);
  const {plotId} = useParams();
  const {farmId} = useParams();
  const [kit, setKit] = useState(null);
  const [kitMap, setKitMap] = useState([]);
  const dispatch = useDispatch();
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);

  const {values, initForm} = FormHandler(submitKit, validateKitForm);

  let selectedPlot = useSelector((state) => {
    return state.plotList.selectedPlot;
  });

  const findDeviceLocation = useSelector((state) => {
    return state.sensor.findLocation;
  });

  const clustersList = useSelector((state) => {
    return state.sensor.clusters;
  });

  useEffect(() => {
    if (!selectedPlot.kitId) {
      return;
    }
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        selectedPlot.kitId
      )
      .then((res) => {
        setKit({...res.data.content});
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // toast.error(error.response.data.message);
        }
        dispatch(toggleLoader(false));
      })
      .finally();
  }, [selectedPlot.kitId]);

  function submitKit() {
    dispatch(toggleLoader(true));
    if (isEditKit) {
      axios
        .put(
          process.env.REACT_APP_HOST +
          `/user/` +
          getUserId() +
          `/plot/` +
          plotId,
          {
            kitId: values.kitId,
            name: selectedPlot.name,
            farmId: selectedPlot.farmId,
          }
        )
        .then(function (response) {
          if (!selectedPlot.kitId) {
            toast.success(t("successMsg.KIT_UPDATED_SUCCESSFULLY"));
          } else {
            toast.success(t("successMsg.KIT_ADDED_SUCCESSFULLY"));
          }
          selectedPlot.kitId = response.data.content.kitId;
          setIsEditKit(false);

          setError(null);
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    } else {
      if (!isEmpty(irrigation)) {
        axios
          .put(
            process.env.REACT_APP_HOST +
            `/user/` +
            getUserId() +
            `/plot/` +
            plotId +
            "/irrigation",
            {valveCode: values.valveCode}
          )
          .then((res) => {
            setIrrigation(values);
            toast.success("ValveCode Updated successfully");
            setError(null);
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              setError(error.response.data.message);
              toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
            }
          })
          .finally(() => {
            dispatch(toggleLoader(false));
          });
      } else {
        axios
          .post(
            process.env.REACT_APP_HOST +
            `/user/` +
            getUserId() +
            `/plot/` +
            plotId +
            "/irrigation",
            {valveCode: values.valveCode}
          )
          .then((res) => {
            setIrrigation(values);
            if (irrigation.valveCode) {
              toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
            } else {
              toast.success(t("successMsg.VALVE_CODE_ADDED_SUCCESSFULLY"));
            }
            setError(null);
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              setError(error.response.data.message);
              toast.error(" Something went wrong");
            }
          })
          .finally(() => {
            dispatch(toggleLoader(false));
          });
      }
    }
  }

  useEffect(() => {
    if (!kit || !kit.devices || !selectedPlot.propertyDetails) {
      return;
    }
    dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedPlot)));

    // if (clustersList.length===0) {
    //   return
    // }
    let deviceMappingList = [];
    if (
      selectedPlot.propertyDetails[0] &&
      selectedPlot.propertyDetails[0].cluster
    ) {
      kit.devices.forEach((device) => {
        const devices = _.findWhere(selectedPlot.propertyDetails, {
          cluster: device,
        });
        const deviceMapping = {
          deviceId: device,
          lat: devices
            ? !devices.coordinate
              ? " "
              : devices.coordinate.lat
            : "",
          lng: devices
            ? !devices.coordinate
              ? " "
              : devices.coordinate.lng
            : "",
        };
        deviceMappingList.push(deviceMapping);
      });
      setKitMap([...deviceMappingList]);
    } else if (clustersList.length > 0) {
      kit.devices.forEach((device, index) => {
        let location = clustersList[index][0];
        const deviceMapping = {
          deviceId: device,
          lat: location.coordinate ? location.coordinate.lat : "",
          lng: location.coordinate ? location.coordinate.lng : "",
        };
        deviceMappingList.push(deviceMapping);
      });
      setKitMap([...deviceMappingList]);
    } else {
      kit.devices.forEach((device, index) => {
        const deviceMapping = {
          deviceId: device,
          lat: "",
          lng: "",
        };
        deviceMappingList.push(deviceMapping);
      });
      setKitMap([...deviceMappingList]);
    }
  }, [kit, clustersList.length]);

  function onClose() {
    dispatch(getFindLocation(false));
    dispatch(getSelectedCoordinate({}));
    dispatch(changeToggle(TOGGLE.LIST));
    setIsEditKit(null);
    initForm({});
    dispatch(getSelectedSensor(-1));
  }

  return (
    <div className="">
      <div className={"farm-settings-blur"} hidden={!findDeviceLocation}>
        <div className={"farm-settings-blur-inner"}>
          <FeatherIcon className={"whit-text m-b-8"} icon={"map-pin"}/>
          <div className={"whit-text"}>
            {" "}
            Please select a location in the map
          </div>
          <button
            className="btn whit-text btn-sa-success w-140p"
            onClick={onClose}
          >
            {" "}
            {t("button.CANCEL")}
          </button>
        </div>
      </div>
      <Container className={"pb-2"}>
        <ToggleLayout
          image={valve}
          title={t("title.VALVE_SETTINGS")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={1}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          <ValveSetting type="PLOT" plotId={plotId} farmId={farmId}/>
        </ToggleLayout>

        <ToggleLayout
          imgType={"feather"}
          feather={"hard-drive"}
          title={t("title.KIT_SETTINGS")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={2}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          <KitSetting type="PLOT" plotId={plotId} farmId={farmId}/>
        </ToggleLayout>

        <ToggleLayout
          imgType={"feather"}
          feather={"package"}
          title={t("title.VISION_KIT_SETTINGS")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={3}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          <SenzagroVisionKitSetting type="PLOT" plotId={plotId} farmId={farmId}/>
        </ToggleLayout>

        <ToggleLayout
          image={upload}
          title={t("title.UPLOAD_BULK_DATA")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={4}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          {kit ? (
            <UploadKitData properties={kit.model.properties} kitId={kit.id}/>
          ) : selectedPlot.kitId && !kit ? (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>
                {t("emptyMsg.KIT_NOT_FOUND")}
              </div>
            </div>
          ) : (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>
                {t("emptyMsg.KIT_NOT_CONFIGURED")}
              </div>
            </div>
          )}
        </ToggleLayout>

        <ToggleLayout
          image={sensor}
          title={t("title.DEVICES")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={5}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          <DeviceSetting plotId={plotId}/>
        </ToggleLayout>

        <ToggleLayout
          image={danger}
          title={t("title.ALERT_SETTINGS")}
          visibleToggleIndex={visibleToggleIndex}
          toggleIndex={6}
          onVisibleToggleIndexChange={(index) =>
            setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
          }
        >
          {selectedPlot && selectedPlot.kitId ? (
            <PlotSettingAlert/>
          ) : (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>
                {t("emptyMsg.KIT_NOT_CONFIGURED")}
              </div>
            </div>
          )}
        </ToggleLayout>
      </Container>
    </div>
  );
};

export default PlotSetting;
