import React, {useEffect, useState} from 'react';
import FarmImg from "../../images/farm.jpg";
import FeatherIcon from "feather-icons-react";
import {toCapsFirst} from "../../utils/Utils";
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import DashboardCurrentScouting from "../dashboard/dashboard-current-scouting";
import C3Chart from "react-c3js";
import {useTranslation} from "react-i18next";
import * as d3 from "d3";
import * as _ from "underscore";
import {pluck} from "underscore";
import CashFlowStages from "./cash-flow-stages";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";

export default function CashFlowCropView(props) {
  const [yieldReport, setYieldReport] = useState(null);
  const [taskReport, setTaskReport] = useState([]);
  const [scoutReport, setScoutReport] = useState([]);
  const [cashFlowSummary, setCashFlowSummary] = useState([]);
  const [dataSet, setDataSet] = useState({});
  const [cropData, setCropData] = useState({});
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });
  const {t, i18n} = useTranslation();
  const [isGraph, setIsGraph] = useState(false);
  const dispatch = useDispatch();
  const {farmId} = useParams();

  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-36px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");
  }


  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/cash/user/` + getUserId() + `/farm/` + farmId + `/monthly-cashflow-summary?from=` + props.data.crop.plantedDate + `&to=` + props.data.crop.expectedHarvestEndDate)
      .then(function (response) {
        setCashFlowSummary(response.data.content)

      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/cash/user/` + getUserId() + `/farm/` + farmId + `/monthly-yield-report?from=` + props.data.crop.plantedDate + `&to=` + props.data.crop.expectedHarvestEndDate)
      .then(function (response) {
        let data = response.data.content
        if (data.length !== 0) {
          let total = pluck(response.data.content, 'quantity').reduce((partialSum, a) => partialSum + a, 0)

          setYieldReport(total)
        }
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, []);

  useEffect(() => {
    if (!props.data?.crop?.expectedHarvestStartDate) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/scout-summary?from=` + props.data.crop.plantedDate + '&to=' + props.data.crop.expectedHarvestEndDate)
      .then(function (response) {
        setScoutReport(response.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, [props.data]);
  useEffect(() => {
    dispatch(toggleLoader(true));
    //+'?from=' +fromDate +`-01` + '&to=' + fromDate + '-'+ daysInMonth(fromDate.slice(5,7),fromDate.slice(0,4))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/task-summary?from=` + props.data.crop.plantedDate + `&to=` + props.data.crop.expectedHarvestEndDate)
      .then(function (response) {
        setTaskReport(response.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, []);
  // useEffect(() => {
  //     dispatch(toggleLoader(true));
  //     //+'?from=' +fromDate +`-01` + '&to=' + fromDate + '-'+ daysInMonth(fromDate.slice(5,7),fromDate.slice(0,4))
  //     axios.get('https://agri.senzmate.com/service/core/kit/boralanda-D_Richel_greenhouse/graph-kit-history/0/summary?from=2022-07-11&to=2022-07-11&aggregation=MAX&granularity=DAY')
  //         .then(function (response) {
  //             // setTaskReport(response.data.content)
  //         }).catch(function (error) {
  //         if (error.response && error.response.status === 422) {
  //
  //         }
  //     }).finally(() => dispatch(toggleLoader(false)));
  // }, []);


  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData);
    });
  }

  useEffect(() => {
    if (dataSet.length) {
      dataSet.slice(0, dataSet.length);
    }
    if (cashFlowSummary.length == 0) {
      return;
    }

    drawGraph();
  }, [cashFlowSummary]);


  function drawGraph() {

    const income = _.where(cashFlowSummary, {type: "INCOME"})[0] ? _.where(cashFlowSummary, {type: "INCOME"})[0].total : 0;
    const expense = _.where(cashFlowSummary, {type: "EXPENSE"})[0] ? _.where(cashFlowSummary, {type: "EXPENSE"})[0].total : 0;
    const profit = income - expense
    // const date = _.pluck(recentLog, 'date');
    const dateSliced = [];
    // date.map(d => {
    //     dateSliced.push(d.slice(0, 10));
    // });
    const graphData = {};
    const tooltip = {};
    const data = {
      // xs: {
      //     'Duration': 'Date',
      // },
      // xFormat: '%Y-%m-%d',
      columns: [

        ['Total'].concat(profit, income, expense),
        // ['income'].concat(income),
        // ['expense'].concat(expense),
      ],
      type: 'bar',
      colors: {
        income: '#22C687',
        expense: '#FF4343',
        profit: '#E2E5E9'
      }
    };
    const axis = {
      x: {
        type: 'category',
        categories: ['Profit', 'Income', 'Expense'],

      },
      // x: {
      //     show: true,
      //     type: 'timeseries',
      //     tick: {
      //         multiline: true,
      //         format:  '%Y-%m-%d',
      //         count:   31,
      //         fit: true,
      //         rotate: window.innerWidth > 767 ? 0 : 9,
      //         culling: {
      //             max: window.innerWidth > 767 ? 6 : 5,
      //         },
      //         height: 100,
      //
      //
      //     },
      //     label: {
      //         text: 'Months',
      //         position: 'outer-center',
      //     },
      //
      // },
      // y: {
      //     label: {
      //         text: 'Duration(min)',
      //         position: 'outer-middle',
      //
      //     }
      // },
      rotated: true
    };
    const bar = {
      space: 2
    }
    const zoom = {
      rescale: true
    };

    graphData['data'] = data;
    graphData['axis'] = axis;
    graphData['tooltip'] = tooltip;
    graphData['zoom'] = zoom;
    graphData['bar'] = bar;
    addDataGraphDate(graphData).then(() => {
    });

  }

  useEffect(() => {
    // if(props.data)
    if (_.findWhere(props.data.cropAgronomy, {id: props.data.crop.cropAgronomyId})) {
      setCropData(_.findWhere(props.data.cropAgronomy, {id: props.data.crop.cropAgronomyId}))
    }

  }, [props.data])


  return (
    <div>
      <div className={"d-flex flex-column text-center justify-content-center"}>
        <div className={'crop-agro-card-img-container overflow-hidden m-auto'}>
          <img src={cropData.image ? cropData.image : FarmImg} width={'60px'} height={'60px'}/>
        </div>
        <div
          className={"crop-view-heading mt-3"}>{`${cropData?.cropName ? cropData.cropName : ''}  ROTATION REPORT`}</div>
        <div className={"crop-view-sub-heading mt-3"}>{cropData?.category ? toCapsFirst(cropData.category) : ''}</div>
      </div>

      <div className={"crop-container"}>
        <div className={"crop-hading"}>Farm Financial Summary</div>
        <div>
          {dataSet.data && (
            <C3Chart
              area={{zerobased: false}}
              padding={{left: 65, right: 10}}
              size={{height: 200}}
              tooltip={dataSet.tooltip}
              zoom={dataSet.zoom}
              data={dataSet.data}
              subchart={{show: false}}
              onrendered={styleGraph}
              axis={dataSet.axis}
            />
          )}
          {dataSet.data && <div className={"crop-chart-x-label"}>Amount in LKR</div>}
          {cashFlowSummary.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>
                {" "}
                {t("emptyMsg.THERE_ARE_NO_REPORTS")}
              </div>
            </div>
          )}
        </div>

      </div>
      <div className={"gray-container p-4"}>
        <p className={"crop-hading mt-1"}>Current Rotation Harvest Yield</p>
        <div className={"d-flex m-auto justify-content-center mb-1"}>
          <FeatherIcon icon="feather" className={"crop-view-yield-value"}/>
          <div className={"crop-view-yield-value ml-3"}>{`${yieldReport ? yieldReport : '0'} Kg`}</div>
        </div>
      </div>
      <div>
        <p className={"crop-hading mt-4 pt-1"}>Task and scouting</p>
        <div className={"row"}>
          <div className={"col-6"}>
            <div className={"sensor-details w-167 ml-auto"}>
              <div className="sensor-icon sensor-icon-size-40 padding-top-0"><FeatherIcon
                icon="clipboard"/>
              </div>
              <div className="sensor-data sensor-data-p">
                <div className="crop-view-card-label">{scoutReport.taskTotal}</div>
                <div className="crop-view-card-value">{"Total Scouts"}</div>
              </div>
            </div>
          </div>
          <div className={"col-6"}>
            <div className={"sensor-details w-167"}>
              <div className="sensor-icon sensor-icon-size-40 padding-top-0"><FeatherIcon
                icon="check-square"/>
              </div>
              <div className="sensor-data sensor-data-p">
                <div className="crop-view-card-label">{taskReport.taskTotal}</div>
                <div className="crop-view-card-value">{"Total Tasks"}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={"crop-container"}>
          <p className={"crop-view-task-status"}>Task Status</p>
          <DashboardCurrentScouting crop={true} startDate={props.data.crop.expectedHarvestStartDate}
                                    endDate={props.data.crop.expectedHarvestEndDate}/>
        </div>
      </div>


      <CashFlowStages startDate={props.data.crop.plantedDate} endDate={props.data.crop.expectedHarvestEndDate}
                      cropAgronomyId={props.data.crop.cropAgronomyId}/>
    </div>
  );
}

;
