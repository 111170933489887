import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import InputRange from "react-input-range";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateAlert} from "../../../utils/FormValidationRules";
import Switch from "react-switch";
import FeatherIcon from "feather-icons-react";
import * as _ from "underscore";
import axios from "axios";
import {toast} from "react-toastify";
import {hasPermission} from "../../../utils/Authentication";
import InfiniteScroll from "react-infinite-scroll-component";
import {css} from "@emotion/core";
import {getSensorLimits} from "../../../utils/Utils";
import {toggleLoader} from "../../../shared/actions/setting";
import {getSelectedPlot} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {getDisplayName, toBrowserTimeZone} from "../../../shared/utils/utils";

function PlotSettingAlert(props) {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {farmId} = useParams();
  const [isTable, setTable] = useState(true);
  const [isToggle, setIsToggle] = useState(true);
  const [thresholdHistory, setThresholdHistory] = useState([]);
  const [levels, setLevels] = useState([]);
  const [maintains, setMaintains] = useState({});
  const [maintainUpdate, setMaintainUpdate] = useState(false);
  const [persistence, setPersistence] = useState({});
  const [persistenceUpdate, setPersistenceUpdate] = useState(false);
  const [alertSwitch, setAlertSwitch] = useState({});
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertHistory, setAlertHistory] = useState([]);
  const [kit, setKit] = useState(null);
  const [kitId, setKitId] = useState(null);
  const [sensorIndex, setSensorIndex] = useState(0);
  const [indexSensor, setIndexSensor] = useState(0);
  const [formIndex, setFormIndex] = useState(1);
  const [showThreshold, setShowThreshold] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [showData, setShowData] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [updateThreshold, setUpdateThreshold] = useState(true);
  const [updateAlert, setUpdateAlert] = useState(true);
  const [formData, setFormData] = useState([]);
  const [indexValueThreshold, setIndexValueThreshold] = useState(1);
  const [indexValueAlert, setIndexValueAlert] = useState(1);
  const [formError, setFormError] = useState(null);
  const [isMaintenanceHistory, setIsMaintenanceHistory] = useState([]);

  const {values, errors, handleChange, handleSubmit, handleOnBlur, setValue} =
    FormHandler(saveAlertSchedule, validateAlert);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    width: fit-content;
  `;

  function saveAlertSchedule() {
    setIsLoading(true);
  }

  function onClickExpand() {
    setTable(!isTable);
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }

  let selectedPlot = useSelector((state) => {
    return state.plotList.selectedPlot;
  });

  const farmActuators = useSelector(state => {
    return state.farmList.farmActuators
  });

  function handleRangeChange(value) {
    console.log(value)
    setValue({low: value.min, high: value.max});
  }

  function underMaintainSwitch(checked) {
    setMaintains({maintain: checked});
    setMaintainUpdate(true);
  }

  function persistenceSwitch(checked) {
    setPersistence({persistence: checked});
    setPersistenceUpdate(true);
  }

  // Get Sensor Values

  useEffect(() => {
    let kitArray = new Set(farmActuators.map(actuator => actuator.kitId));

    if (!props.plotId || kitArray.has(props.plotId)) {
      if (kitArray.has(props.plotId)) setKitId(props.plotId);
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/plot/` +
        props.plotId
      )
      .then(function (response) {
        dispatch(getSelectedPlot(response.data.content));
        if (response.data.content.kitId) {
          setKitId(response.data.content.kitId)
        } else {
          setKitId(null)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.plotId]);

  useEffect(() => {
    if (!kitId || alertUpdate || maintainUpdate || persistenceUpdate) {
      return;
    }
    setKit(null);
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + kitId)
      .then((res) => {
        setKit(res.data.content);
      })
      .finally(() => dispatch(toggleLoader(false)));
  }, [kitId, alertUpdate, maintainUpdate, persistenceUpdate]);

  useEffect(() => {
    setFormIndex(1);
  }, [sensorIndex]);

  //Get the Alert Level

  useEffect(() => {
    if (!kitId) {
      return;
    }
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId
      )
      .then((res) => {
        setLevels(res.data.content);
      });
  }, [kitId]);

  function levelArray(x) {
    let level = [];
    for (var i = 1; i <= x; i++) {
      level.push(i);
    }
    return level;
  }

  useEffect(() => {
    if (!kitId) {
      return;
    }
    dispatch(toggleLoader(true));
    setFormError(null);
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + kitId + `/property/` + sensorIndex + `/alert-limit`
      )
      .then((res) => {
        setFormData(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          setFormError(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setResetForm(false);
        setShowData(true);
      });
  }, [kitId, resetForm, sensorIndex, updateData]);

  function setValues(level) {
    let selectedValue = _.where(formData, {level: level ? level : 1})[0];
    values.currentLevelPeriod = _.result(selectedValue, "currentLevelPeriod");
    values.high = _.result(selectedValue, "high");
    values.low = _.result(selectedValue, "low");
    values.persistence = _.result(selectedValue, "persistence");
    values.nextLevelPeriod = _.result(selectedValue, "nextLevelPeriod");
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));

    if (kit.properties[sensorIndex]?.code === "CN") {
      values.low = values.low / 1000;
      values.high = values.high / 1000;
    }
    axios.put(`${process.env.REACT_APP_HOST}/user/${getUserId()}/kit/${kitId}/property/${sensorIndex}/alert-limit`, values)
      .then((res) => {
        toast.success(t("successMsg.ALERT_SUCCESSFULLY_UPDATED"));
        setUpdateData(!updateData);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
        setUpdateThreshold(!updateThreshold);
      });
  }, [isLoading]);

  let alertLevels = levelArray(levels.alertLevel);

  useEffect(() => {
    if (!kit) {
      return;
    }
    setAlertSwitch({alerts: {1: _.result(kit.alerts, "1")}});
    setMaintains({maintain: kit.maintain});
  }, [kit]);

  const style1 = {borderBottom: "solid 2.5px #22C687"};

  // Pass the Alert Level Switch

  useEffect(() => {
    if (!kitId || !alertUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/alert`,
        alertSwitch
      )
      .then((res) => {
        toast.success(res.data.content);
        setUpdateAlert(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setAlertUpdate(false);
        dispatch(toggleLoader(false));
      });
  }, [alertUpdate]);

  useEffect(() => {
    if (!kitId || !updateAlert) {
      return;
    }
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + kitId + `/alert/size/10/index/0`
      )
      .then((res) => {
        setAlertHistory(res.data.content);
      })
      .finally(() => {
        setUpdateAlert(false);
      });
  }, [kitId, updateAlert]);

  useEffect(() => {
    if (!kitId) {
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/property/` +
        sensorIndex +
        `/alert-limit/history/size/10/index/0`
      )
      .then((res) => {
        setThresholdHistory(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        // setUpdateThreshold(false)
      });
  }, [kitId, sensorIndex, updateThreshold]);

  useEffect(() => {
    if (!kitId || !maintainUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/maintain`,
        maintains
      )
      .then((res) => {
        toast.success(t("successMsg.SUCCESSFULLY_MAINTAINS_UPDATED"));
        setMaintainUpdate(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [maintainUpdate]);

  useEffect(() => {
    if (!kitId || !persistenceUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/persistence`,
        persistence
      )
      .then((res) => {
        toast.success(t("successMsg.SUCCESSFULLY_PERSISTENCE_UPDATED"));
        setPersistenceUpdate(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [persistenceUpdate]);

  useEffect(() => {
    if (!formData || !kit) {
      return;
    }

    let selectedValues = _.where(formData, {level: formIndex})[0];
    let high = _.result(selectedValues, "high")
    let low = _.result(selectedValues, "low")
    values.currentLevelPeriod = _.result(selectedValues, "currentLevelPeriod");
    values.high = high ? kit.properties[sensorIndex]?.code === "CN" ? high * 1000 : high : high;
    values.low = low ? kit.properties[sensorIndex]?.code === "CN" ? low * 1000 : low : low;
    values.persistence = _.result(selectedValues, "persistence");
    values.nextLevelPeriod = _.result(selectedValues, "nextLevelPeriod");
    setShowData(false);
  }, [showData, kit]);

  function fetchDataThreshold() {
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/property/` +
        sensorIndex +
        `/alert-limit/history/size/10/index/` +
        indexValueThreshold
      )
      .then((res) => {
        setThresholdHistory((old) => [...old, ...res.data.content]);
        setIndexValueThreshold(indexValueThreshold + 1);
      });
  }

  useEffect(() => {
    if (!kitId || !updateAlert) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/kit/' + kitId + `/maintain/size/` + 10 + `/index/0`)
      .then(res => {
        setIsMaintenanceHistory(res.data.content)
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [kitId]);

  function fetchDataAlertHistory() {
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        kitId +
        `/alert/size/10/index/` +
        indexValueAlert
      )
      .then((res) => {
        setAlertHistory((old) => [...old, ...res.data.content]);
        setIndexValueAlert(indexValueAlert + 1);
      });
  }

  function getLimits() {
    if (!kit || !kit.properties) {
      return {min: 0, max: 100, step: 1};
    }
    let sensor = kit.properties[indexSensor]?.code;

    return getSensorLimits(sensor);
  }

  return (
    <div>
      {/*{senorValues && senorValues.properties && senorValues.properties.length > 0 &&*/}

      <Container>
        {(_.isEmpty(selectedPlot) ||
        !selectedPlot.kitId ||
        typeof selectedPlot.kitId === "undefined") && !kitId ? (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>
              {t("info.KIT_NOT_CONFIGURED")}
            </div>
          </div>
        ) : kit &&
        kit.properties &&
        kit.properties.length > 0 ? (
          <div className="alert-box">
            <div className={""}>
              {kit.id &&
                kit.properties.map((value, index) => (
                  <div
                    style={(sensorIndex === value.number && style1) || null}
                    key={index}
                    className={"r-w-50 plot-report-tabs w-tab-auto"}
                    onClick={() => {
                      setSensorIndex(value.number);
                      setIndexSensor(index);
                    }}
                  >
                    <div
                      className="plot-report-tabs-title limit-plot-report-N plot-title-size limit-char-fw"
                      title={getDisplayName(value, kit.metaData)}
                    >
                      {getDisplayName(value, kit.metaData)}
                    </div>
                    <div className="plot-report-tabs-value limit-plot-report-V ">
                      {value.displayValue}
                    </div>
                  </div>
                ))}
            </div>

            {/*<div hidden={!isLoaderLoading} className="container m-t-b-150" style={{display:'inline-block'}}>*/}
            {/*  <div className="sweet-loading">*/}
            {/*    <PropagateLoader*/}
            {/*      css={override}*/}
            {/*      size={20}*/}
            {/*      color={"#11bc4d"}*/}
            {/*      loading={true}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div>
              <div>
                {alertLevels &&
                  alertLevels.map((data, index) => (
                    <div key={index} className={"mt-1"}>
                      <div
                        className={"alert-level-box mb-0 bg-ededed-grey " + (data !== formIndex ? "" : " border-bottom m-0")}>
                        <h5 className="d-inline pr-5 plot-alert-heading text-dark">
                          Alert{" "}
                          <span className={"hide-768"}>{t("title.LEVEL")}</span>{" "}
                          {data}{" "}
                          <span className="" data-toggle="tooltip" title={t("info.ALERT_LEVELS")}><FeatherIcon icon={"info"} className={"icon-in-btn tooltip-icon text-blue"} /></span>
                        </h5>
                        <Switch
                          checked={
                            _.result(kit.alerts, data)
                              ? _.result(kit.alerts, data)
                              : false
                          }
                          onChange={(checked) => {
                            setAlertSwitch({alerts: {[data]: checked}});
                            setAlertUpdate(true);
                          }}
                          onColor="#94ceb8"
                          offColor="#D3D3D3"
                          onHandleColor="#22C687"
                          handleDiameter={12}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={10}
                          width={21}
                          className="react-switch"
                          id="material-switch"
                        />

                        {alertLevels.length > 1 && (
                          <a className={"float-right mr-1 ml-auto"}>
                            {" "}
                            {data !== formIndex && (
                              <FeatherIcon
                                className={"icon-in-btn plot-alert-btn text-dark border-0"}
                                icon={"chevron-down"}
                                onClick={() => {
                                  setFormIndex(data);
                                  setValues(data);
                                }}
                              />
                            )}
                            {data === formIndex && (
                              <FeatherIcon
                                className={"icon-in-btn plot-alert-btn text-dark border-0"}
                                icon={"chevron-up"}
                                onClick={() => {
                                  setFormIndex(data);
                                  setValues(data);
                                }}
                              />
                            )}
                          </a>
                        )}
                      </div>
                      {data === formIndex && (
                        <div className={!isToggle ? "hide-table" : ""}>
                          <form
                            className="form-controls px-web-32"
                            onSubmit={handleSubmit}
                          >
                            {hasPermission("PLOT_ALERT_ADVANCED_SETTING") && (
                              <div className="alert-input-box">
                                <div
                                  className={
                                    formIndex === 3 ? "col-md-6" : "col-md-4"
                                  }
                                >
                                  <div className="form-group m-b-16">
                                    <label htmlFor="email" className={"add-lable-color"}>
                                      {t("title.ALERT_AFTER")}:{" "}
                                    </label>
                                    <input
                                      onBlur={handleOnBlur}
                                      onChange={handleChange}
                                      value={values.currentLevelPeriod || ""}
                                      type="text"
                                      className={`form-control`}
                                      name="currentLevelPeriod"
                                      placeholder="mins"
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    formIndex === 3 ? "col-md-6" : "col-md-4"
                                  }
                                >
                                  <div className="form-group m-b-16">
                                    <label htmlFor="email" className={"add-lable-color"}>
                                      {t("title.ALERT_INTERVAL_TYPE")}
                                    </label>
                                    <select
                                      onBlur={handleOnBlur}
                                      onChange={handleChange}
                                      value={values.persistence}
                                      className={`form-control`}
                                      name="persistence"
                                    >
                                      <option value={""} hidden>
                                        {t("title.SELECT_TIME_INTERVAL")}
                                      </option>

                                      <option value="INTERVAL"> {t("title.INTERVAL")}</option>
                                      <option value="CONTINUOUS"> {t("title.CONTINUOUS")}</option>
                                      <option value="SQUARE"> {t("title.SQUARE")}</option>
                                    </select>
                                  </div>
                                </div>
                                {formIndex !== 3 && (
                                  <div className="col-md-4">
                                    <div className="form-group m-b-16">
                                      <label htmlFor="email" className={"add-lable-color"}>
                                        {t("title.NEXT_ALERT_AFTER")}:{" "}
                                      </label>
                                      <input
                                        onBlur={handleOnBlur}
                                        onChange={handleChange}
                                        value={values.nextLevelPeriod || ""}
                                        type="text"
                                        className={`form-control `}
                                        name="nextLevelPeriod"
                                        placeholder="mins"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className={"mr-5 ml-5 d-block"}>
                              <InputRange
                                maxValue={getLimits().max}
                                minValue={getLimits().min}
                                step={getLimits().step}
                                formatLabel={(value) => value.toFixed(1)}
                                value={{
                                  min: typeof values.low !== "undefined" ? values.low : 0,
                                  max: values.high ? values.high : 0,
                                }}
                                onChange={(value) => handleRangeChange(value)}
                              />
                              {errors.high && (
                                <p className="warning-input-msg ">
                                  {errors.high}
                                </p>
                              )}
                            </div>
                            <div className="text-center m-t-40">
                              <button
                                className="btn btn-sa-secondary p-2 w-140p plot-setting-btn-m"
                                type="reset"
                                onClick={() => setResetForm(true)}
                              >
                                {t("button.RESET")}
                              </button>
                              <button
                                className="btn btn-sa-primary p-2  w-140p"
                                type="submit"
                                onClick={() => {
                                  values.level = data;
                                }}
                              >
                                {t("button.SAVE")}
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div
                className={"alert-level-box mb-2 threshold bg-ededed-grey " + (!showThreshold ? "" : "border-bottom mb-0")}>
                <h5 className={" d-inline plot-alert-heading text-dark"}>
                  {t("title.THRESHOLD_HISTORY")}
                  <span className="" data-toggle="tooltip" title={t("info.THRESHOLD_HISTORY")}><FeatherIcon icon={"info"} className={"icon-in-btn tooltip-icon text-blue"} /></span>
                </h5>{" "}
                <FeatherIcon
                  className={"icon-in-btn time-box text-dark mr-1"}
                  icon={showThreshold ? "chevron-up" : "chevron-down"}
                  onClick={() => setShowThreshold(!showThreshold)}
                />
              </div>
              {showThreshold ? (
                <div className={"sa-table-container border-0"} id="scrollableDivThres">
                  <InfiniteScroll style={{overflow: "none"}}
                                  next={fetchDataThreshold}
                                  dataLength={thresholdHistory.length}
                    // endMessage={git
                    //     <p style={{ textAlign: 'center' }}>
                    //         <b>that's all</b>
                    //     </p>
                    // }
                    // loader={<div className="loader"> Loading... </div>}
                                  hasMore={true}
                                  scrollableTarget="scrollableDivThres"
                                  useWindow={false}
                  >
                    <table className="table table-borderless sa-table-width">
                      <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{t("title.USER")}</th>
                        <th className={"sa-table-head-sticky"}> {t("title.THRESHOLD_LEVEL")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.ALERT_LEVEL")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.TIME")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {thresholdHistory.map((threshold, index) => (
                        <tr key={index}>
                          <td className={'sa-table-data'}>{threshold.user}</td>
                          <td className={'sa-table-data'}>
                            {threshold.alertLimit.low +
                              " to " +
                              threshold.alertLimit.high}
                          </td>
                          <td className={'sa-table-data'}>{threshold.alertLimit.level}</td>
                          <td className={'sa-table-data'}>{toBrowserTimeZone(threshold.time)}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                    {thresholdHistory.length === 0 && (
                      <div className={"empty-results"}>
                        <FeatherIcon icon="info"/>
                        <div className={"empty-results-text"}>
                          {t("emptyMsg.THERE_ARE_NO_THRESHOLD_HISTORY")}
                        </div>
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              ) : null}

              <div className="alert-container justify-content-between">
                <div className="col-md-5 w-100 m-b-p-0 pl-0">
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"d-inline plot-alert-heading text-dark"}>
                      <span className={"hide-768"}>{t("title.UNDER")}</span>{" "}
                      {t("title.MAINTENANCE")}
                      <span className="" data-toggle="tooltip" title={t("info.UNDER_MAINTENANCE")}><FeatherIcon icon={"info"} className={"icon-in-btn tooltip-icon text-blue"} /></span>
                    </h5>

                    <Switch
                      checked={
                        kit.maintain ? kit.maintain : false
                      }
                      onChange={underMaintainSwitch}
                      onColor="#94ceb8"
                      offColor="#D3D3D3"
                      onHandleColor="#22C687"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={21}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"d-inline plot-alert-heading text-dark"}>
                      Persistence{" "}
                      <span className={"hide-768"}>{t("title.DEVICE")} </span>
                    </h5>
                    <Switch
                      checked={
                        kit.persistence
                          ? kit.persistence
                          : false
                      }
                      onChange={persistenceSwitch}
                      onColor="#94ceb8"
                      offColor="#D3D3D3"
                      onHandleColor="#22C687"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={21}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                  <div className="m-b-p-0 pr-0">
                    <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                      <h5 className={"plot-alert-heading text-dark"}>
                        {t("title.MAINTENANCE_HISTORY")}
                      </h5>
                    </div>
                    {isMaintenanceHistory.length !== 0 && (
                      <div className="flex-column">
                        <InfiniteScroll
                          next={fetchDataAlertHistory}
                          dataLength={isMaintenanceHistory.length}
                          hasMore={true}
                          scrollableTarget="scrollableDivAlert"
                          useWindow={false}
                        >
                          <table className="table table-borderless sa-table-width">
                            <thead>
                            <tr>
                              <th>User</th>
                              <th>Status</th>
                              <th>Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {isMaintenanceHistory && isMaintenanceHistory.map((data, index) => (
                              <tr key={index}>
                                <td>{data.user}</td>
                                <td>{data.maintain === true ? "On" : "Off"}</td>
                                <td>{data.time.slice(0, 10)}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                        {isMaintenanceHistory && isMaintenanceHistory === 0 &&
                          <div className={"empty-results mt-5"}>
                            <FeatherIcon icon="info"/>
                            <div className={"empty-results-text"}>There are no Maintenance History</div>
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-7 m-b-p-0 pr-0">
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"plot-alert-heading text-dark"}>
                      {t("title.ALERT_HISTORY")}
                      <span className="" data-toggle="tooltip" title={t("info.ALERT_HISTORY")}><FeatherIcon icon={"info"} className={"icon-in-btn tooltip-icon text-blue"} /></span>
                    </h5>
                  </div>
                  {alertHistory.length !== 0 && (
                    <div className={"alert-table"} id="scrollableDivAlert">
                      <InfiniteScroll
                        next={fetchDataAlertHistory}
                        dataLength={alertHistory.length}
                        // endMessage={
                        //     <p style={{ textAlign: 'center' }}>
                        //         <b>that's all</b>
                        //     </p>
                        // }
                        // loader={<div className="loader"> Loading... </div>}
                        hasMore={true}
                        scrollableTarget="scrollableDivAlert"
                        useWindow={false}
                      >
                        <table className="table table-borderless sa-table-width">
                          <thead>
                          <tr>
                            <th scope="col">{t("title.USER")}</th>
                            <th scope="col">{t("title.LEVEL")}</th>
                            <th scope="col">{t("title.ALERT")}</th>
                            <th scope="col">{t("title.TIME")}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {alertHistory.map((history, index) => (
                            <tr key={index}>
                              <td>{history.user} </td>
                              <td>{history.level}</td>
                              <td>{history.alert === true ? "On" : "Off"}</td>
                              <td>{toBrowserTimeZone(history.time)}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                  {alertHistory.length === 0 && (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.ALERT_HISTORY")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>
              {formError ? formError : t("emptyMsg.KIT_PROPERTIES_NOT_AVAILABLE")}
            </div>
          </div>
        )}
      </Container>
      {/*}*/}
    </div>
  );
}

export default PlotSettingAlert;
