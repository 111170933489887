import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";

const Popup = ({ children, title, heading }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <div onClick={togglePopup} className="mb-3">{<button className="btn btn-sa-primary ml-5">{title}</button>}</div>
      {showPopup && (
        <React.Fragment>
          <div className="sa-popup-bg-style" onClick={closePopup}></div>
          <div className="popup-container view-scroll">
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>{heading}</span>
              <div className="sa-popup-close-icon">
                <FeatherIcon
                  onClick={closePopup}
                  className={"sa-modal-close-icon"}
                  icon={"x"}
                />
              </div>
            </div>
            <div className="sa-popup-child">{children}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Popup;
