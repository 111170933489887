import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {VIEW} from "../../utils/Enum";
import {changeView, toggleLoader} from "../../shared/actions/setting";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import AccountItem from "./account-item";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getAccountList, getFilterAccountList} from "../../actions/module";

const Account = () => {
  const {t, i18n} = useTranslation();

  const [accountList, setAccountList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeView(VIEW.ACCOUNT_SETTING));
  }, []);

  useEffect(() => {
    dispatch(changeView(VIEW.ACCOUNT_SETTING));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/account`)
      .then(res => {
        setAccountList(res.data.content)
        dispatch(getAccountList(res.data.content))
        dispatch(getFilterAccountList(res.data.content))
      })
     .catch(function (error) {
       if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [])

  const accountListFilter = useSelector(state => {
    return state.modules.accountListFilter
  });

  useEffect(() => {
    setAccountList(accountListFilter)
  }, [accountListFilter])

  return (
    <div className="smart-farm">
      <div id="listView" className={'list-view full-list-view half-list-container'}>
        <Container>
          <Row id={"m-rl-m-16-2"}>
            {accountList.map((account) => (
              <Col xl={4} lg={6} className={'m-b-4'} key={account.id}>
                <AccountItem account={account}/>
              </Col>
            ))}
          </Row>

          {accountList && accountList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ACCOUNTS")}</div>
            </div>)
          }
        </Container>
      </div>
    </div>
  )
};
export default Account;
