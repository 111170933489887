import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import axios from "axios";
import FormHandler from "../../shared/utils/FormHandler";
import DatePicker from "react-datepicker";
import addImage from "../../images/addImage.svg";
import {toggleLoader, toggleLoaderClear} from "../../shared/actions/setting";
import {useParams} from "react-router";
import {validateTasks} from "../../utils/FormValidationRules";
import {uploadImage} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {FileUploader} from "react-drag-drop-files";
import {filter, findIndex, without} from "underscore";
import {dateFormat} from "../../shared/utils/utils";
import {Typeahead} from "react-bootstrap-typeahead";

let isUploaded = false;

export default function AddTask(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState("");
  const [imgTask, setImgTask] = useState([]);
  const [oldImages, setOldImages] = useState(props.values?.images ?? []);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();

  const category = [{name: t("title.IRRIGATION"), value: "Irrigation"}, {
    name: t("title.PESTICIDE_AND_FUNGICIDE_APPLICATION"),
    value: "Pesticide and fungicide application"
  }, {
    name: t("title.WEEDICIDE_APPLICATION"),
    value: "Weedicide application"
  }, {
    name: t("title.FERTILIZATION"),
    value: "Fertilization"
  }, {
    name: t("title.FRUITING_STAGE_GUIDELINE"),
    value: "Fruiting stage guideline"
  }, {
    name: t("title.BAGGING"),
    value: "Bagging"
  }, {
    name: t("title.FLOWERING_STAGE_GUIDELINE"),
    value: "Flowering stage guideline"
  }, {
    name: t("title.FRUIT_THINNING"),
    value: "Fruit Thinning"
  }, {
    name: t("title.MULCHING"),
    value: "Mulching"
  }, {
    name: t("title.HARVESTING_GUIDELINES"),
    value: "Harvesting guidelines"
  }, {
    name: t("title.PRUNING"),
    value: "Pruning"
  }, {
    name: t("title.SEEDLING_STAGE"),
    value: "Seedling Stage"
  }, {
    name: t("title.GERMINATION_STAGE"),
    value: "Germination Stage"
  }, {
    name: t("title.VEGETATIVE_STAGE"),
    value: "Vegetative Stage"
  }
]

  const {
    values,
    errors,
    initForm,
    handleChange,
    handleSubmit,
    handleOnBlur,
    deleteErrors,
    setValue,
  } = FormHandler(addTasks, validateTasks);

  function addTasks() {
    setIsLoading(true)
  }

  function handleChangeDrag(file) {
    file.add = true
    file.imgPath = URL.createObjectURL(file)
    let dataImg = [...imgTask]
    dataImg.push(file)
    setImgTask(dataImg)
  };

  function removeImg(index) {
    let data = without(imgTask, imgTask[index])
    setImgTask(data)

  }

  function removeOldImg(index) {
    let data = without(oldImages, oldImages[index])
    setOldImages(data)
  }

  const fileTypes = ["JPG", "PNG", "JPEG"];

  function onTypeError(e) {
    toast.error(e)
  }


  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    setFormErr("");
  }

  function filterActiveUsers(data) {
    const activeUser = [];
    data.map((user) => {
      if (user.status === "ACTIVE") {
        activeUser.push(user);
      }
    });

    setActiveUsers(activeUser)
  }


  useEffect(() => {
    if (!farmId) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user')
      .then(res => {
        let data = res.data.content;
        filterActiveUsers(data);
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [farmId]);

  useEffect(() => {
    if (!props.assignee || props.assignee.length === 0) {
      return;
    }
    setActiveUsers(props.assignee);
  }, [farmId, props.assignee]);

  useEffect(async () => {
    if (!isLoading) {
      return;
    }

    if (props.scoutId) {
      values.scoutReportId = props.scoutId
    }

    values.id === "None" && delete values.id;
    if (!props.updateAvailable) {

      if (imgTask.length !== 0 && imgTask[0].add) {
        setIsLoading(false)

        let getPromiseList = [];
        dispatch(toggleLoader(true));
        for (let i = 0; i < imgTask.length; i++) {
          let imgName = "image_" + new Date().getTime() + '.jpeg';
          let response = await uploadImage(imgName, imgTask[i]);
          getPromiseList.push(response);
        }

        Promise.all(getPromiseList).then(res => {
          imgTask.map((e) => e.add = false)
          values.images = res
        }).finally(() => {
          setIsLoading(true)
        })
      }

      if ((imgTask.length > 0 && values.images) || imgTask.length === 0) {
        if (!values.category) {
          values.category = "None";
        }
        endDate && (values.endDate = dateFormat(endDate));
        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/task-report', values)
          .then(res => {
            toast.success(t("successMsg.TASK_ADDED_SUCCESSFULLY"));
            props.onClose();
            props.onUpdate();
            // setTasks(oldArray => [...oldArray, res.data.content]);
            resetForm()
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              setFormErr(error.response.data.message);
            }

          }
        )
          .finally(() => {
            setIsLoading(false);
            dispatch(toggleLoaderClear(false));
          });
      }

    } else {
      if (imgTask.length !== 0 && imgTask[0].add) {
        setIsLoading(false)
        isUploaded = false;

        let getPromiseList = [];
        dispatch(toggleLoader(true));
        for (let i = 0; i < imgTask.length; i++) {
          let imgName = "image_" + new Date().getTime() + '.jpeg';
          let response = await uploadImage(imgName, imgTask[i]);
          getPromiseList.push(response);
        }

        Promise.all(getPromiseList).then(res => {
          imgTask.map((e) => e.add = false)
          values.images = res
          oldImages.map((e) => values.images.push(e))
        }).finally(() => {
          setIsLoading(true)
          isUploaded = true;
        })
      } else if (imgTask.length === 0 && values.images) {
        values.images = oldImages;
      }
      if ((imgTask.length > 0 && values.images && isUploaded) || imgTask.length === 0) {
        dispatch(toggleLoader(true));
        endDate && (values.endDate = dateFormat(endDate));
        delete values.id
        // delete values.farmId
        delete values.time
        delete values.createdBy
        delete values.assignee
        delete values.scoutReport
        axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + values.farmId + '/task-report/' + props.taskId, delete values.farmId && values)
          .then(res => {

            // tasks[taskIndex] = res.data.content;
            // setTasks(tasks);
            props.onClose()
            toast.success(t("successMsg.TASK_UPDATED_SUCCESSFULLY"));

          }).catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }
        })
          .finally(() => {
            setIsLoading(false);
            dispatch(toggleLoaderClear(false));
            isUploaded = false;
          });
      }
    }
  }, [isLoading])
  console.log(activeUsers)
  console.log(values)


  useEffect(() => {
    if (!props.updateAvailable) {
      return
    }
    initForm(props.values)
    props.values.endDate ? setEndDate(new Date(props.values.endDate)) : setEndDate("");
    setSelectedCategory(filter(category, {value: props.values.category}))
  }, [props.updateAvailable]);

  useEffect(() => {
    if (!props.defaultAssignee || activeUsers.length === 0) {
      return
    }
    if (findIndex(activeUsers, {id: props.defaultAssignee.id}) !== -1) {
      values.assigneeId = props.defaultAssignee.id
    }
  }, [props.defaultAssignee, activeUsers]);


  return (
    <div onClick={props.formClick}>
      <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form onSubmit={handleSubmit} className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{!props.updateAvailable ? t("title.ADD_TASK") : t("title.EDIT_TASK")}</span>
              <div className="sa-popup-close-icon" onClick={props.onClose}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.SUMMARY")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.note || ''} type="text"
                         className={`form-control ${errors.note && "warning-input"}`}
                         name="note"
                         placeholder={t("placeHolder.ENTER_SUMMARY")}/>
                  {errors.note && (<p className="warning-input-msg ">{errors.note}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="assignee">{t("title.ASSIGNEE")}</label>
                  <select onBlur={handleOnBlur} onChange={handleChange}
                          value={values.assigneeId || ''}
                          className={`form-control ${errors.assigneeId && "warning-input"}`}
                          name="assigneeId">
                    <option value={""} hidden>{t("placeHolder.SELECT_ASSIGNEE_NAME")}</option>
                    {activeUsers.map((user, index) => (
                        // <option value="" selected disabled hidden>Choose here</option>,
                        <option value={user.id} key={index} placeholder={t("title.CHAIN")}>{user.lastName}</option>
                      )
                    )}
                  </select>
                  {errors.assigneeId && (
                    <p className="warning-input-msg">{errors.assigneeId}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.CATEGORY")}</label>
                  <Typeahead
                    id="basic-typeahead-single"
                    className={`${errors.category && "warning-input br-25"}`}
                    labelKey={option => `${option.name}`}
                    onChange={(selected) => {
                      if (selected.length > 0) {
                        setSelectedCategory(selected)
                        setValue({"category": selected[0].value})
                      } else {
                        setSelectedCategory([])
                      }
                    }}
                    options={category}
                    placeholder={t("placeHolder.ENTER_CATEGORY")}
                    selected={selectedCategory}
                  />

                  {/*<input list="browsers" onBlur={handleOnBlur} onChange={handleChange}*/}
                  {/*    value={values.category || ''}*/}
                  {/*    className={`form-control ${errors.category && "warning-input"}`}*/}
                  {/*    name="category"*/}
                  {/*    placeholder={t("placeHolder.ENTER_CATEGORY")}*/}
                  {/*    // autocomplete="off"*/}

                  {/*/>*/}
                  {/*<datalist id="browsers">*/}
                  {/*    <option value="Irrigation">{t("title.IRRIGATION")}</option>*/}
                  {/*    <option value="Pesticide and fungicide application">{t("title.PESTICIDE_AND_FUNGICIDE_APPLICATION")}</option>*/}
                  {/*    <option value="Weedicide application">{t("title.WEEDICIDE_APPLICATION")}</option>*/}
                  {/*    <option value="Fertilization">{t("title.FERTILIZATION")}</option>*/}
                  {/*    <option value="Fruiting stage guideline">{t("title.FRUITING_STAGE_GUIDELINE")}</option>*/}
                  {/*    <option value="Bagging">{t("title.BAGGING")}</option>*/}
                  {/*    <option value="Flowering stage guideline">{t("title.FLOWERING_STAGE_GUIDELINE")}</option>*/}
                  {/*    <option value="Fruit Thinning">{t("title.FRUIT_THINNING")}</option>*/}
                  {/*    <option value="Mulching">{t("title.MULCHING")}</option>*/}
                  {/*    <option value="Harvesting guidelines">{t("title.HARVESTING_GUIDELINES")}</option>*/}
                  {/*    <option value="Pruning">{t("title.PRUNING")}</option>*/}

                  {/*</datalist>*/}


                  {errors.category && (
                    <p className="warning-input-msg ">{errors.category}</p>)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label>{t("title.DEADLINE")}</label>
                  <DatePicker className={"form-control"}
                              onChange={date => setEndDate(date)}
                              selected={endDate || ""}
                              minDate={new Date()}
                              name={"endDate"}
                              placeholderText={t("placeHolder.SELECT_A_DATE")}
                    // fixedHeight
                              dateFormat={"dd/MM/yyyy"}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="status">{t("title.STATUS")}</label>
                  <select onBlur={handleOnBlur} onChange={handleChange}
                          value={values.status || ''}
                          className={`form-control ${errors.status && "warning-input"}`}
                          name="status">
                    <option hidden value={""}>{t("title.SELECT_STATUS")}</option>
                    <option>{t("title.REPORTED")}</option>
                    <option>{t("title.ASSIGNED")}</option>
                    <option value={"IN_PROGRESS"}>{t("title.IN_PROGRESS")}</option>
                    <option>{t("title.DONE")}</option>
                    <option>{t("title.FIXED")}</option>
                    {props.approve && <option>{t("title.APPROVED")}</option>}
                  </select>
                  {errors.status && (
                    <p className="warning-input-msg">{errors.status}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.DESCRIPTION")}</label>
                  <textarea onBlur={handleOnBlur} onChange={handleChange}
                            value={values.description || ''} type="text-area"
                            className={`form-control ${errors.description && "warning-input"}`}
                            name="description"
                            placeholder={t("placeHolder.ENTER_DESCRIPTION")} rows="3"
                  />
                  {errors.note && (<p className="warning-input-msg ">{errors.description}</p>)}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group m-b-16 d-flex flex-column">
                  <label htmlFor="email">{t("title.IMAGES")}</label>
                  <div className={"d-flex add-img"}>
                    <FileUploader handleChange={handleChangeDrag} name="file" types={fileTypes} multiple={false}
                                  onTypeError={onTypeError}>
                      <img src={addImage} width={"48px"}/>

                    </FileUploader>
                    {imgTask.map((data, index) => (

                      <div key={index} className={"ml-3 position-relative"}>
                        <div className={"img-close"} onClick={() => removeImg(index)}>
                          <FeatherIcon icon={"x"} width={"16px"}/>
                        </div>
                        <img src={data.imgPath} width={"48px"} height={"48px"} className={"shadow"}/>
                      </div>))}
                    {oldImages && (
                      oldImages.map((data, index) => (
                        <div key={index} className={"ml-3 position-relative"}>
                          <div className={"img-close"} onClick={() => {
                            removeOldImg(index);
                          }}>
                            <FeatherIcon icon={"x"} width={"16px"}/>
                          </div>
                          <img src={data} width={"48px"} height={"48px"} className={"shadow"}/>
                        </div>
                      ))
                    )}
                  </div>
                  {/*<img src={imgTask}/>*/}
                </div>
              </div>

            </div>

            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={props.onClose}>{t("button.CANCEL")}
              </button>
              <button id={'taskAddOrUpdate'} className="sa-popup-secondary-btn-style" type="submit">
                {!props.updateAvailable ? t("button.ADD") : t("button.UPDATE")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
