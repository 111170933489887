import React, {useEffect, useState} from "react";
import Logo from "../images/logo.png";
import Dlogo from "../images/Ammahicon.svg";

import {hasPermission,} from "../utils/Authentication";

import {useHistory, useLocation} from "react-router-dom";
import {changeToggle, toggleChangePassword, toggleLoader} from "../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedFarm} from "../actions/farm";
import {MODULE, TOGGLE, VIEW} from "../utils/Enum";
import {getModule, goToHomePage, isModule} from "../utils/Utils";
import axios from "axios";
import {
  corporateList,
  getFilterCorporateList,
  getSelectedCorporate,
  getSelectedFarmModule,
  getSelectedUserModule,
  getFarmUser
} from "../actions/module";
import packageJson from "../../package.json";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import NavbarLayout from "../shared/components/navbarLayout";
import {getName, getRefCount, getReferences, getRole, getUserId, signOut, isSuper, isAccountUser} from "../shared/utils/SharedAuthentication";
import {toast} from "react-toastify";

const MainNavBar = (props) => {
  const [dropDownNotification, setDropDownNotification] = useState(false);
  const [module, setModule] = useState(MODULE.SMART_FARM);
  const [notificationsUpdate, setNotificationsUpdate] = useState([]);
  const [isNotificationExpand, setIsNotificationExpand] = useState(false);
  const [notificationID, setNotificationID] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loadNotifications, setLoadNotifications] = useState([]);
  const [notificationUpdateRefresh, setNotificationUpdateRefresh] = useState(false);
  const [isCcoOrFms, setIsCcoOrFms] = useState([]);
  const [corporateData, setCorporateData] = useState([]);
  const [corporateEnable, setCorporateEnable] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const role = getRole();
  const refs = getRefCount();
  const [farmsData, setFarmsData] = useState([]);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  let selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  const corporateDataList = useSelector(state => {
    return state.modules.corporateList
  });

  const farmUser1 = useSelector(state => {
    return state.modules.farmUser
  });

  const corporateListFilter = useSelector(state => {
    return state.modules.corporateListFilter
  });

  function toggleNotificationTruncate(
    value,
    notificationId,
    notificationsseenStatus
  ) {
    setIsNotificationExpand(value);
    setNotificationID(notificationId);
    localStorage.setItem("NOTIFICATION", loadNotifications[0].createdAt);


    if (!value && !notificationsseenStatus) {
      setNotificationUpdateRefresh(!notificationUpdateRefresh);
    }
  }


  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const view = useSelector((state) => {
    return state.setting.view;
  });
  const userModules = useSelector((state) => {
    return state.modules.enabledModules;
  });

  function logOut() {
    signOut();
    history.push("/login");
  }

  function setting() {
    const role = getRole();
    const refs = getRefCount();


    const farmUser = refs === 1 && (role >= 1000 && role <= 9999);
    history.push(farmUser ? "/" + getReferences() + "/settings/plots" : "/module/settings/farms");
  }

  useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.type = "text/javascript";
      document.body.appendChild(externalScript);
      externalScript.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
    };
    LoadExternalScript();
  }, []);

  function about() {
    window.open("https://senzagro.com", "_blank");
  }

  function systemSetting() {
    if (isAccountUser()) {
      history.push("/system-config/farmer-detail");
    } else {
      history.push("/system-config/crop");
    }
  }

  function corporateSetting() {

    history.push("/corporate");
  }

  function buyerSetting() {

    history.push("/buyer");
  }

  function accountSetting() {
    history.push("/account");
  }

  function goToHome() {
    if (toggle === TOGGLE.MAP) {
      dispatch(changeToggle(TOGGLE.LIST));
    }

    goToHomePage(history);
  }


  function showChangePassword() {
    dispatch(toggleChangePassword(true));
    if (view === VIEW.FARM_LIST) {
      dispatch(getSelectedFarm({}));
    }
  }

  function fetchSelectedFarm(farmId) {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(function (response) {
        dispatch(getSelectedFarm(response.data.content));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }


  function changeKey(arr) {
    if (!arr) return [];
    return arr.map((s) => {
      if (s.hasOwnProperty("startTime")) {
        s.createdAt = s.startTime;
        s.seenStatus = false;
        delete s.startTime;
      }
      return s;
    });
  }

  useEffect(() => {
    // setisLoading(true);
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/moduleConfig`
      )
      .then((res) => {
        dispatch(getSelectedUserModule(res.data.content.enabledModules));
        dispatch(getSelectedFarmModule(res.data.content.farmModules));
      })
      .finally(() => {
        // setisLoading(false);
      });
  }, []);

  useEffect (() => {    
    setFarmsData(farmUser1);
    }, [farmUser1]);

  useEffect(() => {
    if (!(refs === 1 && (role >= 1000 && role <= 9999))) {
      return;
    }

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/participants?farms=` +  getReferences())
      .then((res) => {
        setFarmsData(res.data.content);
        if (res.data.content.length > 0)  {
          dispatch(getFarmUser(res.data.content));
        }
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      }
    }).finally(
      () => {
        dispatch(toggleLoader(false))
      }
    )

  }, [])


  function onPaths(paths) {
    return (match, location) => {
      if (paths === "/daily-update") {
        return location.pathname.match("/daily-update") || location.pathname.match("/alltasks")
      }
      if (paths === "SF") {
        return (
          !location.pathname.match("/daily-update") &&
          !location.pathname.match("/cash-flow") &&
          !location.pathname.match("/settings") &&
          !location.pathname.match("/xindicate") &&
          !location.pathname.match("/map-data") &&
          !location.pathname.match("/corporate") &&
          !location.pathname.match("/buyer") &&
          !location.pathname.match("/system-config") &&
          !location.pathname.match("/dashboard") &&
          !location.pathname.match("/cco") &&
          !location.pathname.match("/alltasks") &&
          !location.pathname.match("/notification-page") && 
          !location.pathname.match("/ccr") &&
          !location.pathname.match("/account")
        );
      }
      return location.pathname.match(paths);
    };
  }


  useEffect(() => {
    setModule(getModule(location.pathname));
  }, [location]);


  function goToNotification(farmId, plotId, tittle) {
    if (selectedFarm.id !== farmId) {
      fetchSelectedFarm(farmId)
    }

    if (tittle === "CASHFLOW_YIELD_UPDATES") {
      history.push("/" + farmId + "/cash-flow/yield");
    } else if (tittle === "FARM_SCOUTING_REPORT_UPDATES") {
      history.push("/" + farmId + "/daily-update/scouting");
    } else if (tittle === "FARM_TASK_REPORT_UPDATES") {
      history.push("/" + farmId + "/daily-update/task");
    } else if (tittle === "CASHFLOW_INCOME_UPDATES") {
      history.push("/" + farmId + "/cash-flow/income");
    } else if (tittle === "CASHFLOW_EXPENSE_UPDATES") {
      history.push("/" + farmId + "/cash-flow/expense");
    } else {
      history.push("/" + farmId + "/plot/" + plotId + "/sensor");
    }
  }

  function goToBuyerNotification(buyerId, corporateId, tittle, references) {

    if (tittle === "DEMAND_UPDATES") {
      if (hasPermission("BUYER_PROFILE")) {
        history.push("/buyer/" + buyerId + "/demand-request");
      } 
      else if (hasPermission("CORPORATE_ACCESS") && references) {
        let refArray = getReferences().split(',');
        let id = findFirstMatchingReference(refArray, references)
        if (id) {
          history.push("/corporate/" + id + "/demand-request");
        }
      } 
      else if (hasPermission("CORPORATE_ACCESS") && corporateId) {
        history.push("/corporate/" + corporateId + "/demand-request");
      }
    } else if (tittle === "SUPPLY_UPDATES") {
      if (hasPermission("CORPORATE_ACCESS")) {
        history.push("/corporate/" + corporateId + "/supply-request");
      } 
      else if (hasPermission("BUYER_PROFILE") && references) {
        let refArray = getReferences().split(',');
        let id = findFirstMatchingReference(refArray, references)
        if (id) {
          history.push("/buyer/" + id + "/supply-request");
        }
      } 
      else if (hasPermission("BUYER_PROFILE") && buyerId) {
        history.push("/buyer/" + buyerId + "/supply-request");
      } 
    } 
  }

  function findFirstMatchingReference(array1, array2) {
    const set1 = new Set(array1);
    const matchingElement = array2.find(element => set1.has(element));
    return matchingElement;
  }

  useEffect(() => {
    setLoadNotifications(
      _.union(changeKey(notifications), notificationsUpdate)
    );
  }, [notificationsUpdate, notifications]);

  function getModuleUrl(module) {
    const role = getRole();
    const refs = getRefCount();

    const farmUser = refs === 1 && (role >= 1000 && role <= 9999);
    if (module === MODULE.SMART_FARM) {
      return farmUser ? "/" + getReferences() + "/plot" : "/";
    } else if (module === MODULE.DAILY_UPDATE) {
      return farmUser
        ? "/" + getReferences() + "/daily-update/scouting"
        : "/module/daily-update/farms";
    } else if (module === MODULE.CASH_FLOW) {
      return farmUser
        ? "/" + getReferences() + "/cash-flow/yield"
        : "/module/cash-flow/farms";
    } else if (module === MODULE.DASHBOARD) {
      return farmUser ? "/" + getReferences() + "/dashboard" : "/";
    }
    if (module === MODULE.CCO) {
      if ([510, 55, 54, 52].includes(role)) {
        return "/cco/cco-report"
      } else if ([210, 410].includes(role)) {
        return "/cco/issue"
      } else {
        return "/cco/dashboard"
      }
    } else if (module === MODULE.XINDICATE) {
      if ([510, 410, 210].includes(role)) {
        return "/xindicate/participant"
      }
      if ([1000].includes(role) && farmsData && farmsData.length > 0 && farmsData[0].id && farmsData[0].corporateId) {
        return "/xindicate/participant-view/" + farmsData[0].corporateId  + "/" + farmsData[0].id;
      }
      return "/xindicate/dashboard/participant"
    } else if (module === MODULE.SETTINGS) {
      return "/module/settings/farms"
    }
  }

  

  function showDashboard() {
    const role = getRole();
    const refs = getRefCount();

    return refs === 1 && (role >= 1000 && role <= 9999);
  }

  //
  //
  const {t, i18n} = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  function brand() {
    if (process.env.REACT_APP_DOMAIN === "DUNYA") {
      return (
        <img
          onClick={() => goToHome()}
          className="dunya-logo"
          src={Dlogo}
          alt="logo"
          height={"40px"}
          width={"auto"}
        />
      );
    } else {
      return (
        <img
          onClick={() => goToHome()}
          className="sa-logo"
          src={Logo}
          alt="logo"
          height={"26px"}
          width={"auto"}
        />
      );
    }
  }

  let navItems = [
    {
      title: t("title.DASHBOARD"),
      isActive: onPaths("/dashboard"),
      to: getModuleUrl(MODULE.DASHBOARD),
      permissions: hasPermission("SMART_FARM") && showDashboard() && userModules[MODULE.SMART_FARM],
      webScreen: true,
      icon: "map",
    }, {
      title: t("title.SMART_FARM"),
      isActive: onPaths("SF"),
      to: getModuleUrl(MODULE.SMART_FARM),
      permissions: hasPermission("SMART_FARM") && userModules[MODULE.SMART_FARM],
      webScreen: true,
      icon: "book-open",
    }, {
      title: t("navbar.SCOUTING"),
      isActive: onPaths("/daily-update"),
      to: getModuleUrl(MODULE.DAILY_UPDATE),
      permissions: hasPermission("DAILY_UPDATE") && userModules[MODULE.DAILY_UPDATE],
      webScreen: true,
      icon: "check-square",
    }, {
      title: t("navbar.CASH_FLOW"),
      isActive: onPaths("/cash-flow"),
      to: getModuleUrl(MODULE.CASH_FLOW),
      permissions: hasPermission("CASH_FLOW") && userModules[MODULE.CASH_FLOW],
      webScreen: true,
      icon: "dollar-sign",
    },
    // {
    //   title: t("navbar.BIRD_VIEW"),
    //   isActive: onPaths("/map-data"),
    //   to: "/module/map-data/farms",
    //   permissions: hasPermission("BIRD_VIEW") && userModules[MODULE.BIRD_VIEW],
    //   webScreen: true,
    //   icon: "map",
    // },
    {
      title: t("navbar.FMS"),
      isActive: onPaths("/xindicate"),
      to: getModuleUrl("XINDICATE"),
      permissions: hasPermission("FMS"),
      webScreen: true,
      icon: "user",
    }
    // , {
    //   title: t("navbar.CCO"),
    //   isActive: onPaths("/cco"),
    //   to: getModuleUrl("CCO"),
    //   permissions: hasPermission("CCO"),
    //   webScreen: true,
    //   icon: "user",
    // }
  ]

  if (isSuper() || getRole() === 9) {
    navItems.push({
      title: t("navbar.SENZAGRO_CONNECT"),
      isActive: onPaths("/ccr"),
      to: "/ccr/ccr-supply",
      permissions: hasPermission("CCR_PROFILE"),
      webScreen: true,
      icon: "share-2",
    });
  }

  let mobileNavbar = [
    {
      title: t("title.SENZAGRO") + packageJson.version,
      click: about,
      icon: "alert-circle"
    }, {
      title: t("title.CHANGE_PASSWORD"),
      click: showChangePassword,
      icon: "lock"
    }, {
      title: t("title.LOGOUT"),
      click: logOut,
      icon: "log-out",
      logout: true
    }
  ]

  let languages = [
    {
      title: t("title.ENGLISH"),
      lng: "en",
      icon: "E",
    }, {
      title: t("title.TAMIL"),
      lng: "ta",
      icon: "T",
    }, {
      title: t("title.SINHALA"),
      lng: "si",
      icon: "S",
    }, {
      title: "JAPANESE",
      lng: "ja",
      icon: "J",
    }
  ]
  let settingList = [
    {
      title: t("navbar.SETTINGS"),
      permission: hasPermission("SETTINGS"),
      click: setting,
      desc: t("title.MANAGE_YOUR_FARM_RELATED_SETTINGS")
    }, {
      title: t("navbar.SYSTEM_SETTINGS"),
      permission: hasPermission("SETTINGS.SYSTEM_SETTING"),
      click: systemSetting,
      desc: t("title.MANAGE_SYSTEM_WISE_SETTINGS")
    }, {
      title: t("title.CORPORATE_SETTINGS"),
      permission: hasPermission("CORPORATE_SETTING"),
      click: corporateSetting,
      desc: t("title.MANAGE_CORPORATE_RELATED_SETTINGS")
    }, {
      title: t("title.BUYER_PROFILE"),
      permission: hasPermission("BUYER_PROFILE"),
      click: buyerSetting,
      desc: t("title.MANAGE_BUYER_PROFILES")
    }, {
      title: t("title.ACCOUNT_SETTING"),
      permission: hasPermission("SETTINGS.ACCOUNT_SETTING"),
      click: accountSetting,
      desc: t("title.MANAGE_ACCOUNT_RELATED_SETTINGS")
    },
  ]

  function expandPageNotification() {
    setDropDownNotification(false);
    history.push("/notification-page");
  }

  let txts = {
    language: t("title.SELECT_LANGUAGE"),
    notifications: t("title.NOTIFICATIONS"),
    lastHour: t("title.LASTHOUR"),
    lastDay: t("title.LASTDAY"),
    lastWeek: t("title.LASTWEEK"),
    all: t("title.ALL"),
    emptyNotifications: t("emptyMsg.NO_NOTIFICATIONS"),
  }

  useEffect(() => {
    if (!corporateEnable) {
      return;
    }

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `user/` + getUserId() + `/corporate`)
      .then(function (response) {
        dispatch(corporateList(response.data.content))
        dispatch(getFilterCorporateList(response.data.content))
        if (isModule(location.pathname, MODULE.XINDICATE) || isModule(location.pathname, MODULE.DASHBOARD)) {
          setIsCcoOrFms(true)
          if (response.data.content.length > 0 && !selectedCorporate)  {
            dispatch(getSelectedCorporate(response.data.content[0].id));
          }
        } else {
          setIsCcoOrFms(false)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }).finally(() => {
      dispatch(toggleLoader(false))
    });
  }, [corporateEnable, location])

  function handleSearchCorporate(e) {
    let val = e.target.value;
    setSearchValue(val)
    if (val !== "") {
      let res = _.filter(corporateDataList, function (item) {
        return _.values(_.pick(item, 'name')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      dispatch(getFilterCorporateList([...res]));
    } else {
      dispatch(getFilterCorporateList([...corporateDataList]));
    }
  }

  useEffect(() => {
    setSearchValue('')
  }, [location.pathname])

  useEffect(() => {
    if (getRole() < 1000) {
      setCorporateEnable(true);
    }
  }, [])
  return (<div>
      <NavbarLayout toggleNotificationTruncate={toggleNotificationTruncate} isNotificationExpand={isNotificationExpand}
                    notificationID={notificationID} goToNotification={goToNotification} txts={txts} goToBuyerNotification={goToBuyerNotification}
                    settingPermission={hasPermission("SETTINGS")}   selectedCorporate={selectedCorporate}
                    notificationActive={dropDownNotification || location.pathname.match("/notification-page")}
                    expandPageNotification={expandPageNotification} changeLanguage={changeLanguage} name={getName()}
                    settingList={settingList} languages={languages} navItems={navItems} mobileNavbar={mobileNavbar}
                    loadNotifications={loadNotifications} role={role} refs={refs}  fmsOrCco={isCcoOrFms}
                    corporateChange={(id) => dispatch(getSelectedCorporate(id))} handleSearchCorporate={handleSearchCorporate} 
                    corporateEnable={!([VIEW.SYSTEM_SETTINGS, VIEW.CORPORATE_LIST, VIEW.SETTINGS, VIEW.BUYER_PROFILE, VIEW.BUYER_DETAIL, VIEW.BUYER_USER, VIEW.CCR_PROFILE, VIEW.WATER_RESOURCE, VIEW.ACCOUNT_SETTING, VIEW.ACCOUNT_USER].includes(view)) && corporateEnable}
                    setNotificationsUpdate={setNotificationsUpdate} setNotifications={setNotifications}
                    notificationUpdateRefresh={notificationUpdateRefresh} searchValue={searchValue} setSearchValue={setSearchValue}
                    setNotificationUpdateRefresh={setNotificationUpdateRefresh} brand={() => brand()}
                    corporateDataList={corporateDataList} corporateListFilter={corporateListFilter}
      />
    </div>
  )
};

export default MainNavBar;
