import React, {useEffect, useState} from 'react';
import Crops from "../xindicate/participant/crops";
import {useParams} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {changeView, toggleLoader} from "../../shared/actions/setting";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import * as _ from "underscore";
import CropAgronomyView from '../system-settings/crop-agronomy/crop-agronomy-view';
import pdfImg from "../../images/PDF.svg"
import {useTranslation} from 'react-i18next';
import CashFlowCropView from "./cash-flow-crop-view";
import {VIEW} from "../../utils/Enum";
import {getUserId} from "../../shared/utils/SharedAuthentication";


const CashFlowCrop = () => {
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const [cropAgronomyToggle, setCropAgronomyToggle] = useState(false);
  const [cropView, setCropView] = useState(false);
  const [cropNameList, setCropNameList] = useState([]);
  const [cropNameFilterList, setCropNameFilterList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [print, setPrint] = useState(false);
  const [data, setData] = useState(false);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    dispatch(changeView(VIEW.FARM_VIEW));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`)
      .then(res => {
        setCropNameList(res.data.content)
        setCropNameFilterList(res.data.content)
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [])

  function onPrint() {
    setPrint(true)

    setTimeout(function () {
      window.print();
    }, 500);
  }

  window.onafterprint = function () {
    setPrint(false);
  }

  function onViewClick(crop, index) {
    let values = {};
    setIsViewVisible(true);
    values = crop;
    if (crop.houseHoldHead) {
      values.name = crop.name;
      values.practices = crop.stages.practices;
    }
    setSelectedData(values)
  }

  function handleSearch(e) {
    let val = e.target.value;
    if (val !== "") {
      let res = _.filter(cropNameFilterList, function (item) {
        return _.values(_.pick(item, 'cropName', 'category')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      setCropNameList(res);
    } else {
      setCropNameList(cropNameFilterList);
    }
  }


  return (
    <div className="container pb-3">
      {cropView && <FeatherIcon icon={"arrow-left"}
                                onClick={() => setCropView(false)}
                                className={"sa-cursor mb-2"}/>}
      <div className="single-card m-rl-m-8 p-a-16" hidden={isViewVisible}>
        <div hidden={cropAgronomyToggle || cropView}>
          <Crops id={farmId} type={"farmId"} view={(dataCrop, cropAgronomy) => {
            setCropView(true);


            setData({crop: dataCrop, cropAgronomy: cropAgronomy})
          }}/>
        </div>
        {cropView && data.crop.plantedDate && <CashFlowCropView data={data}/>}

        <div hidden={!cropAgronomyToggle}>
          <div className={'crop-agronomy-heading mb-3'}>
            <span>{t("title.CROP_AGRONOMY")}</span>
          </div>
          <div className={'form-group d-flex sa-table-filter sa-filter-row mb-3'}>
                    <span className="sa-table-btn-mute sa-search-align sa-table-float-left sa-participant-search">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name={t("placeHolder.SEARCH")}
                                       onChange={handleSearch}
                                       autoComplete={"off"} type="text" placeholder="Search"/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>


                            </span>
            {/*<span>*/}
            {/* <select name="status" className="sa-filter sa-table-h-float-l  sa-table-float-left sa-table-m-r-0">*/}
            {/*  <option value={""}>Vegetable</option>*/}
            {/*  <option>REPORTED</option>*/}
            {/*  <option>ASSIGNED</option>*/}
            {/*  <option value={"IN_PROGRESS"}>IN PROGRESS</option>*/}
            {/*  <option>DONE</option>*/}
            {/*  <option>FIXED</option>*/}
            {/* </select>*/}
            {/*</span>*/}

          </div>


          <div className={'crop-agronomy-list'}>


            {cropNameList.length > 0 && cropNameList.map((item, index) => (
              <div className={'crop-agro-card'} onClick={() => {
                setIsViewVisible(true);
                onViewClick(item, index);
              }} key={item.id}>
                <div className={'crop-agro-card-img-container'}>
                  <img src={item.image} width={'48px'}/>
                </div>
                <div>
                  <div className={'crop-agro-card-txt'}>{item.cropName}</div>
                  <div className={'crop-agro-card-sub-txt'}>{item.category}</div>
                </div>
              </div>
            ))}


          </div>


        </div>


        <div
          className={'crop-toggle-txt ' + (cropAgronomyToggle ? 'justify-content-end' : 'justify-content-start')}
          onClick={() => setCropAgronomyToggle(!cropAgronomyToggle)}>
          {cropAgronomyToggle && <div className={'crop-toggle-icon'}>
            <FeatherIcon icon={'chevrons-left'} className={'crop-toggle-icon-arrow'}/>
          </div>}
          {!cropView && (!cropAgronomyToggle ? t("title.CROP_AGRONOMY") : t("title.CROP_LIST"))}
          {!cropAgronomyToggle && !cropView && <div className={'crop-toggle-icon'}>
            <FeatherIcon icon={'chevrons-right'} className={'crop-toggle-icon-arrow'}/>
          </div>}

        </div>
        <div>

        </div>
      </div>
      {isViewVisible && <div>
        <div className={'d-flex align-items-center pdf-hide'}>
          <div className={"mb-3 pdf-hide"}>
            <FeatherIcon icon={"arrow-left"} onClick={() => {
              setIsViewVisible(false)
            }}
                         className={"sa-cursor"}/> {selectedData.cropName}
          </div>
          <div className={'pdf-hide pdf-download-btn ml-auto '} onClick={onPrint}>
            <img src={pdfImg} className={'mr-1'}/>
            {t("button.DOWNLOAD")}
          </div>
        </div>
        <CropAgronomyView onPrint={onPrint} crop={selectedData}/>
      </div>}

    </div>
  );
};

export default CashFlowCrop;
