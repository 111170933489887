import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toggleLoader} from "../../shared/actions/setting";
import {daysInMonth} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";

export default function DashboardCurrentScouting(props) {


  const [task, setTask] = useState([]);
  const [fromDate, setFromDate] = useState();
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();


  useEffect(() => {
    var date = new Date(),
      month = ("0" + (date.getMonth() + 1)).slice(-2)
    setFromDate([date.getFullYear(), month].join("-"));
  }, [])


  useEffect(() => {
    if (!fromDate || props.crop) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + "/scout-summary" + '?from=' + fromDate + `-01` + '&to=' + fromDate + '-' + daysInMonth(fromDate.slice(5, 7), fromDate.slice(0, 4)))
      .then(function (response) {
        setTask(response.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [fromDate])

  useEffect(() => {
    if (!props.crop) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/task-summary?from=` + props.startDate + `&to=` + props.endDate)
      .then(function (response) {
        setTask(response.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, []);

  function percentage(total, done) {
    return done / total * 100
  }

  let m_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  return (
    <div className={!props.crop && "dashboard-irrigation-container"}>
      {!props.crop && <div className={"dashboard-card-title"}>{t("title.CURRENT_MONTHLY_SCOUTING")}</div>}

      <div className={"mt-4"}>
        <div className={"d-flex"}>
          {task.taskDone > 0 && <div
            className={"financial-txt"}>{task.taskTotal ? (percentage(task.taskTotal, task.taskDone) + " %") : "0 %"}</div>}
          {!props.crop && <div className={"financial-txt mx-auto"}>{m_names[new Date().getMonth()]}</div>}
          <div
            className={"financial-txt"}>{task.taskTotal ? (100 - percentage(task.taskTotal, task.taskDone) + " %") : "100 %"}</div>
        </div>
        <div className={"progress rounded-0 " + (props.crop ? "bg-orange" : "progress-financial")}>
          <div className="progress-bar bg-primary-green rounded-0" role="progressbar"
               style={{width: percentage(task.taskTotal, task.taskDone) + '%'}}
               aria-valuenow={percentage(task.taskTotal, task.taskDone)}
               aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div className={"d-flex"}>
          {task.taskDone > 0 && <div className={"financial-type-income"}>{props.crop ? "Done" : "COMPLETED"}</div>}
          <div
            className={"financial-type-expense " + (props.crop ? "color-orange" : "text-err")}>{t("title.PENDING")}</div>
        </div>
      </div>

    </div>
  )
}
