import React, {useEffect, useState} from 'react';
import PlotIrrigationManual from "./plot-irrigation-manual";
import {useParams} from "react-router-dom";
import {IRRIGATION_MODE} from "../../../utils/Enum";
import {hasPermission,} from "../../../utils/Authentication";
import PlotIrrigationSchedule from "./plot-irrigation-schedule";
import PlotIrrigationDaily from "./plot-irrigation-daily";
import FeatherIcon from "feather-icons-react";
import FarmIrrigationModeChange from "../farm/irrigation-mode-change";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {useTranslation} from 'react-i18next';
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId, isSuper} from "../../../shared/utils/SharedAuthentication";
import PlotAutomationLayout from './plot-automation-layout';
import SensorList from './sensor-list';


const PlotIrrigationSetting = (props) => {
  // const {plotId} = useParams();
  const {farmId} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [irrigation, setIrrigation] = useState(null);
  const [isAddIrrigation, setIsAddIrrigation] = useState(null);
  const [isLoadMode, setIsLoadMode] = useState(true);
  const [buttonHide, setButtonHide] = useState(true);
  const [modeChangeHide, setModeChangeHide] = useState(false);
  const [isDeleteIrrigation, setIsDeleteIrrigation] = useState(false);


  const plotMqttStatus = useSelector(state => {
    return state.plotList.plotMqtt;
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  const clusters = useSelector(state => {
    return state.sensor.clusters;
  });

  useEffect(() => {
    if (!isLoadMode || isDeleteIrrigation) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation')
      .then(function (response) {
        let irr = {};
        if (response.data.content) {
          irr = toBrowserTimeZoneAll(response.data.content);
        }
        setIrrigation(irr);
        if (!response.data.content || !response.data.content.mode || !response.data.content.valveCode || [IRRIGATION_MODE.DAILY, IRRIGATION_MODE.MANUAL].includes(response.data.content.mode)) {
          setButtonHide(true);
        } else {
          setButtonHide(false);
        }
        setIsLoadMode(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [isLoadMode]);

  useEffect(() => {
    setIsLoadMode(true);
  }, [props.plotId]);

  function addIrrigation() {
    setIsAddIrrigation(true);
  }

  function toBrowserTimeZoneAll(irrigation) {
    // if (irrigation.schedules) {
    //   irrigation.schedules.forEach(e=>{
    //     // console.log(e.startTime);
    //     // console.log(toBrowserTimeZone("2022-01-01 "+e.startTime+":00"))
    //     let converted = toBrowserTimeZone("2022-01-01 " + e.startTime + ":00");
    //     irrigation.startTime = converted.substring(11, 16);
    //
    //     converted=toBrowserTimeZone("2022-01-01 " + e.endTime + ":00");
    //     irrigation.endTime = converted.substring(11, 16);
    //   })
    // }
    return irrigation;
  }


  useEffect(() => {
    if (!plotMqttStatus || !plotMqttStatus.mode || isLoadMode) {
      return;
    }
    if (irrigation && plotMqttStatus.mode !== irrigation.mode) {
      dispatch(toggleConfirmationDialog({
        isVisible: true,
        type: "request",
        confirmationHeading: t("confirmation_msg.IRRIGATION_MODE_CHANGED"),
        confirmationDescription: t("confirmation_msg.SOMEONE_HAS_CHANGED_IRRIGATION_MODE_OF_THIS_PLOT_PLEASE_REFRESH_THE_PAGE_TO_GET_THE_UPDATE")
      }));
    }
  }, [plotMqttStatus]);

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    setIsLoadMode(true);
  }, [confirmationDialog]);


  function updateIrrigation(res) {
    setIrrigation(res);
  }

  function getIrrigation() {
    if (!irrigation || !irrigation.valveCode) {
      return null;
    }
    switch (irrigation.mode) {
      case IRRIGATION_MODE.MANUAL:
        return (<PlotIrrigationManual plotId={props.plotId} irrigation={irrigation} visibleWidget={props.visibleWidget}/>);

      case IRRIGATION_MODE.SCHEDULE:
        return !props.visibleWidget && (
          <PlotIrrigationSchedule changeAdd={() => setIsAddIrrigation(false)} hide={(hide) => {
          setButtonHide(hide);
          setModeChangeHide(hide)
        }} onReload={() => setIsLoadMode(true)} setIsDeleteIrrigation={setIsDeleteIrrigation}
                                        isAddIrrigation={isAddIrrigation} plotId={props.plotId}
                                        irrigation={irrigation}/>);
      case IRRIGATION_MODE.AUTOMATION:
        return (
          <div>
            {isSuper() && <SensorList/>}
            <PlotAutomationLayout plotId={props.plotId} isAddIrrigation={isAddIrrigation} irrigation={irrigation} clusters={clusters}
                                    updateIrrigation={(res) => updateIrrigation(res)}
                                    changeAdd={() => setIsAddIrrigation(false)}
                                    isDeleteIrrigation = {isDeleteIrrigation}
                                    setIsDeleteIrrigation = {() => setIsAddIrrigation(false)}
                                    hide={(hide) => {
                                      setButtonHide(hide);
                                      setModeChangeHide(hide)
                                    }}
                                    visibleWidget={props.visibleWidget}
                                    buttonHide={buttonHide}
                                    addIrrigation={addIrrigation} isFarmIrrigation={props.isFarmIrrigation}
                                    farmId={farmId}/>
 
        </div>
          );
      case IRRIGATION_MODE.DAILY:
        return (<PlotIrrigationDaily plotResponse={irrigation} plotId={props.plotId} visibleWidget={props.visibleWidget}/>);

    }
  }

  function onChange() {
    setIsLoadMode(true);
  }

  return (
    <div>
      <div>
        {hasPermission("WRITE") &&
          <div className="pull-right m-t-25">
            <button
              hidden={(buttonHide || isAddIrrigation || props.visibleWidget || (irrigation && irrigation.mode === IRRIGATION_MODE.AUTOMATION))}
              className="btn btn-sa-secondary pis-add" style={{padding: 8}} onClick={addIrrigation}><FeatherIcon
              icon={"plus"}/> <span className={"hide-375"}>{t("button.ADD")}</span>
            </button>
          </div>
        }
        <FarmIrrigationModeChange plotId={props.plotId} valveCode={irrigation && irrigation.valveCode}
                                  hideButton={!props.isModeOnly && (modeChangeHide || isAddIrrigation || props.visibleWidget)}
                                  changeIrrigation={isLoadMode} onChange={() => onChange()}
                                  farmId={farmId} visibleWidget={props.visibleWidget}/>
        {isSuper() &&
          <div>Valve Code: {irrigation?.valveCode}</div>
        }
        {!props.isModeOnly && irrigation && !irrigation.valveCode && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div
              className={"empty-results-text"}>{t("title.VALVE_HAS_NOT_BEEN_CONFIGURED_TO_THIS_PLOT_PLEASE_ADD")}</div>
          </div>)
        }
        
        {irrigation && irrigation.mode !== IRRIGATION_MODE.MANUAL && irrigation.valveCode && (isSuper() || props.visibleWidget) &&
          <PlotIrrigationManual hideButton={props.visibleWidget ? false : true} visibleWidget={props.visibleWidget} plotId={props.plotId} irrigation={irrigation}/>
        }
        {!props.isModeOnly && getIrrigation()}
      </div>
      {!props.isModeOnly && isEmpty(irrigation) && (
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div
            className={"empty-results-text"}>{t("title.VALVE_HAS_NOT_BEEN_CONFIGURED_PLEASE_ADD_OR_CONTACT_ADMIN")}</div>
        </div>)
      }
    </div>
  )
};

export default PlotIrrigationSetting
