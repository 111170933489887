import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import PlotHeader from "./plot-header";
import SensorList from "./sensor-list";
import PlotInfo from "./plot-info";
import {TOGGLE} from "../../../utils/Enum";
import {useDispatch, useSelector} from "react-redux";
import {getBottomReached} from "../../../actions/plot";
import PlotReport from "./plot-report";
import PlotReportHandler from "./plot-report-handler";
import PlotSetting from "../../settings/plot/plot-setting";
import PlotDevice from "./plot-device";
import PlotIrrigation from "./plot-irrigation";
import PlotUserDetails from "../../settings/plot/plot-user-details";
import CashFlowCrop from "../../cash-flow/cash-flow-crop";

const PlotLayout = () => {
  const dispatch = useDispatch();
  const [showFadeEffect, setShowFadeEffect] = useState(false);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  function bottomReached() {
    const offset = document.getElementById('listView').offsetHeight;
    const scrolledHeight = document.getElementById('listView').scrollTop;
    dispatch(getBottomReached((offset + scrolledHeight + 1) >= document.getElementById('listView').scrollHeight))


  }

  return (
    <div id="listView"
         className={"list-view " + (toggle === TOGGLE.LIST ? 'full-list-view' : 'half-list-view overflow-auto')}
         onScroll={bottomReached}>
      <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list bg-img-none" : ""}/>
      {/*<Container>*/}
      <PlotHeader/>
      <div className={"half-list-container"}>
        <Switch>
          <Route path="/:farmId/plot/:plotId/sensor" component={SensorList}/>
          <Route path="/:farmId/plot/:plotId/info" component={PlotInfo}/>
          <Route path="/:farmId/plot/:plotId/schedule" component={PlotIrrigation}/>
          <Route path="/:farmId/plot/:plotId/user" component={PlotUserDetails}/>
          <Route path="/:farmId/plot/:plotId/config" component={PlotSetting}/>
          <Route path="/:farmId/plot/:plotId/report" component={PlotReport}/>
          <Route path="/:farmId/plot/:plotId/devices" component={PlotDevice}/>
          <Route path="/:farmId/plot/:plotId/reportHandler" component={PlotReportHandler}/>
          <Route path="/:farmId/plot/:plotId/crop" component={CashFlowCrop}/>
        </Switch>
      </div>
      {/*</Container>*/}
    </div>
  );
};

export default PlotLayout;
