import {isAllFalse, isSL_NIC, isSLMobile} from "./Utils";
import {ACTUATOR_TYPE, CASH_FLOW_TYPE, EXPENSE_CATEGORY} from "./Enum";
import {isEmpty} from "../shared/utils/utils";


export function validateIrrigationSchedule(values) {
  let errors = {};
  let t1 = Date.parse('20 Aug 2000 ' + values.startTime);
  let t2 = Date.parse('20 Aug 2000 ' + values.stopTime);
  if (!values.startTime || values.startTime === "") {
    errors.startTime = 'Start time is required';
  }

  if (!values.stopTime || values.stopTime === "") {
    errors.stopTime = 'End time is required';
  } else if (t1 > t2) {
    errors.stopTime = "End time Should be greater than start time"
  } else if (Math.abs(t2 / 60000 - t1 / 60000) > 61) {
    errors.startTime = "Irrigation time limit should not exceed 61 Minutes"
  }

  if (!values.days || isEmpty(values.days) || isAllFalse(values.days)) {
    errors.days = 'Day(s) is required';
  }
  return errors;
}

export function validateAutomationSchedule(values) {
  let errors = {};
  let t1 = Date.parse('20 Aug 2000 ' + values.startTime);
  let t2 = Date.parse('20 Aug 2000 ' + values.stopTime);
  if (!values.startTime || values.startTime === "") {
    errors.startTime = 'Start time is required';
  }

  if (!values.stopTime || values.stopTime === "") {
    errors.stopTime = 'End time is required';
  } else if (t1 > t2) {
    errors.stopTime = "End time Should be greater than start time"
  } 
  if (!values.days || isEmpty(values.days) || isAllFalse(values.days)) {
    errors.days = 'Day(s) is required';
  }
  return errors;
}

export function validateIrrigationAutomation(values) {
  let errors = {};
  if (!values.number) {
    errors.number = 'Sensor is required';
  }

  return errors;
}

export function validateAutomationMaxTime(values) {
  let errors = {};
  if (!values.hours && values.minutes) {
    errors.hours = 'Hours field is empty';
  }
  if (!values.minutes && values.hours) {
    errors.minutes = 'Minutes field is empty';
  }

  if (!values.hoursForDay && values.minutesForDay) {
    errors.hoursForDay = 'Hours For Day field is empty';
  }
  if (!values.minutesForDay && values.hoursForDay) {
    errors.minutesForDay = 'Minutes For Day field is empty';
  }

  return errors;
}


export function FarmAdd(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.farmType) {
    errors.farmType = 'Farm Type is required';
  }
  if (!values.location) {
    errors.location = 'Location is required';
  }
  if (!values.area) {
    errors.area = 'Area is required';
  }
  if (!values.corporateId) {
    errors.corporateId = "Please select the corporate";
  }
  if (values.contactEmail && !/\S+@\S+\.\S+/.test(values.contactEmail)) {
    errors.contactEmail = 'Email is not valid';
  }

  return errors;
}

export function PlotAddValid(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.crop) {
    errors.crop = 'Crop name is required';
  }
  if (!values.area) {
    errors.area = 'Area is required';
  }

  return errors;
}

export function validateAlert(values) {
  let errors = {};

  // if (!values.high) {      // 0 also possible value for alert's high
  //   errors.high = 'High is required';
  // }
  return errors;
  //
}

export function validateIrrigationMode(values) {
  let errors = {};
  if (!values.mode || values.mode === "") {
    errors.mode = 'Irrigation Mode is required';
  }

  return errors;
}

export function validateSource(values) {
  let errors = {};
  if (!values.mode || values.mode === "") {
    errors.mode = 'Source is required';
  }

  return errors;
}

export function successForm(values) {
  return {};
}


export function plotCycleerror(values) {
  let errors = {};

  if (!values.stage) {
    errors.stage = 'Stage is required';
  }

  return errors;
}

export function ValidateAlertUser(values) {
  let errors = {};
  return errors;
}

export function ValidateFarmAlertUser(values) {
  let errors = {};
  return errors;
}


export function validateActuators(values) {
  let errors = {};
  if (!values.kitId) {
    errors.kitId = 'Kid Id is required';
  }
  if (!values.code) {
    errors.code = 'code is required';
  }


  return errors;
}

export function validateSupply(values) {
  let errors = {};
  if (!values.item) {
    errors.item = 'Crop item is required';
  }
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.price) {
    errors.price = 'Price is required';
  }
  if (!values.deadLine) {
    errors.deadLine = 'Deadline for EOI is required';
  }

  return errors;
}

export function validateSupplyUpdate(values) {
  let errors = {};
  if (!values.ccr) {
    errors.quantity = 'CCR is required';
  }
  return errors;
}

export function validateCCRUpdate(values) {
  let errors = {};
  if (!values.ccr) {
    errors.ccr = 'CCR is required';
  }
  return errors;
}

export function validateKitId(values) {
  let errors = {};
  if (!values.kitId) {
    errors.kitId = 'Kit Id is required';
  }
  return errors;
}

export function validateOrderUpdate(values) {
  let errors = {};
  // if (!values.ccr) {
  //   errors.quantity = 'CCR is required';
  // }
  return errors;
}

export function validateSupplyCallSummary(values, callStatus, supplyStatus) {
  let errors = {};
  if (callStatus === "CONTACTED_SUPPLIER" && supplyStatus === "SUPPLY_CONFIRMED") {
    if (!values.contactedDate) {
      errors.contactedDate = 'Contacted Date is required';
    }
    if (!values.negotiatedPrice) {
      errors.negotiatedPrice = 'Negotiated Price is required';
    }
    if (!values.negotiatedQuantity) {
      errors.negotiatedQuantity = 'Quantity is required';
    }
    if (!values.dateOfDeliveryPossible) {
      errors.dateOfDeliveryPossible = 'Date of Delivery Possible is required';
    }
  } else if (callStatus === "CONTACTED_SUPPLIER" ) {
    if (!supplyStatus) {
      errors.supplyStatus = 'Supply Status is required';
    } 
  } else if (!supplyStatus &&!callStatus) {
    errors.callStatus = 'Call Status is required';
  }
  if (callStatus === "CONTACTED_SUPPLIER" && supplyStatus === "SUPPLY_CONFIRMED" && Object.keys(values).length === 0) {
    errors.callSummary = "Call summary is required";
  }
  return errors;
}

export function validateDemandCallSummary(values, callStatus, demandStatus) {
  let errors = {};
  if (callStatus === "CONTACTED_BUYER" && demandStatus === "DEMAND_CONFIRMED") {
    if (!values.contactedDate) {
      errors.contactedDate = 'Contacted Date is required';
    }
    if (!values.negotiatedPrice) {
      errors.negotiatedPrice = 'Negotiated Price is required';
    }
    if (!values.negotiatedQuantity) {
      errors.negotiatedQuantity = 'Quantity is required';
    }
    if (!values.deliveryMode) {
      errors.deliveryMode = 'Delivery Mode is required';
    }
  } else if (callStatus === "CONTACTED_BUYER") {
    if (!demandStatus) {
      errors.demandStatus = 'Demand Status is required';
    }
  } else if (!demandStatus &&!callStatus) {
    errors.callStatus = 'Call Status is required';
  }
  if (callStatus === "CONTACTED_BUYER" && demandStatus === "DEMAND_CONFIRMED" && Object.keys(values).length === 0) {
    errors.callSummary = "Call summary is required";
  }
  return errors;
}

export function validateDemand(values) {
  let errors = {};
  if (!values.item) {
    errors.item = 'Crop item is required';
  }
  if (!values.corporates) {
    errors.corporates = 'At least one corporate should be selected';
  }
  if (values.corporates && values.corporates.length === 0) {
    errors.corporates = 'At least one corporate should be selected';
  }
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.price) {
    errors.price = 'Price is required';
  }
  if (!values.deadLine) {
    errors.deadLine = 'Deadline for EOI is required';
  }

  return errors;
}

export function validateDemandUpdate(values) {
  let errors = {};
  if (!values.corporates) {
    errors.corporates = 'At least one corporate should be selected';
  }
  if (values.corporates && values.corporates.length === 0) {
    errors.corporates = 'At least one corporate should be selected';
  }
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.price) {
    errors.price = 'Price is required';
  }
  if (!values.deadLine) {
    errors.deadLine = 'Deadline for EOI is required';
  }

  return errors;
}

export function validateRequestUpdate(values) {
  let errors = {};
  if (values.status === "NO_RESPONSE_YET") {
    errors.status = 'Choose a status to Update this Order';
  }
  if (values.status === "WILL_BE_PROVIDED_LATER") {
    if (!values.expectedDate) {
      errors.expectedDate = 'Expected Date of Delivery is required';
    }
  }

  return errors;
}

export function validateMyRequestUpdate(values) {
  let errors = {};
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.price) {
    errors.price = 'Price is required';
  }
  if (values.status === "NO_RESPONSE_YET") {
    errors.status = 'Choose a status to Update this Order';
  }
  if (values.status === "WILL_BE_PROVIDED_LATER") {
    if (!values.expectedDate) {
      errors.expectedDate = 'Expected Date of Delivery is required';
    }
  }

  return errors;
}

export function validateNewRequestUpdate(values) {
  let errors = {};
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.price) {
    errors.price = 'Price is required';
  }
  if (!values.status) {
    errors.status = 'Choose a status to Respond this Demand Request';
  }
  if (values.status === "WILL_BE_PROVIDED_LATER") {
    if (!values.expectedDate) {
      errors.expectedDate = 'Expected Date of Delivery is required';
    }
  }

  return errors;
}



export function validateUserSubmit(values) {
  let errors = {};
  if (!values.lastName) {
    errors.lastName = 'Name is required';
  }
  if (!values.userRoleId) {
    errors.userRoleId = 'Role is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email is not valid';
  }
  if (!values.mobile) {
    errors.mobile = 'Phone No is required';
  } else if (!values.mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
    errors.mobile = 'Phone No is not valid';
  }
  if (values.isTable) {
    if (!values.username) {
      errors.username = 'Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password Should match';
    }
  }
  // if(!values.status){
  //   errors.status = 'Status is required';
  // }
  return errors;
}

export function validateBuyerUserSubmit(values) {
  let errors = {};
  if (!values.lastName) {
    errors.lastName = 'Name is required';
  }
  // if (!values.userRoleId) {
  //   errors.userRoleId = 'Role is required';
  // }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email is not valid';
  }
  if (!values.mobile) {
    errors.mobile = 'Phone No is required';
  } else if (!values.mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
    errors.mobile = 'Phone No is not valid';
  }
  if (values.isTable) {
    if (!values.username) {
      errors.username = 'Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password Should match';
    }
  }
  // if(!values.status){
  //   errors.status = 'Status is required';
  // }
  return errors;
}

export function xindicateUserSubmit(values) {
  let errors = {};
  if (!values.lastName) {
    errors.lastName = 'Name is required';
  }
  // if (!values.userRoleId) {
  //   errors.userRoleId = 'Role is required';
  // }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email is not valid';
  }
  if (!values.mobile) {
    errors.mobile = 'Mobile No is required';
  } else if (!values.mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
    errors.mobile = 'Mobile No is not valid';
  }
  if (values.isTable) {
    if (!values.username) {
      errors.username = 'Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password Should match';
    }
  }
  // if (!values.userRoleId) {
  //   errors.userRoleId = 'User is required';
  // }
  // if(!values.status){
  //   errors.status = 'Status is required';
  // }
  return errors;
}

export function validateUserUpdate(values) {
  let errors = {};
  if (!values.lastName) {
    errors.lastName = 'Name is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email is not valid';
  }
  if (!values.mobile) {
    errors.mobile = 'Mobile No is required';
  } else if (!values.mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
    errors.mobile = 'Mobile No is not valid';
  }
  // if(!values.userRoleId){
  //   errors.userRoleId = 'User is required';
  // }
  if (!values.status) {
    errors.status = 'Status is required';
  }
  return errors;
}

export function validateActuator(values) {
  let errors = {};
  if (!values.kitId) {
    errors.kitId = 'Kit ID is required';
  }
  if (!values.code || values.code === "") {
    errors.code = 'Code is required';
  } else if (!values.code.match('[a-z]([-][0-9])*')) {
    errors.code = 'Code is not valid';
  }
  if (!values.type) {
    errors.type = 'Type is required';
  }
  if (values.type === ACTUATOR_TYPE.MIST) {
    if (!values.mistOn) {
      errors.mistOn = 'ON duration is required';
    }

    if (!values.mistOff) {
      errors.mistOff = 'OFF duration is required';
    }
  }

  values.actuatorNumber = parseInt(values.actuatorNumber);
  if (values.actuatorNumber !== 0 && values.actuatorNumber !== 1 && values.actuatorNumber !== 2) {
    errors.actuatorNumber = 'Actuator ID is required';
  }
  let lat = Number(values.lat);
  if (values.lat && (Number.isNaN(lat) || (lat > 180 || lat < -180))) {
    errors.lat = 'Enter Valid Latitude ';
  }
  let lng = Number(values.lng);
  if (values.lng && (Number.isNaN(lng) || (lng > 180 || lng < -180))) {
    errors.lng = 'Enter Valid Longitude ';
  }
  return errors;
}

export function validateFarmInfo(values) {
  let errors = {};
  if (values.name === "") {
    errors.name = 'Farm name is required';
  }
  if (values.location === "") {
    errors.location = 'Location is required';
  }
  if (values.farmType === "") {
    errors.farmType = 'Farm type is required';
  } else {
  }
  if (values.area && values.area <= 0) {
    errors.area = 'Area is not valid';
  }
  // if (values.contactName === "") {
  //   errors.contactName = 'Contact Name is required';
  // }
  // if (values.contactName) {
  //   if (values.contactName.toLowerCase().match(/^[a-z ]+$/) == null) {
  //     errors.contactName = 'Enter Valid Contact Name';
  //   }
  // }
  // if (values.contactEmail === "") {
  //   errors.contactEmail = 'Contact Email is required';
  // }
  // if (values.contactPhoneNumber === "") {
  //   errors.contactPhoneNumber = 'Contact Number is required';
  // }
  // if (values.contactPhoneNumber) {
  //   if (!values.contactPhoneNumber.match(/^(?:0|94|\+94|0094)?(?:7(0|1|2|5|6|7|8)\d)\d{6}$/g)) {
  //     errors.contactPhoneNumber = 'Contact No is not valid';
  //   }
  // }
  return errors;
}

export function validatePlotInfo(values) {
  let errors = {};
  if (values.name === "") {
    errors.name = 'Plot name is required';
  }
  if (values.area && values.area <= 0) {
    errors.area = 'Area is not valid';
  }
  return errors;

}

export function validateSenzagroVisionKitForm(values) {
  let errors = {};
  if ((values.senzagroVisionKitId === "" || !values.senzagroVisionKitId)) {
    errors.senzagroVisionKitId = 'Senzagro Vision Kit Id is required';
  }
  return errors;
}

export function validateKitForm(values) {
  let errors = {};
  if ((values.kitId === "" || !values.kitId) && !values.valveCode && !values.deviceId) {
    errors.kitId = 'Kit Id is required';
  }
  if ((values.valveCode === "" || !values.valveCode) && !values.kitId && !values.deviceId) {
    errors.valveCode = 'ValveCode is required';
  }
  let lat = values.lat ? Number(values.lat) : "";
  if ((values.lat === "" || !values.lat) && values.deviceId) {
    errors.lat = 'Latitude is required';
  } else if (values.lat && (Number.isNaN(lat) || (lat > 180 || lat < -180)) && values.deviceId) {
    errors.lat = 'Enter Valid Latitude ';
  }
  let lng = values.lng ? Number(values.lng) : "";
  if ((values.lng === "" || !values.lng) && values.deviceId) {
    errors.lng = 'Longitude is required';
  } else if (values.lng && (Number.isNaN(lng) || (lng > 180 || lng < -180)) && values.deviceId) {
    errors.lng = 'Enter Valid Longitude ';
  }
  return errors;
}


export function validateCashFlow(values) {
  let errors = {};
  if (!values.category && values.type === CASH_FLOW_TYPE.EXPENSE) {
    errors.category = ' Category is required';
  }
  if (!values.name && values.category !== EXPENSE_CATEGORY.LABOUR_CHARGE) {
    errors.name = ' Yield is required';
  }
  // if (!values.vendor && values.type===CASH_FLOW_TYPE.EXPENSE  && values.category!==EXPENSE_CATEGORY.LABOUR_CHARGE) {
  //   errors.vendor = 'Vendor is required';
  // }
  //   if (!values.buyer && values.type===CASH_FLOW_TYPE.INCOME) {
  //   errors.buyer = 'Buyer is required';
  // }

  if (!values.date) {
    errors.date = 'Date is required';
  }
  if (!values.quantity && values.category !== EXPENSE_CATEGORY.LABOUR_CHARGE && values.category !== EXPENSE_CATEGORY.REPAIR_MAINTENANCE) {
    errors.quantity = 'Quantity is required';
  }
  if (!values.wage && values.category === EXPENSE_CATEGORY.LABOUR_CHARGE) {
    errors.wage = "Labour charge is required";
  }
  if (!values.labour && values.category === EXPENSE_CATEGORY.LABOUR_CHARGE) {
    errors.labour = "Labour Name is required";
  }
  if (!values.price && values.type === CASH_FLOW_TYPE.EXPENSE && values.category !== EXPENSE_CATEGORY.LABOUR_CHARGE) {
    errors.price = 'Total Amount is required';
  }

  if (!values.cashStatus) {
    errors.cashStatus = 'Cash Status is required';
  }
  if (!values.unitPrice && values.type === CASH_FLOW_TYPE.INCOME) {
    errors.unitPrice = 'Unit price is required';
  }

  // if (!values.userId && values.category===EXPENSE_CATEGORY.LABOUR_CHARGE ) {
  //   errors.userId = 'Labour Name is required';
  // }

  if (!values.wageType && values.category === EXPENSE_CATEGORY.LABOUR_CHARGE) {
    errors.wageType = 'Wage type is required';
  }
  return errors;
}

export function validateCashFlowProduct(values) {
  let errors = {};

  if (!values.name) {
    errors.name = ' Product Name is required';
  }
  if (!values.category) {
    errors.category = ' Product Category is required';
  }
  if (!values.type) {
    errors.type = ' Product Type is required';
  }


  return errors;

}

export function validateCropAddFarmer(values) {
  let errors = {};

  if (!values.cropAgronomyId) {
    errors.cropAgronomyId = ' Crop Name is required';
  }
  if (!values.plantedDate) {
    errors.plantedDate = ' Planted Date is required';
  }
  if (values.plantedDate > values.expectedHarvestStartDate) {
    errors.expectedHarvestStartDate = 'Harvest start date should be greater than planted date';
  }
  if (values.expectedHarvestStartDate > values.expectedHarvestEndDate) {
    errors.expectedHarvestEndDate = 'Harvest end date should be greater than harvest start date';
  }
  if (!values.expectedHarvestEndDate) {
    errors.expectedHarvestEndDate = ' Expected Harvest End Date is required';
  }
  if (!values.expectedHarvestStartDate) {
    errors.expectedHarvestStartDate = ' Expected Harvest Start Date is required';
  }
  if (!values.expectedHarvest) {
    errors.expectedHarvest = ' Expected Harvest Amount is required';
  }


  return errors;

}


export function validateLabourChargeSubmit(values) {
  let errors = {};
  if (!values.labourId) {
    errors.labourId = 'Labour name is required';
  }
  // if (!values.type) {
  //   errors.type = 'Type is required';
  // }
  // if (!values.date) {
  //   errors.date = 'Date is required';
  // }
  if (!values.amount) {
    errors.amount = 'Amount is required';
  } else if (!/^[0-9]+$/.test(values.amount)) {
    errors.amount = 'Enter valid amount';
  } else if (values.amount < 1) {
    errors.amount = 'Enter valid amount';
  }
  // if (!values.task) {
  //   errors.task = 'Task is required';
  // }
  // if (!values.plot) {
  //   errors.plot = 'Plot is required';
  // }
  return errors;
}

export function validateScoutUpdate(values) {
  let errors = {};
  if (!values.note) {
    errors.note = 'Name is required';
  }
  // if (!values.type) {
  //   errors.type = 'Type is required';
  // }
  // if (!values.date) {
  //   errors.date = 'Date is required';
  // }
  // if (!values.assigneeId) {
  //   errors.assigneeId = 'Task is required';
  // }
  if (!values.status) {
    errors.status = 'Plot is required';
  }
  return errors;
}

export function validateTasks(values) {
  let errors = {};
  if (!values.note) {
    errors.note = 'Note is required';
  }

  if (!values.assigneeId) {
    errors.assigneeId = 'Assignee is required';
  }
  if (!values.category) {
    errors.category = 'Category is required';
  }

  if (!values.status) {
    errors.status = 'Status is required';
  }
  return errors;
}

export function validateAssignTask(values) {
  let errors = {};
  if (!values.id) {
    errors.id = 'Task is required';
  }
  return errors;
}

export function validateMsg(values) {
  let errors = {};
  if (!values.msg) {
    errors.msg = 'Message is required';
  }
  return errors;
}

export function corporateAdd(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.accountId) {
    errors.accountId = 'AccountId is required';
  }
  if (values.accountId === "5f0ed52a2d3b872199d53040") {
    if (!values.vendorCode) {
      errors.vendorCode = 'Vender Code required';
    }
    if (!values.plantCode) {
      errors.plantCode = 'Plant Code required';
    }
  }
  if (!values.contactPhoneNumber) {
    errors.contactPhoneNumber = 'Contact Phone Number is required';
  }
  if (values.contactEmail) {
    if (!/\S+@\S+\.\S+/.test(values.contactEmail)) {
      errors.contactEmail = 'Email is not valid';
    }
  }
  return errors;
}

export function validateWaterSource(values) {
  let errors = {};

  if (!values.location) {
    errors.location = 'Location is required';
  }
  if (!values.farmIds || values.farmIds.length === 0) {
    errors.farmIds = 'Members are required';
  }

  return errors;
}

export function evaluationValidation(values) {
  let errors = {};

  return errors;
}

export function validateDailyConfig(values) {
  let errors = {};

  // if (!values.assigneeId) {
  //   errors.assigneeId = 'Assignee is required';
  // }
  if (!values.category) {
    errors.category = 'Category is required';
  }

  return errors;
}

export function plotCultivationErrors(values) {
  let errors = {};
  if (!values.cropName) {
    errors.cropName = 'Crop Name is required';
  }
  if (!values.name) {
    errors.name = 'Cultivation Name is required';
  }

  return errors;
}


export function validateYieldSubmit(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Yield name is required';
  }
  if (!values.quantity) {
    errors.quantity = 'Quantity is required';
  }/*else if(!/^[0-9]+$/.test(values.quantity)){
    errors.quantity = 'Enter a valid number';
  }*/ else if (values.quantity <= 0) {
    errors.quantity = 'Enter a valid number';
  }
  if (!values.category) {
    errors.category = 'Category is required';
  }
  return errors;
}

export function validateGroupSubmit(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Group name is required';
  }

  if (!values.type) {
    errors.type = 'Group type is required';
  }

  if (!values.leaderId) {
    errors.leaderId = 'Leader is required';
  }

  if (!values.membersId || values.membersId.length === 0) {
    errors.membersId = 'Farmers are required';
  }

  if (values.startDate > values.endDate) {
    errors.endDate = 'End Date must be later than Start Date';
  }

  return errors;
}

export function validateProgramSubmit(values) {

  let errors = {};
  if (!values.name) {
    errors.name = 'Program name is required';
  }

  if (!values.category) {
    errors.category = 'Category type is required';
  }

  return errors;
}

export function farmAlertvalidate(values) {
  let errors = {};

  return errors;
}

export function farmAlertvalidatepop(values) {
  let errors = {};
  // if (!values.maintainLevel) {
  //   errors.maintainLevel = 'maintainLevel is required';
  // }
  //
  return errors;
}

export function irrigationCycleValidate(values) {
  let errors = {};
  // if (!values.maintainLevel) {
  //   errors.maintainLevel = 'maintainLevel is required';
  // }
  //
  return errors;
}


export function cropDetailsSubmit(values) {
  let errors = {};
  if (!values.name) {
    errors.name = ' Common Name is required '
  }
  return errors;
}

export function cropAgronomyAdd(values) {
  let errors = {};
  if (!values.cropName) {
    errors.cropName = 'Crop Name is required '
  }
  if (!values.category) {
    errors.category = ' Category is required '
  }
  // if (!values.pests) {
  //   errors.pests = ' Pests is required '
  // }
  return errors;
}

export function buyerAdd(values) {
  let errors = {};
  if (!values.companyName) {
    errors.companyName = 'Company Name is required '
  }
  if (!values.registrationNo) {
    errors.registrationNo = 'Registration Number is required '
  }
  if (!values.address) {
    errors.address = 'Address is required '
  }
  if (!values.district) {
    errors.district = 'District is required '
  }
  return errors;
}

export function contactPersonAdd(values) {
  let errors = {};
  if (!values.contactName) {
    errors.contactName = 'Contact Person Name is required '
  }
  if (!values.contactNumber) {
    errors.contactNumber = 'Contact Number is required '
  } else if (!values.contactNumber.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
    errors.contactNumber = 'Contact Number is not valid '
  }
  if (values.email) {
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email is not valid';
    }
  }
  return errors;
}

export function purchasingPowerDetailsAdd(values) {
  let errors = {};
  if (!values.procurementList || values.procurementList.length === 0) {
    errors.procurementList = ' At least One Procurement is required'
  }
  return errors;
}

export function procurementAdd(values) {
  let errors = {};
  if (!values.item) {
    errors.item = ' Item name is required '
  }
  if (!values.category) {
    errors.category = ' Category is required '
  }

  return errors;
}

export function cropAgronomyStageAdd(values) {
  let errors = {};
  // if (!values.practices && !values.fertiliser && !values.irrigationRequirement && !values.name) {
  //   errors.stageErr = ' At least one field is required '
  // }

  if(!values.duration){
    errors.duration = ' Duration is required '
  }
  return errors;
}

export function cropAgronomyDiseaseAdd(values) {
  let errors = {};
  if (!values.practices && !values.symptoms && !values.name) {
    errors.stageErr = ' At least one field is required '
  }
  return errors;
}

export function cropAgronomyPestAdd(values) {
  let errors = {};
  if (!values.practices) {
    errors.pestPractices = ' Practices is required '
  }
  if (!values.name) {
    errors.name = ' Name is required '
  }

  if(!values.pestCategory){
    errors.pestCategory = ' Category is required '
  }

  return errors;
}

export function cropAgronomyZoneAdd(values) {
  let errors = {};
  if (!values.zone) {
    errors.zone = ' Zone is required '
  }
  if (!values.region) {
    errors.region = ' Region is required '
  }
  if (!values.soilType) {
    errors.soilType = ' Soil type is required '
  }
  return errors;
}

export function validateActivitySubmit(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.fields) {
    errors.fields = 'Field(s) is required';
  }
  return errors;
}

export function validateActivityFields(values) {
  let errors = {};
  if (!values.fieldName) {
    errors.fieldName = 'Field Name is required';
  }

  if (!values.type) {
    errors.type = 'Field Name is required';
  }

  if (values.type && values.type === "ENUM" && (!values.options || values.options.length === 0)) {
    errors.options = 'Choice(s) is required';
  }

  return errors;
}


export function validateParticipantSubmit(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!values.gender) {
    errors.gender = 'Gender is required';
  }
  return errors;
}

export function validateFarmerSubmit(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.gender) {
    errors.gender = 'Gender is required';
  }
  if (!values.homeAddress) {
    errors.homeAddress = 'Home Address is required';
  }
  if (!values.nicNumber) {
    errors.nicNumber = 'NIC Number is required';
  }
  if (values.mailAddress) {
    if (!/\S+@\S+\.\S+/.test(values.mailAddress)) {
      errors.mailAddress = 'Email is not valid';
    }
  }
  if (values.nicNumber && !isSL_NIC(values.nicNumber)) {
    errors.nicNumber = 'NIC No is not valid';
  }

  return errors;
}

export function validateKeellsFarmerSubmit(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.gender) {
    errors.gender = 'Gender is required';
  }
  if (!values.homeAddress) {
    errors.homeAddress = 'Home Address is required';
  }
  if (!values.nicNumber) {
    errors.nicNumber = 'NIC Number is required';
  }
  if (values.mailAddress) {
    if (!/\S+@\S+\.\S+/.test(values.mailAddress)) {
      errors.mailAddress = 'Email is not valid';
    }
  }
  if (values.nicNumber && !isSL_NIC(values.nicNumber)) {
    errors.nicNumber = 'NIC No is not valid';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone Number is required';
  } else {
    if (!isSLMobile(values.phoneNumber)) {
      errors.phoneNumber = 'Phone Number is not valid';
    }
  }
  if (values.phoneNumber3 && !isSLMobile(values.phoneNumber3)) {
    errors.phoneNumber3 = 'Phone No 3 is not valid';
  }
  if (values.phoneNumber2 && !isSLMobile(values.phoneNumber2)) {
    errors.phoneNumber2 = 'Phone No 2 is not valid';
  }

  return errors;
}

export function validateTruckSubmit(values) {
  let errors = {};
  if (!values.truckNumber) {
    errors.truckNumber = 'Truck Number is required';
  }
  if (!values.height || !values.width || !values.length) {
    errors.truckDimensions = 'Truck Dimensions is required';
  }


  return errors;
}

export function validateHouseholdSubmit(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.gender) {
    errors.gender = 'Gender is required';
  }
  if (!values.birthDate) {
    errors.birthDate = 'Birth Date is required';
  }
  if (!values.homeAddress) {
    errors.homeAddress = 'Home Address is required';
  }
  if (!values.nicNumber) {
    errors.nicNumber = 'NIC Number is required';
  } else if (values.nicNumber && !isSL_NIC(values.nicNumber)) {
    errors.nicNumber = 'NIC No is not valid';
  }
  if (values.mailAddress) {
    if (!/\S+@\S+\.\S+/.test(values.mailAddress)) {
      errors.mailAddress = 'Email is not valid';
    }
  }

  return errors;
}

export function validateHouseHoldSubmit(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }

  // if (!values.idNumber) {
  //   errors.idNumber = 'ID Number is required';
  // }

  return errors;
}

export function validateAddCropAvailable(values) {
  let errors = {};

  return errors;
}

export function soilReportValidation(values){
  let errors = {};
  if (!values.name) {
    errors.name = 'Soil Report Name is required';
  }

  return errors;
}
