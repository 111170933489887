import React from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PlotAutomationTab = (props) => {
  const {t, i18n} = useTranslation();
  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  return (
    <div className="text-center tab-container m-rl-m-16 d-flex p-b-12">
        <NavLink to={`${!props.isFarmIrrigation ? '/' + props.farmId + '/plot/' + props.plotId + '/schedule/sensorBoundary' : '/' + props.farmId + '/irrigation-setting/sensorBoundary'}`} activeClassName="plot-tog-active">
          <div id={'tabSensor'} className="plots-header-toggles-auto"><FeatherIcon
            className={"icon-in-tab plot-tab-icon-color"} icon={"radio"}/><span
            className={"plot-tab"}> {t("title.SENSOR_READING_BOUNDARY")}</span></div>
        </NavLink>
      <NavLink to={`${!props.isFarmIrrigation ? '/' + props.farmId + '/plot/' + props.plotId + '/schedule/timeBoundary' : '/' + props.farmId + '/irrigation-setting/timeBoundary'}`}  activeClassName="plot-tog-active">
        <div id={'tabIrrigation'} className="plots-header-toggles-auto"><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"clock"}/><span
          className={"plot-tab"}> {t("title.AUTOMATION_IRRIGATION_SCHEDULE")}</span></div>
      </NavLink>
      
        <NavLink to={`${!props.isFarmIrrigation ? '/' + props.farmId + '/plot/' + props.plotId + '/schedule/maxTime' : '/' + props.farmId + '/irrigation-setting/maxTime'}`} activeClassName="plot-tog-active">
          <div id={'tabSensor'} className="plots-header-toggles-auto"><FeatherIcon
            className={"icon-in-tab plot-tab-icon-color"} icon={"radio"}/><span
            className={"plot-tab"}> {t("title.MAXIMUM_IRRIGATION_TIME")}</span></div>
        </NavLink>
      
    </div>
  )
};


export default PlotAutomationTab
