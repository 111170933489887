import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {createStore} from "redux";
import reducer from "./reducers";
import {TOGGLE} from "./utils/Enum";
import 'bootstrap/dist/css/bootstrap.min.css';
import './sa.scss';
import '../src/utils/i18n'


const store = createStore(reducer, {
  farmList: {
    farmList: [],
    filterFarmList: [],
    farmActuators: [],
    farmSourceAll: [],
    selectedFarm: {},
    farmSource: {},
    farmSourceIndex: 0,
    farmMqttStatus: null,
    selectedFarmScout: []
  },
  setting: {
    toggle: TOGGLE.LIST,
    exportObject: null,
    isChangePasswordVisible: false,
    isLoading: false,
    isLoadingCount: 0,
    confirmationDialog: {},
    timePicker: {},
    isLoaderCount: 0,
  },
  plotList: {
    plotList: [],
    selectedPlot: {},
    plotIrrigationStatus: {},
    bottomReached: false,
    plotMqtt: {},
  },

  sensor: {
    clusters: [],
    selectedSensor: {},
    findLocation: false,
    selectedCoordinate: {},
  },
  modules: {
    enabledModules: {},
    farmModules: {},
    selectedCorporate: null,
    farmUser: [],
    corporateList: [],
    corporateListFilter: [],
    getAccountList: [],
    accountListFilter: [],
    selectedAccount: null,
    corporateChange: "INITIAL"
  }
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App/>
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
