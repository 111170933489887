import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {hasPermission,} from "../../../utils/Authentication";
import {ACTUATOR_TYPE, TOGGLE, VIEW_MODE} from "../../../utils/Enum";
import Kit from "../../common/kit";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateActuator} from "../../../utils/FormValidationRules";
import {
  changeToggle,
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader
} from "../../../shared/actions/setting";
import axios from "axios";
import {toast} from "react-toastify";
import {getFindLocation, getSelectedCoordinate} from "../../../actions/sensor";
import {getFarmActuators, getSelectedActuator, getFarmSource} from "../../../actions/farm";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import ReactTooltip from "react-tooltip";
import Debugger from './debugger';

export default function Actuator() {
  const {t, i18n} = useTranslation();
  const [actuators, setActuators] = useState([]);
  const [viewMode, setViewMode] = useState(VIEW_MODE.TABLE);
  const {farmId} = useParams();
  const [selectedValve, setSelectedValve] = useState({});
  const [isModelVisible, setIsModalVisible] = useState(false);
  const[isDebug, setIsDebug] = useState(false);
  const [debugData, setDebugData] = useState({});
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [uniqueActuator, setUniqueActuator] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [isTable, setTable] = useState(false);
  const dispatch = useDispatch();

  const findDeviceLocation = useSelector(state => {
    return state.sensor.findLocation;
  });

  const selectCoordinates = useSelector(state => {
    return state.sensor.selectedCoordinate;
  });


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    setValue
  } = FormHandler(farmUser, validateActuator);

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  function edit(values) {
    values.lat = values.coordinate ? values.coordinate.lat : '';
    values.lng = values.coordinate ? values.coordinate.lng : '';
    if (values.meta && values.meta.TT) {
      let mist = values.meta.TT.split("/");
      values.mistOn = mist[0];
      values.mistOff = mist[1];
    }
    initForm(values);
    setIsUpdateAvailable(true);
    setIsModalVisible(true);
  }

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/valve')
      .then(response => {
        setActuators(response.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(error => console.log(`Error ${error}`))
  }, []);

  function setDebug(value){
    setIsDebug(value)
  }

  function edit(values) {
    values.lat = values.coordinate ? values.coordinate.lat : '';
    values.lng = values.coordinate ? values.coordinate.lng : '';

    if (values.meta) {
      if (values.meta.TT) {
        let mist = values.meta.TT.split("/");
        values.mistOn = mist[0];
        values.mistOff = mist[1];
      }

      if (values.meta.IV) {
        values.volume = values.meta.IV;
      }

      if (values.meta.ID) {
        values.duration = values.meta.ID;
      }
    }


    initForm(values);
    setIsUpdateAvailable(true);
    setIsModalVisible(true);
    setTable(false)
  }

  function debug(values){
    setDebug(true)
    setDebugData(values)


  }

  function deleteValve(actuator) {
    console.log(actuator, "actuator")


    if (actuator.assigned) {
      toast.error(t("errorMsg.ACTUATOR_IS_ASSIGNED_TO_A_ZONE"));
      return;
    }
    for (let act1 of actuators) {
      console.log(act1, "act1")

      if (act1.code[0] == actuator.code[0]) {
        console.log(act1, "act1 inside")
        if (act1.assigned) {
          toast.error(t("errorMsg.ACTUATOR_IS_ASSIGNED_TO_A_ZONE"));

          return
        }
      }
    }


    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACTUATOR")
      ,
      confirmationDescription: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACTUATOR")
    }));
    setSelectedValve(actuator.id);
  }

  function addActuator() {
    setIsUpdateAvailable(false);
    setIsModalVisible(true);
    initForm({actuatorNumber: ''});
    setFormErr("");
    setTable(false)
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/valve/` + selectedValve)
      .then(res => {
        if (res.status === 200) {
          setSelectedValve({});
          setRefresh(true)
          setActuators(actuators.filter(item => item.id !== selectedValve));
          toast.success("Actuator deleted successfully")
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [confirmationDialog]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    dispatch(toggleLoader(true));
    values.farmId = farmId;
    values.actuatorNumber = parseInt(values.actuatorNumber);
    if (values.lat && values.lng) {
      values.coordinate = {"lat": parseFloat(values.lat), "lng": parseFloat(values.lng)};
    }
    if (values.type === ACTUATOR_TYPE.MIST) {
      values.meta = {"TT": values.mistOn + "/" + values.mistOff};
    }
    values.meta = {
      "IV": values.volume,
      "ID": values.duration
    };

    console.log("values", values)

    // ADD
    if (!isUpdateAvailable) {
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/valve', values)
        .then(res => {
          setActuators(oldArray => [...oldArray, res.data.content]);
          setIsModalVisible(false);
          setRefresh(true)
          dispatch(getSelectedCoordinate({}));
          toast.success(t("successMsg.ACTUATOR_CREATED_SUCCESSFULLY"));
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
              setFormErr(error.response.data.message);
            }
          }
        )
        .finally(() => {
          dispatch(toggleLoader(false));
          setIsLoading(false);

        });
    } else {  //UPDATE
      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/valve/' + values.id, values)
        .then(res => {
          let data = [...actuators];
          data.forEach((actuator, index) => {
            if (actuator.id === values.id) {
              data[index] = res.data.content;
            }
          });
          setActuators(data);
          dispatch(getSelectedCoordinate({}));
          setIsModalVisible(false);
          setRefresh(true)
          toast.success(t("successMsg.ACTUATOR_UPDATED_SUCCESSFULLY"));
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/source')
      .then(res => {
        if (res.data.content.length > 0) {
          dispatch(getFarmSource(res.data.content[0]));
        } else {
          dispatch(getFarmSource({}));
        }
      })
      .finally(() => {
        setRefresh(false)
        dispatch(toggleLoader(false));
      });
  }, [refresh]);

  function farmUser() {
    console.log(values, errors)
    setIsLoading(true);
  }

  function locationPicker() {
    dispatch(changeToggle(TOGGLE.MAP));
    dispatch(getFindLocation(true));
    dispatch(getSelectedActuator(values));
    setIsModalVisible(false);

  }

  useEffect(() => {
    if (findDeviceLocation) {
      return
    }
    if (!findDeviceLocation && selectCoordinates.latLng) {
      setIsModalVisible(true);
      values.lat = selectCoordinates.latLng.lat();
      values.lng = selectCoordinates.latLng.lng();
      dispatch(changeToggle(TOGGLE.LIST));
    }
  }, [findDeviceLocation]);

  function onClose() {
    dispatch(getFindLocation(false));
    setIsModalVisible(false);
    dispatch(getSelectedCoordinate({}));
    setTable(false)
  }

  function closeMap() {
    dispatch(getFindLocation(false));
    setIsModalVisible(true);
    dispatch(getSelectedCoordinate({}));
    setTable(true)
    dispatch(changeToggle(TOGGLE.LIST));
  }

  function changeToggleClick(toggle) {
    setViewMode(toggle);
  }

  useEffect(() => {
    if (!actuators || actuators.length === 0) {
      dispatch(getFarmActuators([]));
      return
    }
    dispatch(getFarmActuators(actuators));
    let result = [];
    for (let i = 0; i < actuators.length; i++) {
      let actuatorIndex = _.findIndex(result, {kitId: actuators[i].kitId});
      if (actuatorIndex === -1) {
        actuators[i].actuatorIds = [actuators[i].actuatorNumber];
        result.push(actuators[i]);
      } else {
        result[actuatorIndex].actuatorIds.push(actuators[i].actuatorNumber);
      }

    }
    // let uniq = _.uniq(actuators, actuators => actuators.kitId);
    setUniqueActuator(result);
  }, [actuators]);


  return (
    // <div className={"half-list-container"}>
    <div>
      <div className={"farm-settings-blur"} hidden={!findDeviceLocation}>
        <div className={"farm-settings-blur-inner"}>
          <FeatherIcon className={"whit-text m-b-8"} icon={"map-pin"}/>
          <div className={"whit-text"}> Please select a location in the map</div>
          <button
            className="btn whit-text btn-sa-success w-140p"
            onClick={closeMap}
          >
            {" "}
            {t("button.CANCEL")}
          </button>
        </div>
      </div>
      {/*<Container>*/}
      {/*    <div className="single-card m-rl-m-8 p-a-16">*/}
      <div style={{display: "flow-root"}}>
        {hasPermission("WRITE") &&
          <div className="float-r">
                        <span className={'sa-table-icon-flex'}>
                            <button className="sa-table-btn-secondary sa-table-float-right" onClick={addActuator}>
                                <FeatherIcon
                                  icon={"plus"} className={"sa-table-icon-size"}/> <span
                              className={"sa-table-icon"}>{t("button.ADD")}</span>
                            </button>
                        </span>
          </div>
        }
        {hasPermission("VALVE_CARD") &&
          <div className="pull-left">
            <button id="toggle-list"
                    className={"actuator-tog-w-h btn first-tog " + (viewMode === VIEW_MODE.LIST ? 'tog-sa-primary hide-768' : 'tog-sa-secondary')}
                    onClick={() => changeToggleClick(VIEW_MODE.LIST)}>
              <FeatherIcon className={"toggle-icon-in-btn"} icon="grid"/> <span
              className={"hide-768"}>{t("title.LIST").toUpperCase()}</span>
            </button>
            <button id="toggle-map"
                    className={"actuator-tog-w-h btn second-tog " + (viewMode === VIEW_MODE.TABLE ? 'tog-sa-primary hide-768' : 'tog-sa-secondary')}
                    onClick={() => changeToggleClick(VIEW_MODE.TABLE)}>
              <FeatherIcon className={"toggle-icon-in-btn"} icon="map"/> <span
              className={"hide-768"}> {t("title.TABLE").toUpperCase()}</span>
            </button>
          </div>
        }
      </div>
      {viewMode === VIEW_MODE.TABLE &&
        <div className="sa-table-container">
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.KIT_ID")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.ACTUATOR_ID")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.CODE")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.TYPE")}</th>
              {hasPermission("WRITE") &&
                <th className="sa-table-item-align sa-table-head-sticky"></th>
              }
            </tr>
            </thead>
            <tbody>
            {actuators.length > 0 && actuators.map((actuator, index) =>
              <tr key={actuator.id}>
                <td className={"sa-table-data"}>{actuator.kitId}</td>
                <td className={"sa-table-data"}>{actuator.actuatorNumber}</td>
                <td className={"sa-table-data"}>{actuator.code}</td>
                <td className={"sa-table-data"}>{actuator.name}</td>
                <td className={"sa-table-data"}>{actuator.type}</td>
                {hasPermission("WRITE") &&
                  <td className={"sa-table-item-align"}>
                    <div className={'sa-table-action-flex'}>
                      <div className="sa-table-actions sa-action-m"><FeatherIcon onClick={() => {
                        debug(actuator);
                        setFormErr("");
                      }}
                        className={"table-action"}
                        icon={"activity"} /></div>
                      <div className="sa-table-actions sa-action-m"><FeatherIcon onClick={() => {
                        edit(actuator);
                        setFormErr("");
                      }}
                        className={"table-action"}
                        icon={"edit"} /></div>
                      <div id={'actuatorDelete'} className="sa-table-actions sa-action-m"><FeatherIcon
                        onClick={() => deleteValve(actuator)}
                        className={"text-red"} icon={"trash-2"}/></div>
                    </div>
                  </td>
                }

              </tr>)}

            </tbody>
          </table>
          {actuators.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_ACTUATORS,_PLEASE_ADD")}</div>
            </div>)
          }
        </div>
      }

      {viewMode === VIEW_MODE.LIST &&
        <div id={"kit-m"} className={window.innerWidth > 620 ? "row  mt-3" : "mo-plot-list"}>
          {uniqueActuator && uniqueActuator.length > 0 && uniqueActuator.map((actuator, index) => (
            <Kit index={index} key={index} kitId={actuator.kitId} actuatorIds={actuator.actuatorIds}/>
          ))}
          {actuators.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_ACTUATORS,_PLEASE_ADD")}</div>
            </div>
          )}
        </div>
      }


     {isDebug && <Debugger  debugData={debugData} setIsDebug={setIsDebug} />}
      
      

      
      <div className={"sa-popup-bg sa-modal-actuator " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">

        
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{!isUpdateAvailable ? t("title.ADD_ACTUATOR") : t("title.EDIT_ACTUATOR")}</span>
              <div className="sa-popup-close-icon" onClick={(onClose)}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.KIT_ID")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.kitId || ''} type="text"
                         className={`form-control ${errors.kitId && "warning-input"}`}
                         name="kitId"
                         placeholder={t("placeHolder.ENTER_KIT_ID")}
                         readOnly={values.assigned}
                         data-tip={values.assigned ? "Cannot edit; actuator already assigned to a sector." : ""}/>
                  <ReactTooltip place="top" className="toolTipTheme" effect="solid"/>
                  {errors.kitId && (
                    <p className="warning-input-msg ">{errors.kitId}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.CODE")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.code || ''} type="text"
                         className={`form-control ${errors.code && "warning-input"}`}
                         name="code"
                         placeholder={t("placeHolder.ENTER_CODE")}
                         readOnly={values.assigned}
                         data-tip={values.assigned ? "Cannot edit; actuator already assigned to a sector." : ""}/>
                  <ReactTooltip place="top" className="toolTipTheme" effect="solid"/>
                  {errors.code && (<p className="warning-input-msg">{errors.code}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16"
                     data-tip={values.assigned ? "Cannot edit; actuator already assigned to a sector." : ""}>
                  <label htmlFor="exampleFormControlSelect1">{t("title.ACTUATOR_ID")}</label>
                  <select onBlur={handleOnBlur} onChange={handleChange}
                          value={values.actuatorNumber}
                          className={`form-control ${errors.actuatorNumber && "warning-input"}`}
                          id="exampleFormControlSelect1" name="actuatorNumber" disabled={values.assigned}>
                    <option hidden value={""}>{t("placeHolder.SELECT_ACTUATOR")}</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                  {errors.actuatorNumber && (
                    <p className="warning-input-msg">{errors.actuatorNumber}</p>)}
                </div>
                <ReactTooltip place="top" className="toolTipTheme" effect="solid"/>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16"
                     data-tip={values.assigned ? "Cannot edit; actuator already assigned to a sector." : ""}>
                  <label htmlFor="exampleFormControlSelect1">{t("title.CONTROLLER_TYPE")}</label>
                  <select onBlur={handleOnBlur} onChange={handleChange}
                          value={values.type || ''}
                          className={`form-control ${errors.type && "warning-input"}`}
                          id="exampleFormControlSelect1" name="type" disabled={values.assigned}>
                    <option hidden value={""}>{t("placeHolder.SELECT_CONTROLLER_TYPES")}</option>
                    <option value={ACTUATOR_TYPE.DISPENSER}>{ACTUATOR_TYPE.DISPENSER}</option>
                    <option value={ACTUATOR_TYPE.BRANCH}>{ACTUATOR_TYPE.BRANCH}</option>
                    <option value={ACTUATOR_TYPE.MOTOR}>{ACTUATOR_TYPE.MOTOR}</option>
                    <option value={ACTUATOR_TYPE.TANK}>{ACTUATOR_TYPE.TANK}</option>
                    <option value={ACTUATOR_TYPE.MIST}>{ACTUATOR_TYPE.MIST}</option>
                  </select>
                  <ReactTooltip place="top" className="toolTipTheme" effect="solid"/>
                  {errors.type && (
                    <p className="warning-input-msg">{errors.type}</p>)}
                </div>
              </div>
              <div className="col-md-6" hidden={values.type !== ACTUATOR_TYPE.MIST}>
                <div className="form-group m-b-16">
                  <label htmlFor="email">Mist ON Duration (in Mins)</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.mistOn || ''} type="text"
                         className={`form-control ${errors.mistOn && "warning-input"}`}
                         name="mistOn"
                         placeholder={t("placeHolder.ENTER_ON_DURATION")}/>
                  {errors.mistOn && (
                    <p className="warning-input-msg ">{errors.mistOn}</p>)}
                </div>
              </div>
              <div className="col-md-6" hidden={values.type !== ACTUATOR_TYPE.MIST}>
                <div className="form-group m-b-16">
                  <label htmlFor="mistOff">Mist OFF Duration (in Mins)</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.mistOff || ''} type="text"
                         className={`form-control ${errors.mistOff && "warning-input"}`}
                         name="mistOff"
                         placeholder="Enter OFF duration"/>
                  {errors.mistOff && (<p className="warning-input-msg">{errors.mistOff}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.NAME")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.name || ''} type="text"
                         className={`form-control`}
                         name="name"
                         placeholder="Enter Name"/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.NEEDED_PRESSURE")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.neededPressure || ''} type="text"
                         className={`form-control`}
                         name="neededPressure"
                         placeholder={t("title.NEEDED_PRESSURE")}/>
                  {errors.neededPressure && (<p className="warning-input-msg">{errors.neededPressure}</p>)}

                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label>{t("title.TIMEOUT")}</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.timeOut || ''} type="number"
                         className={`form-control`}
                         name="timeOut"
                         placeholder={t("placeHolder.ENTER_TIMEOUT")}/>
                </div>
              </div>
            </div>

            <div className={"advance-m table-container-toggle mb-2 " + (isTable ? "" : "table-toggle-hide")}>
              <hr className="table-toggle-line"/>
              <div className="table-toggle-heading">{t("title.ADVANCE")}</div>
              <div onClick={() => {
                setTable(!isTable)
                setValue({isTable: !isTable})
              }} className="table-dropdown-icon"><i
                className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
            </div>
            <div className="sa-popup-content" hidden={!isTable}>
              <div style={{marginTop: 10}} className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email"> Irrigation Duration (in Minutes)</label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.duration || ''} type="text"
                         className={`form-control`}
                         name="duration"
                         placeholder={"Enter Irrigation Duration"}/>
                  {errors.duration && (
                    <p className="warning-input-msg ">{errors.duration}</p>)}
                </div>
              </div>
              <div style={{marginTop: 10}} className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email"> Irrigation Volume (in Milliliters) </label>
                  <input onBlur={handleOnBlur} onChange={handleChange}
                         value={values.volume || ''} type="text"
                         className={`form-control`}
                         name="volume"
                         placeholder={"Enter Irrigation Volume"}/>
                  {errors.volume && (
                    <p className="warning-input-msg ">{errors.volume}</p>)}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.COORDINATES")}</label>
                  <div className={"row"}>
                    <div className="col-md-6">
                      <input onBlur={handleOnBlur} onChange={handleChange}
                             value={values.lat || ''} type="text"
                             className={`form-control m-b-8 ${errors.lat && "warning-input"}`}
                             name="lat"
                             placeholder="Enter latitude."/>
                      {errors.lat && (<p className="warning-input-msg">{errors.lat}</p>)}
                    </div>
                    <div className="col-md-6">
                      <input onBlur={handleOnBlur} onChange={handleChange}
                             value={values.lng || ''} type="text"
                             className={`form-control ${errors.lng && "warning-input"}`}
                             name="lng"
                             placeholder="Enter longitude."/>
                      {errors.lng && (<p className="warning-input-msg">{errors.lng}</p>)}
                      <i className="cursor-location-btn"
                         onClick={locationPicker}>{t("title.PIN_LOCATION")}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={onClose}>{t("button.CANCEL")}
              </button>
              <button id={'actuatorAddOrUpdate'} className="sa-popup-secondary-btn-style">
                {!isUpdateAvailable ? t("button.ADD") : t("button.UPDATE")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
