import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import 'c3/c3.css';
import * as _ from "underscore";
import {toggleLoader} from "../../../shared/actions/setting";
import {DateRangePicker} from 'rsuite';
import C3Chart from 'react-c3js';
import 'rsuite/dist/styles/rsuite-default.css'
import * as dateFns from "date-fns";
import 'rsuite/lib/styles/index.less';
import {ExportToCsv} from 'export-to-csv';
import FeatherIcon from "feather-icons-react";
import Switch from "react-switch";


import {PropagateLoader} from "react-spinners";
import {css} from "@emotion/core";
import * as d3 from "d3";
import {useTranslation} from "react-i18next";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import filterHandler from "../../../shared/utils/FilterHandler";
import {Dropdown} from "react-bootstrap";
import {
  dateAndTimeFormat,
  dateFormat,
  getDisplayName,
  isEmpty,
  timeDurationForGraph as durationTime,
  timeDurationMinutes,
  toBrowserTimeZone
} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const PlotReport = (props) => {
  const {t, i18n} = useTranslation();
  const [selectedKit, setSelectedKit] = useState({});
  const [kitGraphData, setKitGraphData] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([0]);
  const [dataSet, setDataSet] = useState({});
  const [trendLineGraphData, setTrendLineGraphData] = useState([]);
  const [dateRange, setDataRange] = useState(null);
  const [loadGraph, setLoadGraph] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valuesX, setValuesX] = useState([]);
  const [valuesY, setValuesY] = useState([]);
  const [toggleIrrigation, setToggleIrrigation] = useState(false);
  const [toggleTrendLine, setToggleTrendLine] = useState(false);
  const [togglePredictionLine, setTogglePredictionLine] = useState(false);
  const [alertLevels, setAlertsLevels] = useState([]);
  const [thresholdLevel, setThresholdLevel] = useState(false);
  const [ML, setML] = useState(false);
  const [showGraph, setShowGraph] = useState();
  const [dropDownActive, setDropDownActive] = useState(false);
  const [inferenceData, setInferenceData] = useState({});
  const [Predicted_Data, setPredicted_Data] = useState([]);


  const {handleFilter, setFilter, filters} = filterHandler(
    applyFilter,
    []
  );


  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    width:fit-content;
  `;

  const dispatch = useDispatch();


  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  function toggleIrrigationSwitch() {
    setToggleIrrigation(!toggleIrrigation);
  }

  function toggleTrendlineSwitch() {
    setToggleTrendLine(!toggleTrendLine);
  }

  function togglePredictionlineSwitch() {
    setTogglePredictionLine(!togglePredictionLine);
  }

  function toggleThresholdLevelSwitch() {
    setThresholdLevel(!thresholdLevel);
  }

  function toggleMLSwitch() {
    setML(!ML);
  }

  function getThresholdLevel() {
    let levels = [];
    alertLevels.map((l, i) => {

      let obj = [{}] = [{value: l.high, text: 'Max Threshold Level' + l.level, position: 'middle'},
        {value: l.low, text: 'Min Threshold Level' + l.level, position: 'middle'}];
      levels.push(...obj);
    });
    return [...levels];
  }

  function applyFilter() {
    if (!isEmpty(filters)) {
      setDropDownActive(true);
    } else {
      setDropDownActive(false)
    }
  }

  useEffect(() => {

    if (!selectedPlot.id || !selectedKit.id) {
      return;
    }
    setLoadGraph(false);
    if (selectedIndexes.length === 1) {
      drawGraph([selectedIndexes[0]]);
    } else if (selectedIndexes.length === 2) {
      drawGraph([selectedIndexes[0], selectedIndexes[1]]);
    }

  }, [toggleIrrigation, toggleTrendLine, togglePredictionLine, thresholdLevel, selectedIndexes, Predicted_Data]);


  function irrigationDataHistory(dataHistory) {
    if (dataHistory.length === 0) {
      return;
    }

    dataHistory.forEach(element => {
      let elapsedTime = timeDurationMinutes(element.startTime, element.stopTime);
      let notableTime = elapsedTime / 1000 / 60;
      let startDateAndTime = new Date(element.startTime);
      let stopDateAndTime = new Date(element.stopTime);
      let deltaNegative = dateAndTimeFormat(startDateAndTime.getTime() - 1000);
      let value0 = dateAndTimeFormat(startDateAndTime.getTime());
      let value1 = dateAndTimeFormat(stopDateAndTime.getTime());
      let deltaPositive = dateAndTimeFormat(stopDateAndTime.getTime() + 1000);


      valuesX.push(deltaNegative, value0, value1, deltaPositive);
      valuesY.push(null, notableTime, notableTime, null);
    });
    setValuesX([...valuesX]);
    setValuesY([...valuesY]);

  }


  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });
  }


  useEffect(() => {
    if (!selectedPlot.id) {
      return;
    }
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` +
      getUserId() + `/plot/` + selectedPlot.id + '/irrigation/history' + '?from=' + fromDate + '&to=' + toDate + '&order=' + 'DESC')
      .then((response) => {
        setDataSet({});
        if (valuesX.length > 0 || valuesY.length > 0) {
          valuesX.splice(0, valuesX.length);
          valuesY.splice(0, valuesY.length);

        }
        let dataHistory = response.data.content;
        irrigationDataHistory(dataHistory);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {

        }
      })
  }, [dateRange, selectedPlot]);


  useEffect(() => {
    if (!selectedPlot.kitId) {
      return
    }
    setIsLoading(true);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
      .then(function (response) {
        response.data.content.properties = _.filter(response.data.content.properties, function (item) {
          return item.code !== "WI";
        });
        setSelectedKit(response.data.content);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        setIsLoading(false);
      })
      .finally(() => {
      });

  }, [selectedPlot]);

  useEffect(() => {
    if (!selectedPlot.kitId) {
      return;
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId + `/property/` + selectedIndexes[0] + `/alert-limit`)
      .then(function (response) {
        setAlertsLevels(response.data.content);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });

  }, [selectedPlot, selectedIndexes]);

  // useEffect(() => {
  //     if (!selectedPlot.kitId || !selectedKit.id) {
  //         return
  //     }
  //     let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
  //     let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
  //     setIsLoading(true);
  //     axios.get(process.env.REACT_APP_HOST + `/core/kit/` + selectedPlot.kitId + '/graph-kit-history' + '?from=' + fromDate + '&to=' + toDate)
  //         .then(function (response) {
  //             if (response.data.content.length > 0) {
  //                 if (kitGraphData.length > 0) {
  //                     kitGraphData.splice(0, kitGraphData.length);
  //                 }
  //                 response.data.content.forEach(element => {
  //                     kitGraphData.push(element);
  //                 });
  //                 setKitGraphData([...kitGraphData]);
  //                 if (selectedIndexes.length === 1) {
  //                     drawGraph([selectedIndexes[0]]);
  //                 } else if (selectedIndexes.length === 2) {
  //                     drawGraph([selectedIndexes[0], selectedIndexes[1]]);
  //                 }
  //             }
  //             setIsLoading(false);
  //         })
  //         .catch(function (error) {
  //             if (error.response && error.response.status === 422) {
  //             }
  //             setIsLoading(false);
  //         })
  //         .finally(() => {
  //             dispatch(toggleLoader(false));
  //         });
  // }, [selectedKit, dateRange]);

  useEffect(() => {
    if (!selectedPlot.kitId || !selectedKit.id) {
      return
    }

    selectedKit.properties.map((item, index) => {
      if (index === 0) {
        getHistoryByNumber(item.number, item.code)
      } else {
        getHistoryByNumber(item.number, item.code, true);
      }
    });
    // let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    // let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    // setIsLoading(true);
    // axios.get(process.env.REACT_APP_HOST + `/core/kit/` + selectedPlot.kitId + '/graph-kit-history' + '?from=' + fromDate + '&to=' + toDate)
    //     .then(function (response) {
    //         if (response.data.content.length > 0) {
    //             if (kitGraphData.length > 0) {
    //                 kitGraphData.splice(0, kitGraphData.length);
    //             }
    //             response.data.content.forEach(element => {
    //                 kitGraphData.push(element);
    //             });
    //             setKitGraphData([...kitGraphData]);
    //             if (selectedIndexes.length === 1) {
    //                 drawGraph([selectedIndexes[0]]);
    //             } else if (selectedIndexes.length === 2) {
    //                 drawGraph([selectedIndexes[0], selectedIndexes[1]]);
    //             }
    //         }
    //         setIsLoading(false);
    //     })
    //     .catch(function (error) {
    //         if (error.response && error.response.status === 422) {
    //         }
    //         setIsLoading(false);
    //     })
    //     .finally(() => {
    //         dispatch(toggleLoader(false));
    //     });
  }, [selectedKit, dateRange, filters]);

  function getHistoryByNumber(number, code, isSilence) {
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    if (!isSilence) {
      setIsLoading(true);
    }

    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + selectedPlot.kitId + '/graph-kit-history/' + number + '/summary?from=' + fromDate + '&to=' + toDate +
      (isEmpty(filters) ? '' : '&aggregation=' + (filters.aggregation ? filters.aggregation : "MAX") + '&granularity=' + (filters.granularity ? filters.granularity : "HOURS") + (filters.aggregation === "TIME" ? '&timeOfDay=9' : "")))
      .then(function (response) {
        if (!isEmpty(response.data.content)) {

          response.data.content.valueX = response.data.content.valueX.map(x => toBrowserTimeZone(x + ':00').substring(0, 16));
          if (code === "CN") {
            response.data.content.valueY = response.data.content.valueY.map(x => x * 1000);
          }
          let index = _.findIndex(kitGraphData, {propertyNumber: number});
          if (index === -1) {
            kitGraphData.push(response.data.content);
          } else {
            kitGraphData[index] = response.data.content;
          }
          setKitGraphData([...kitGraphData]);
          if (selectedIndexes.length === 1) {
            drawGraph([selectedIndexes[0]]);
          } else if (selectedIndexes.length === 2) {
            drawGraph([selectedIndexes[0], selectedIndexes[1]]);
          }
        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  // useEffect(() => {
  //     if (!selectedPlot.kitId || !selectedKit.id) {
  //         return
  //     }
  //     let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
  //     let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
  //     setIsLoading(true);
  //     axios.get(process.env.REACT_APP_HOST + `/core/` + `/kit/` + selectedPlot.kitId + '/trend-line' + '?from=' + fromDate + '&to=' + toDate)
  //         .then(function (response) {
  //             if (response.data.content.length > 0) {
  //                 if (trendlineGraphData.length > 0) {
  //                     trendlineGraphData.splice(0, trendlineGraphData.length);
  //                 }
  //                 response.data.content.forEach(element => {
  //                     trendlineGraphData.push(element);
  //                 });
  //                 setTrendlineGraphData([...trendlineGraphData]);
  //                 if (selectedIndexes.length === 1) {
  //                     // drawGraph([selectedIndexes[0]]);
  //                 } else if (selectedIndexes.length === 2) {
  //                     // drawGraph([selectedIndexes[0], selectedIndexes[1]]);
  //                 }
  //             }
  //             setIsLoading(false);
  //         })
  //         .catch(function (error) {
  //             if (error.response && error.response.status === 422) {
  //             }
  //             setIsLoading(false);
  //         })
  //         .finally(() => {
  //             dispatch(toggleLoader(false));
  //         });
  // }, [selectedKit, dateRange]);


  const style1 = {borderBottom: "solid 0.5px #6CC889"};
  const style2 = {borderBottom: "solid 0.5px #F67F0F"};

  function selectTab(number) {
    let array = [...selectedIndexes];
    const graphData = {};
    graphData.data = null;
    graphData.axis = null;
    setLoadGraph(false);
    if (selectedIndexes.length === 2) {
      if (selectedIndexes.includes(number)) {
        let index = selectedIndexes.findIndex(element => element === number);
        array.splice(index, 1);
      } else {
        array.splice(0, 2);
        array.push(number);
      }
    } else if (selectedIndexes.length === 1) {


      if (!selectedIndexes.includes(number)) {
        array.push(number);
      }
    } else {
      array.push(number);
    }
    setSelectedIndexes(array);

    addDataGraphDate(graphData).then(() => {
      drawGraph(array);
    });
  }


  function drawGraph(array) {
    const graphData = {};
    if (array.length === 1) {
      const value1 = _.findWhere(kitGraphData, {propertyNumber: array[0]});
      const value2 = _.findWhere(trendLineGraphData, {propertyNumber: array[0]});
      let displayName1 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[0]}), selectedKit.metaData);
      let noValue1 = false;
      let noValue2 = false;
      let noValue3 = false;
      let noValue0 = false;
      let value1Max = value1 ? Math.max(...value1.valueY) : 1
      let yValues = [...valuesY];
      let tootltipValuesY = [...valuesY].reverse();
      yValues.map((value, key) => {

        if (value) {
          yValues.splice(key, 1, value1Max);
        }
      });

      if (!value1 || !value1.valueX || !value1.valueY) {

        noValue1 = true;
      }
      if (!value2 || !value2.valueX || !value2.valueT || !toggleTrendLine) {

        noValue2 = true;
      }
      if (!value2 || !value2.valueXP || !value2.valueTP || !togglePredictionLine) {

        noValue3 = true;
      }
      if (!valuesX || !yValues || !toggleIrrigation) {
        noValue0 = true;
      }

      setShowGraph(value1 ? value1.valueX.length : 0);
      const tooltip = {
        format: {
          value: function (value, ratio, id, index) {
            if (id === 'Irrigation(Duration)') {
              let duration = tootltipValuesY[index];
              return durationTime(duration * 60000)
            } else {
              return value.toFixed(4);
            }

          }
        },
      };

      const data = {
        xs: {
          [displayName1]: 'x',
          'Irrigation(Duration)': 'x0',
          'Trendline': 'x1',
          'Predictionline': 'x2'

        },
        xFormat: '%Y-%m-%d %H:%M',
        columns: [
          ['x'].concat(noValue1 ? [] : value1.valueX),
          ['x0'].concat(noValue0 ? [] : valuesX),
          ['x1'].concat(noValue2 ? [] : value2.valueX).concat(noValue2 ? [] : value2.valueXP[0]),
          ['x2'].concat(noValue3 || toggleTrendLine ? [] : value1.valueX[value1.valueX.length - 1]).concat(noValue3 ? [] : value2.valueXP),
          [displayName1].concat(noValue1 ? [] : value1.valueY),
          ['Irrigation(Duration)'].concat(noValue0 ? [] : yValues),
          ['Trendline'].concat(noValue2 ? [] : value2.valueT).concat(noValue2 ? [] : value2.valueTP[0]),
          ['Predictionline'].concat(noValue3 || toggleTrendLine ? [] : value1.valueY[value1.valueY.length - 1]).concat(noValue3 ? [] : value2.valueTP)


        ],
        types: {
          'Irrigation(Duration)': 'area',
          // 'Trendline': 'area',
        },
        color: function (color, d) {
          if(d.id === 'Deep Root'){
          const dataPointDate = toBrowserTimeZone(d.x).substring(0, 16); 
          if (Predicted_Data) {
          for (let i = 0; i < Predicted_Data.length; i++) {
              const predictedDate = toBrowserTimeZone(Predicted_Data[i].Date).substring(0, 16);      
              if (predictedDate === dataPointDate && Predicted_Data[i].Predicted_Label === "Irrigated") {
                  return '#14e711';
              }
          }
        }
      }
          return color;
      },

      };

      const zoom = {
        rescale: true

      };

      const grid = {
        y: {
          lines: thresholdLevel && selectedIndexes.length === 1 ? getThresholdLevel : []
        }
      };

      const axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true,
            rotate: window.innerWidth > 576 ? 0 : 9,
            culling: {
              max: window.innerWidth > 480 ? 10 : 5,
            },
            height: 100,


          },
          label: {
            text: 'Date Time',
            position: 'outer-center',
          },

        },
        y: {
          label: {
            text: displayName1,
            position: 'outer-middle',

          }
        },
      };
      graphData['data'] = data;
      graphData['axis'] = axis;
      graphData['tooltip'] = tooltip;
      graphData['zoom'] = zoom;
      graphData['grid'] = grid;

    } else if (array.length === 2) {

      let yValues = [...valuesY];
      let tootltipValuesY = [...valuesY].reverse();

      const value1 = _.findWhere(kitGraphData, {propertyNumber: array[0]});
      const value2 = _.findWhere(kitGraphData, {propertyNumber: array[1]});

      let noValue1 = false;
      let noValue2 = false;
      let noValue0 = false;

      let displayName1 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[0]}), selectedKit.metaData);
      let displayName2 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[1]}), selectedKit.metaData);
      if (displayName1 === displayName2) {
        displayName2 = displayName2 + "1";
      }
      const xs = {};
      xs[displayName1] = 'x1';
      xs[displayName2] = 'x2';
      const axes = {};
      axes[displayName1] = 'y';
      axes[displayName2] = 'y2';

      let value1Max = value1 ? Math.max(...value1.valueY) : 1;
      let value2Max = value2 ? Math.max(...value2.valueY) : 1;
      yValues.map((value, key) => {

        if (value) {
          if (value1Max > value2Max) {
            yValues.splice(key, 1, value1Max);
          } else {
            yValues.splice(key, 1, value2Max);
          }
        }
      });

      if (!valuesX || !yValues || !toggleIrrigation) {
        noValue0 = true;
      }
      if (!value1 || !value1.valueX || !value1.valueY) {
        noValue1 = true;
      }
      if (!value2 || !value2.valueX || !value2.valueY) {
        noValue2 = true;
      }


      const tooltip = {
        format: {
          value: function (value, ratio, id, index) {
            if (id === 'Irrigation(Duration)') {
              let duration = tootltipValuesY[index];
              return durationTime(duration * 60000)
            } else {
              return value;
            }
            
          }
        },
      };

      const zoom = {
        rescale: true

      };

      const data = {
        xs: {
          [displayName1]: 'x1',
          [displayName2]: 'x2',
          'Irrigation(Duration)': 'x0'

        },
        xFormat: '%Y-%m-%d %H:%M',
        columns: [
          ['x0'].concat(noValue0 ? [] : valuesX),
          ['x1'].concat(noValue1 ? [] : value1.valueX),
          ['x2'].concat(noValue2 ? [] : value2.valueX),
          ['Irrigation(Duration)'].concat(noValue0 ? [] : yValues),
          [displayName1].concat(noValue1 ? [] : value1.valueY),
          [displayName2].concat(noValue2 ? [] : value2.valueY),
        ],
        types: {
          'Irrigation(Duration)': 'area',
        },
        color: function (color, d) {
          if(d.id === 'Deep Root'){
          const dataPointDate = toBrowserTimeZone(d.x).substring(0, 16); 
          if (Predicted_Data) {
          for (let i = 0; i < Predicted_Data.length; i++) {
              const predictedDate = toBrowserTimeZone(Predicted_Data[i].Date).substring(0, 16);      
              if (predictedDate === dataPointDate && Predicted_Data[i].Predicted_Label === 'Irrigated') {
                  return '#14e711';
              }
          }
        }
      }
          return color; 
      },
      };

      let axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true
          },
          label: {
            text: 'Date Time',
            position: 'outer-center',
          },
          fit: true,
          culling: {
            max: 10,
          }
        },
        y: {
          label: {
            text: value2Max > value1Max ? displayName2 : displayName1,
            position: 'outer-middle'
          },
        },
        y2: {
          show: true,
          label: {
            text: value2Max > value1Max ? displayName1 : displayName2,
            position: 'outer-middle'
          },
        }
      };
      graphData['data'] = data;
      graphData['axis'] = axis;
      graphData['tooltip'] = tooltip;
      graphData['zoom'] = zoom;

    } else {
      let data = {};
      let axis = {};

      graphData['data'] = data;
      graphData['axis'] = axis;
    }
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
      let elem = document.getElementById("scroll");
      elem && elem.scrollIntoView();
    });
  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }

  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-42px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");

  }

  function exportData() {
    const propertyName = _.findWhere(selectedKit.properties, {number: selectedIndexes[0]}).displayName;
    const graphData = _.findWhere(kitGraphData, {propertyNumber: selectedIndexes[0]});

    let data = [];
    for (let i = 0; i < graphData.valueX.length; i++) {
      data.push({
        "time": graphData.valueX[i],
        "value": graphData.valueY[i]
      })
    }

    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: ['Date', propertyName],
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }
  const getIROIndex = () => {
    let IROIndex = 0;
    selectedKit.properties.map((property, i) => {
      if (property.code === "IRO") {
        IROIndex = property.number;
      }
    });
    return IROIndex;
  }

  const valueTrim = (value1) => {
    let value = value1.slice(0, 4);
    return value;
  }
  const getDeepRootValue = (data) => {
    let valueArray = [];
    data.map((item, index) => {
      valueArray.push({'Date':item.time, 'Deep Root': valueTrim(item.value)});
    });
    setInferenceData({data: valueArray, "problemtype":"eventclassification", "projectname":"Moisture_v1", "version_name":"version1"})
  }

  useEffect(() => {
    if (!selectedPlot || !selectedKit.properties) {
      return
    }
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    var sensorIndex = getIROIndex();
    const device = selectedPlot.kitId;
    axios.get(process.env.REACT_APP_HOST +`/core/device/` + device +"/sensor/" +sensorIndex+"?from=" +fromDate +"&to=" +toDate +`&order=DESC`)
    .then((response) => {
      getDeepRootValue(response.data.content);
    })
    .catch((error) => {
    })
    .finally(() => {
    })
  }, [ML, dateRange]);

  useEffect(() => {
    if (!ML){
      setPredicted_Data([]);
      return
    }
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_HOST +`/automl/inference`, inferenceData)
    .then((response) => {
      setPredicted_Data(Predicted_Data_filter(response.data.labelled_data));
    })
    .catch((error) => {
    })
    .finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [inferenceData]);

  const Predicted_Data_filter = (data) => {
    let Predicted_Data_filter = [];
    data.map((item, index) => {
      if (item.Predicted_Label === "Irrigated") {
        Predicted_Data_filter.push(item);
      }
    });
    return Predicted_Data_filter;
  }

  return (
    <div className="">
      <Container>
        <div hidden={!isLoading} className="container m-t-200">
          <div className="sweet-loading">
            <PropagateLoader
              css={override}
              size={20}
              color={"#11bc4d"}
              loading={true}
            />
          </div>
        </div>
        {!selectedPlot.kitId &&
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_CONFIGURED")}</div>
          </div>}
        {selectedPlot.kitId &&
          <div hidden={isLoading} className="single-card m-rl-m-8 plot-report-container">
            <div className={""}>
              {selectedKit.id && selectedKit.properties.map((property) => (
                <div
                  style={(selectedIndexes.length >= 1 && selectedIndexes[0] === property.number) && style1 ||
                    (selectedIndexes.length >= 1 && selectedIndexes[1] === property.number) && style2
                    || null}
                  key={property.number} className={"r-w-50 plot-report-tabs"}
                  onClick={() => selectTab(property.number)} title={property.time}>
                  <div
                    className={(selectedIndexes.length >= 1 && selectedIndexes[0] === property.number || selectedIndexes.length >= 1 && selectedIndexes[1] === property.number) ? "limit-plot-report-N plot-title-size plot-report-tabs-title-select" : "plot-report-tabs-value-light limit-plot-report-N plot-title-size"}>{getDisplayName(property, selectedKit.metaData)}</div>
                  <div className="plot-report-tabs-value-light limit-plot-report-V">{property.displayValue}</div>
                </div>
              ))
              }
            </div>
            <div>
              <div hidden={selectedIndexes.length === 0 || !selectedPlot.kitId}
                   className={"plot-report-filter-box-plot"}>
                <div className={"plot-report-filter"}>
                  <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                   onChange={(e) => onCheck(e)}
                                   oneTap={false} onOk={(e) => onCheck(e)}
                                   showWeekNumbers={true} appearance={"default"} placeholder={"Today"}
                                   ranges={[{
                                     label: 'Today',
                                     value: [new Date(), new Date()]
                                   }, {
                                     label: 'Yesterday',
                                     value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                   }, {
                                     label: 'Last 7 days',
                                     value: [dateFns.subDays(new Date(), 6), new Date()]
                                   }, {
                                     label: 'Last 30 days',
                                     value: [dateFns.subDays(new Date(), 30), new Date()]
                                   }]}
                  />


                </div>

                <span className={'sa-filter-row'}>
                  <Dropdown className="sa-table-float-left participant-filter">
                      {/*<Dropdown.Toggle className="sa-filter sa-table-h-float-l" id="dropdown-basic">*/}
                    {/*    <FeatherIcon className={"sa-filter-icon-size"} icon="filter"/>*/}
                    {/*  {t("title.FILTER")}*/}
                    {/*</Dropdown.Toggle>*/}

                    <Dropdown.Toggle
                      className={dropDownActive ? "sa-filter sa-table-h-float-l active-border" : "sa-filter sa-table-h-float-l"}
                      id="dropdown-basic">
                                    <FeatherIcon
                                      className={dropDownActive ? "sa-filter-icon-size active-icon-color" : "sa-filter-icon-size"}
                                      icon="filter"/>
                                        <span
                                          className={dropDownActive ? "active-text-color" : ""}>{t("title.FILTER")}</span>
                                    </Dropdown.Toggle>
                      <Dropdown.Menu className={"single-card-content-filter"}>
                        <select className="sa-filter" onChange={handleFilter} name={"aggregation"}>
                          <option value={""}>{filters && filters.aggregation ? "None" : "Aggression"}</option>
                          <option value={"MAX"}>MAX</option>
                          <option value={"MIN"}>MIN</option>
                          <option value={"TIME"}>TIME</option>
                        </select>
                        <select className="sa-filter" onChange={handleFilter} name={"granularity"}>
                          <option value={""}>{filters && filters.granularity ? "None" : "Granularity"}</option>
                          <option value={"HOURS"}>HOUR</option>
                          <option value={"DAY"}>DAY</option>
                        </select>
                      </Dropdown.Menu>
                  </Dropdown>
              </span>

                <div hidden={!kitGraphData.length > 0 || showGraph === 0} className="irrigation-switch">

                  Irrigation{"   "}

                  <Switch
                    checked={toggleIrrigation}
                    onChange={toggleIrrigationSwitch}
                    onColor="#94ceb8"
                    onHandleColor="#22C687"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={21}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>

                {/*<div hidden={selectedIndexes.length !== 1|| !kitGraphData.length>0 || showGraph === 0} className="trendline-switch">*/}

                {/*    Trend line{"   "}*/}
                {/*    <Switch*/}
                {/*        checked={toggleTrendline}*/}
                {/*        onChange={toggleTrendlineSwitch}*/}
                {/*        onColor="#94ceb8"*/}
                {/*        onHandleColor="#22C687"*/}
                {/*        handleDiameter={12}*/}
                {/*        uncheckedIcon={false}*/}
                {/*        checkedIcon={false}*/}
                {/*        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"*/}
                {/*        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"*/}
                {/*        height={10}*/}
                {/*        width={21}*/}
                {/*        className="react-switch"*/}
                {/*        id="material-switch"*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div hidden={selectedIndexes.length !== 1 || !kitGraphData.length>0 || showGraph === 0} className="trendline-switch">*/}

                {/*    Prediction line{"   "}*/}
                {/*    <Switch*/}
                {/*        checked={togglePredictionline}*/}
                {/*        onChange={togglePredictionlineSwitch}*/}
                {/*        onColor="#94ceb8"*/}
                {/*        onHandleColor="#22C687"*/}
                {/*        handleDiameter={12}*/}
                {/*        uncheckedIcon={false}*/}
                {/*        checkedIcon={false}*/}
                {/*        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"*/}
                {/*        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"*/}
                {/*        height={10}*/}
                {/*        width={21}*/}
                {/*        className="react-switch"*/}
                {/*        id="material-switch"*/}
                {/*    />*/}
                {/*</div>*/}

                <div hidden={selectedIndexes.length !== 1 || !kitGraphData.length > 0 || showGraph === 0}
                     className="trendline-switch">

                  Threshold{"   "}
                  <Switch
                    checked={thresholdLevel}
                    onChange={toggleThresholdLevelSwitch}
                    onColor="#94ceb8"
                    onHandleColor="#22C687"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={21}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>


                <div hidden={!kitGraphData.length > 0 || showGraph === 0 || (selectedIndexes[0] != 2 && selectedIndexes[1] != 2)}
                     className="trendline-switch">

                  ML{"   "}
                  <Switch
                    checked={ML}
                    onChange={toggleMLSwitch}
                    onColor="#94ceb8"
                    onHandleColor="#22C687"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={21}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>

                <div className={"plot-report-filter-right"} hidden={isEmpty(selectedKit)}>
                  <div hidden={selectedIndexes.length !== 1 || showGraph === 0}>
                    <button className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right"
                            style={{marginRight: 6}}
                            onClick={() => exportData()}>
                      <FeatherIcon
                        icon={"upload"} className={"sa-table-icon-size"}/> <span
                      className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
                    </button>
                  </div>
                </div>

              </div>
              {showGraph === 0 && (
                <div className={"empty-results"}>
                  <FeatherIcon icon="info"/>
                  <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA_FOR_DATE_RANGE")}</div>
                </div>)
              }
              <div id={"scroll"} style={{marginLeft: "-15px"}}>
                {showGraph > 0 && selectedIndexes.length >= 1 && loadGraph && dataSet.data && (
                  <C3Chart area={{zerobased: false}} tooltip={dataSet.tooltip} padding={{left: 51}} data={dataSet.data}
                           zoom={dataSet.zoom} subchart={{show: true}} axis={dataSet.axis} grid={dataSet.grid}
                           onrendered={styleGraph}/>)}
              </div>
              <div hidden={kitGraphData.length > 0 || !selectedPlot.kitId} className={"empty-results"}>
                <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA")}</div>
              </div>
            </div>
          </div>
        }
      </Container>
    </div>
  );
};


export default PlotReport
