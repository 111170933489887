import React, { useEffect, useState } from "react";
import C3Chart from "react-c3js";
import * as d3 from "d3";
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";

function Graph(props) {
  const [dataSet, setDataSet] = useState({});
  const [loadGraph, setLoadGraph] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    padding-bottom: 150px;
    padding-top: 150px;
    border-color: red;
    width: fit-content;
  `;

  function styleGraph() {
    if (window.innerWidth < 769) {
      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-13px");
    }
    d3.select(".c3-axis-y-label").attr("dy", "-25px");
    d3.selectAll(".c3-legend-item").attr("x", "400");
  }

  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });

  }

  function drawGraph(valuesX, valuesY) {


    const graphData = {};
    if (valuesX || valuesY) {
      let noValue1 = false;
      if (!valuesX || !valuesY) {
        noValue1 = true;
      }
      const data = {
        x: 'x',
        xFormat: '%Y-%m-%d %H:%M:%S',
        columns: [
          ['x'].concat(noValue1 ? [] : valuesX),
          [props.displayName ? props.displayName : "value"].concat(noValue1 ? [] : valuesY)
        ]
      };

      const axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true,
            rotate: window.innerWidth > 576 ? 0 : 9,
            culling: {
              max: window.innerWidth > 480 ? 10 : 5,
            },
            height: 100,

          },
          label: {
            text: 'Date Time',
            position: 'outer-center',
          }
        },
        y: {
          label: {
            text: props.displayName ? props.displayName : 'Value',
            position: 'outer-middle'
          }
        },
      };

      const zoom = {
        enabled: true // Enable zooming
      };

      graphData['data'] = data;
      graphData['zoom'] = zoom;
      graphData['axis'] = axis;
    }
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
      let elem = document.getElementById("scroll");
      if (elem) {
        elem.scrollIntoView();
      }
    });
  }
  useEffect(() => {
    if (props.Xvalues.length != 0 && props.Yvalues.length != 0) {
      drawGraph(props.Xvalues, props.Yvalues);
    }
  }, [props.Xvalues, props.Yvalues]);

  return (
    <didv>
      <div hidden={loadGraph}>
        <div className="sweet-loading">
          <BeatLoader
            css={override}
            size={18}
            color={"#11bc4d"}
            loading={true}
          />
        </div>
      </div>
      {loadGraph && dataSet.data && (
        <C3Chart
          data={dataSet.data}
          subchart={{ show: props.subchart ? props.subchart : false }}
          axis={dataSet.axis}
          onrendered={styleGraph}
          zoom={{enabled: true}}
          size={{ height: props.height ? props.height : 300 }}
        />
      )}
    </didv>
  );
}

export default Graph;