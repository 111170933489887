import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {hasPermission,} from "../../utils/Authentication";
import {formatDisplayEnumValue, InformativeMSG, isEmail, toCapsFirst} from "../../utils/Utils";
import Container from "react-bootstrap/Container";
import FormHandler from "../../shared/utils/FormHandler";
import {validateUserSubmit, validateUserUpdate} from "../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FarmAlertUser from "./farm-alert-user";
import ToggleLayout from "../toggle-layout";
import users from "../../images/icons/users-green.svg"
import PlotUser from "./plot/plot-user";
import {ExportToCsv} from "export-to-csv";
import {DateRangePicker} from 'rsuite';
import * as dateFns from "date-fns";
import FilterHandler from "../../shared/utils/CombinedFilterHandler";
import {useTranslation} from "react-i18next";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";


const FarmUser = () => {
  const {farmId} = useParams();
  const [userList, setUserList] = useState([]);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [formErr, setFormErr] = useState(null);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userLogList, setUserLogList] = useState([]);
  const [alertLog, setAlertLog] = useState([]);
  const [alertLogVisible, setAlertLogVisible] = useState(false);
  const [logUser, setLogUser] = useState(null);
  const [userLogVisible, setUserLogVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [resendUser, setResendUser] = useState(null);
  const [selectedDropdownPlotId, setSelectedDropdownPlotId] = useState(null);
  const [dateRange, setDataRange] = useState(null);
  const [isTable, setTable] = useState(false);
  const [showText, setShowText] = useState(false);
  const [userListUnFiltered, setUserListUnFiltered] = useState([]);
  const {t, i18n} = useTranslation();
  const statusList = ["ACTIVE", "INACTIVE"];
  const [userRoleList, setUserRoleList] = useState([]);
  const dispatch = useDispatch();
  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  const [suggestion, setSuggestion] = useState([]);


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
  } = FormHandler(farmUser, !isUpdateAvailable ? validateUserSubmit : validateUserUpdate);

  function farmUser() {
    setIsLoading(true);
  }

  function exportData() {
    if (userLogList.length === 0) {
      return;
    }
    const data = [];
    userLogList.forEach(user => {
      data.push({
        "Action": user.action,
        "Action At": user.actionAt
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'UserLog',
      useBom: true,
      noDownload: false,
      headers: ["Action", "Action At"],
      filename: "UserLog",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }


  function exportAlertData() {
    const data = [];
    alertLog.forEach(alert => {
      data.push({
        "Alert Medium": alert.alertMedium,
        "Alert Type": alert.alertType,
        "Message": alert.message,
        "Date": alert.creationDate
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'AlertLog',
      useBom: true,
      noDownload: false,
      headers: ["Alert Medium", "Alert Type", "Message", "Date"],
      filename: "AlertLog",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/ums/super/user-role')
      .then(res => {
        setUserRoleList(res.data.content)
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, []);

  // to get the farm user list
  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user')
      .then(res => {
        setUserListUnFiltered(res.data.content);
        setUserList(res.data.content);
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    // to add the farm user
    if (!isUpdateAvailable) {
      values.language = values.language ? values.language : "ENGLISH"
      if (isTable) {
        setValue({isTable: isTable})
      }
      dispatch(toggleLoader(true));
      values.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      if (!values.isTable) {
        delete values.username;
        delete values.password;
        delete values.confirmPassword;
      }
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user', delete values.isTable && values)
        .then(res => {
          setUserList(oldArray => [...oldArray, res.data.content]);
          toast.success(t("successMsg.USER_CREATED_SUCCESSFULLY"));
          resetFormData()
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message)
          } else {
            toast.error(t("errorMsg.EMAIL_HAS_NOT_BEEN_SENT"))
          }
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
        });
      // to update the farm user
    } else {
      dispatch(toggleLoader(true));
      delete values.userRoleId;
      delete values.username;
      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user/' + values.id, values)
        .then(res => {
          let newArr = [...userList];
          newArr[values.index] = res.data.content;
          setUserList(newArr);
          toast.success(t("successMsg.USER_UPDATED_SUCCESSFULLY"));
          resetFormData()
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
        });
    }
  }, [isLoading]);

  function handleDeleteFarmUser(id) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FARM_USER?"),

      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_FARM_USER.")
    }));
    resendUser && setResendUser(null);
    setDeleteId(id);
  }

  function resendVerification(user) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      type: 'request',
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_RESEND_REGISTRATION_MAIL?")
      ,
      confirmationDescription: t("confirmation_msg.THE_OK_ACTION_WILL_SEND_REGISTRATION_MAIL_TO_USER?")
    }));
    setResendUser(user);
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    if (!resendUser) {
      axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user/' + deleteId)
        .then(res => {
          setUserList(userList.filter(item => item.id !== deleteId));
          toast.success(t("successMsg.USER_DELETED_SUCCESSFULLY"));
          dispatch(toggleLoader(false));
        }).finally(() => {
        setDeleteId(-1);
        dispatch(toggleLoader(false));
      })
    } else {
      axios.post(process.env.REACT_APP_HOST + '/resend-key', {
        'email': resendUser.email,
        // mobile: resendUser.mobile,
        'host': window.location.protocol + '//' + window.location.host + '/sign-up',
      }).then(() => {
        toast.success(t("successMsg.EMAIL_HAS_BEEN_SENT_SUCCESSFULLY"));
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message)
        } else {
          toast.error(t("errorMsg.EMAIL_HAS_NOT_BEEN_SENT"))
        }
      })
        .finally(() => {
          setResendUser(null);
          dispatch(toggleLoader(false));
        })
    }
  }, [confirmationDialog]);


  function resetFormData() {
    setIsModalVisible(false);
    setIsUpdateAvailable(false);
    setFormErr("");
    setSelectedUser(null);
    Object.keys(values).forEach(key => delete values[key]);
    Object.keys(errors).forEach(key => delete errors[key]);
    setSuggestion([]);
    setTable(false)
  }


  function onChangeEmail(event) {
    handleChange(event);
    if (selectedUser && selectedUser.email !== event.target.value || selectedUser && !event.target.value) {
      setSelectedUser(null);
      initForm({});
    }
    if (!event.target.value) {
      return
    }
    let email = isEmail(event.target.value) ? event.target.value.trim() : event.target.value;
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + 1000 + '/search' + '?email=' + email)
      .then(res => {
        setSuggestion(res.data.content);
      }).catch((error) => {

    });
  }


  function onSelectEmail(user) {
    user.language = user.language ? user.language : "ENGLISH"
    setSelectedUser(user);
    user.username = null;
    setSuggestion([]);
    initForm(user)
  }

  useEffect(() => {
    if (!logUser || !userLogVisible) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/ums/user/' + getUserId() + '/system-user/' + logUser.id + '/history' + '?size=' + 20 + '&page=' + (userLogList.length === 0 ? 1 : page) + '&order=' + "DESC")
      .then(res => {
        if (userLogList.length > 0) {
          res.data.content.length > 0 && res.data.content.forEach(ele => userLogList.push(ele)) && setUserLogList(userLogList);
        } else {
          setUserLogList(res.data.content);
        }
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [page, logUser]);

  useEffect(() => {
    if (!logUser) {
      return
    }
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/alert-log/' + logUser.id + "?from=" + fromDate + "&to=" + toDate)
      .then(res => {
        setAlertLog(res.data.content)
      }).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, [logUser, dateRange])

  function bottomReached() {
    if (!logUser) {
      return
    }
    const offset = document.getElementById('main3').offsetHeight;
    const scrolledHeight = document.getElementById('main3').scrollTop;
    (offset + scrolledHeight + 1) >= document.getElementById('main3').scrollHeight && setPage(page + 1);
  }


  function formClick(event) {
    let names = ['suggestion', 'email'];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestion.length > 0 && setSuggestion([]);
      return;
    }
  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  const fieldsToFilter = ['lastName', 'username', 'email', 'userRoleId'];

  const {
    handleFilter,
    handleSearch,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, userListUnFiltered, fieldsToFilter);

  function setFilteredList() {
    setUserList(filteredList);
  }

  function convertRoleIdToUserRole(roleId) {
    const userRole = userRoleList.find(role => role.id === roleId);
    if (userRole) {
      return userRole.name;
    }
    return ""; // Return a default value if the role ID is not found.
  }

  return (

    <div>
      <Container className={'pb-2'}>
        <ToggleLayout dropDown={true} title={'Farm Users'} image={users} visibleToggleIndex={visibleToggleIndex}
                      toggleIndex={1}
                      onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                      onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)}>
          {!selectedDropdownPlotId || selectedDropdownPlotId === 'FARM' ? <div>
            <div hidden={!logUser} className={"sc-back sa-cursor"} onClick={() => {
              setLogUser(null);
              setUserLogList([]);
              setAlertLog([]);
              setUserLogVisible(false);
              setAlertLogVisible(false)
            }}><FeatherIcon className={"sc-back-icon"} icon={"arrow-left"}/>{logUser ? logUser.lastName : ""}</div>
            <span>
          <span hidden={alertLogVisible || userLogVisible}
                className="sa-table-btn-mute sa-search-align sa-table-float-left">
                <input
                  className={'sa-table-search sa-table-search-point font-14'}
                  onChange={handleSearch}
                  autoComplete={"off"}
                  type="text"
                  placeholder={t("placeHolder.SEARCH")}
                  name="search"
                />
                <FeatherIcon className={"sa-search-icon"} icon={"search"} width={'16px'}/>
              </span>

              <select hidden={alertLogVisible || userLogVisible}
                      className="sa-filter user-filter"
                      onChange={handleFilter}
                      name="status"
              >
                <option value={""}>{filters && filters.status ? t("title.ALL") : t("title.STATUS")}</option>
                {statusList.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              <div hidden={!alertLogVisible} className="m-b-16">
              <div className={"plot-report-filter"}>
                  <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                   onChange={(e) => onCheck(e)}
                                   oneTap={false} onOk={(e) => onCheck(e)}
                                   showWeekNumbers={true} appearance={"default"} placeholder={"Today"}
                                   ranges={[{
                                     label: 'Today',
                                     value: [new Date(), new Date()]
                                   }, {
                                     label: 'Yesterday',
                                     value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                   }, {
                                     label: 'Last 7 days',
                                     value: [dateFns.subDays(new Date(), 6), new Date()]
                                   }, {
                                     label: 'Last 30 days',
                                     value: [dateFns.subDays(new Date(), 30), new Date()]
                                   }]}
                  />
                </div>
                </div>
              <div className="pull-right">
              <button hidden={!alertLogVisible} onClick={exportAlertData}
                      className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                  <FeatherIcon
                    icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
                </button>
              </div>
              <div className="pull-right">
              <button hidden={!userLogVisible} onClick={exportData}
                      className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                  <FeatherIcon
                    icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
                </button>
              </div>
              {/* <select
                className="sa-filter"
                onChange={handleFilter}
                name={"status"}
              >
                <option value={""}>{filters && filters.status ? t("title.ALL") : t("title.STATUS")}</option>
                  <option value="ACTIVE">{t("title.Active")}</option>
                  <option value="INACTVE">{t("title.Inactive")}</option>
              </select> */}
                            </span>
            {hasPermission("WRITE") &&
              <div hidden={logUser} className="pull-right">
                <button onClick={() => setIsModalVisible(true)} className="sa-table-btn-secondary sa-table-float-right">
                  <FeatherIcon
                    icon={"plus"} className={"sa-table-icon-size"}/> <span className={"sa-table-icon"}>ADD</span>
                </button>
              </div>
            }
            <div id="main3" onScroll={bottomReached} className="sa-table-container"
                 style={{height: 'calc(100vh - 500px)'}}>
              <table hidden={logUser} className="table table-borderless sa-table-width">
                <thead>
                <tr>
                  <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
                  <th className={"sa-table-head-sticky"}>{t("title.USERNAME")}</th>
                  <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                  <th className={"sa-table-head-sticky"}>{t("title.ROLE")}</th>
                  <th className={"sa-table-head-sticky"}>{t("title.STATUS")}</th>
                  {hasPermission("WRITE") &&
                    <th className="sa-table-item-align sa-table-head-sticky"></th>
                  }
                </tr>
                </thead>
                <tbody>
                {userList && userList.map((user, index) => (
                  <tr key={index}>
                    <td className={'sa-table-data'}>{toCapsFirst(user.lastName)}</td>
                    <td className={'sa-table-data'}>{user.username}</td>
                    <td className={'sa-table-data'}>{user.email}</td>
                    <td className={'sa-table-data'}>{convertRoleIdToUserRole(user.userRoleId)}</td>
                    <td className={'sa-table-data'}>
                      {user.status === "INACTIVE" ? <div className="table-status-inactive">{user.status}</div> :
                        <div className="table-status-active">{user.status}</div>}
                    </td>
                    {hasPermission("WRITE") &&
                      <td className="sa-table-item-align">
                        <div className={'sa-table-action-flex'}>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.RESEND")}>
                            <FeatherIcon
                              onClick={() => resendVerification(user)} className={"table-action"}
                              icon={"upload"}/></div>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                               title={t("title.ALERT_LOGS")}><FeatherIcon
                            onClick={() => {
                              setLogUser(user);
                              setAlertLogVisible(true)
                            }} className={"table-action"}
                            icon={"activity"}/></div>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.LOGS")}
                               onClick={() => {
                                 setLogUser(user);
                                 setPage(1);
                                 setUserLogVisible(true)
                               }}><FeatherIcon className={"table-action"}
                                               icon={"clock"}/></div>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.ALERT")}>
                            <FarmAlertUser
                              userId={user.id}/></div>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.EDIT")}>
                            <FeatherIcon onClick={() => {
                              setIsUpdateAvailable(true);
                              setIsModalVisible(true);
                              Object.keys(errors).forEach(k => errors[k] = "");
                              values.lastName = user.lastName;
                              values.mobile = user.mobile;
                              values.language = user.language;
                              values.email = user.email;
                              values.status = user.status;
                              values.id = user.id;
                              values.index = index;
                              setFormErr("");
                            }} className="table-action" icon={"edit"}/></div>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.DELETE")}>
                            <FeatherIcon
                              onClick={() => handleDeleteFarmUser(user.id)}
                              className="text-red" icon={"trash-2"}/></div>
                        </div>
                      </td>
                    }
                  </tr>
                ))}
                </tbody>
              </table>
              {userList && userList.length === 0 && (
                <div className={"empty-results"}>
                  <FeatherIcon icon="info"/>
                  <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_USERS_PLEASE_ADD")}</div>
                </div>)
              }
              <div hidden={!userLogVisible}>
                <table className="table table-borderless table-user">
                  <thead>
                  <tr>
                    <th className="tw-190" scope="col">{t("title.ACTION")}</th>
                    <th className="tw-190" scope="col">{t("title.ACTION_AT")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {userLogList && userLogList.length > 0 && userLogList.map((user, index) => (
                    <tr key={index}>
                      <td>{formatDisplayEnumValue(user.action)}</td>
                      <td>{user.actionAt}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              <div hidden={!alertLogVisible}>
                <table className="table table-borderless table-user">
                  <thead>
                  <tr>
                    <th className="tw-190" scope="col">{t("title.ALERT_MEDIUM")}</th>
                    <th className="tw-190" scope="col">{t("title.ALERT_TYPE")}</th>
                    <th className="tw-190" scope="col">{t("title.MESSAGE")}</th>
                    <th className="tw-190" scope="col">{t("title.DATE")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {alertLog && alertLog.length > 0 && alertLog.sort(function (a, b) {
                    return new Date(b.creationDate) - new Date(a.creationDate);
                  }) && alertLog.map((alert, index) => (
                    <tr key={index}>
                      <td>{alert.alertMedium}</td>
                      <td>{alert.alertType}</td>
                      <td>{alert.message}</td>
                      <td>{alert.creationDate}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                {alertLog && alertLog.length === 0 && (
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ALERT_LOGS")}</div>
                  </div>)
                }
              </div>
            </div>
          </div> : <PlotUser plotId={selectedDropdownPlotId}/>}
        </ToggleLayout>
      </Container>

      <div onClick={formClick} className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{!isUpdateAvailable ? t("title.ADD_NEW_USER") : t("title.EDIT_USER")}</span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div hidden={isUpdateAvailable}>
              {InformativeMSG(t("info.USER_EMAIL_REGISTRATION_MESSAGE"))}
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.EMAIL")}</label>
                  <input onBlur={handleOnBlur} onChange={!isUpdateAvailable ? onChangeEmail : handleChange}
                         autoComplete={"off"}
                         value={values.email || ''} type="text"
                         className={`form-control ${errors.email && "warning-input"}`}
                         name="email"
                         placeholder={t("placeHolder.ENTER_EMAIL_ADDRESS")}/>
                  <ul hidden={suggestion.length === 0}
                      style={{
                        height: "150px",
                        overflowY: "auto",
                        fontSize: 10,
                        zIndex: 999,
                        position: "absolute",
                        width: "95%"
                      }}
                      className="list-group">{suggestion && suggestion.map(user => (
                    <li name={"suggestion"} style={{overflowWrap: "break-word"}} className="list-group-item"
                        value={user.id}
                        onClick={() => onSelectEmail(user)} key={user.id}>{user.email}</li>))}
                  </ul>

                  {errors.email && (<p className="warning-input-msg">{errors.email}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.NAME")}</label>
                  <input disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                         value={values.lastName || ''} type="text"
                         className={`form-control ${errors.lastName && "warning-input"}`}
                         name="lastName"
                         placeholder={t("placeHolder.ENTER_USER'S_NAME")}/>
                  {errors.lastName && (
                    <p className="warning-input-msg ">{errors.lastName}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.PHONE_NUMBER")}</label>
                  <input disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                         value={values.mobile || ''} type="text"
                         className={`form-control ${errors.mobile && "warning-input"}`}
                         name="mobile"
                         placeholder={t("placeHolder.ENTER_USER'S_MOBILE_NO")}/>
                  {errors.mobile && (<p className="warning-input-msg">{errors.mobile}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="exampleFormControlSelect1">{t("title.LANGUAGE")}</label>
                  <select disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                          value={values.language || ''}
                          className={`form-control ${errors.language && "warning-input"}`}
                          id="exampleFormControlSelect1" name="language">
                    <option hidden value={""}>{t("placeHolder.SELECT_USER_LANGUAGE")}</option>
                    <option value="TAMIL">{t("title.TAMIL")}</option>
                    <option value="SINHALA">{t("title.SINHALA")}</option>
                    <option value="ENGLISH">{t("title.ENGLISH")}</option>
                  </select>
                  {errors.language && (<p className="warning-input-msg">{errors.language}</p>)}
                </div>
              </div>
              {!isUpdateAvailable ?
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{t("title.ROLE")}</label>
                    <select disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                            value={values.userRoleId || ''}
                            className={`form-control ${errors.userRoleId && "warning-input"}`}
                            id="exampleFormControlSelect1" name="userRoleId">
                      <option hidden value={""}>{t("placeHolder.SELECT_USER_ROLE")}</option>
                      <option value="1000">{t("title.FARM_USER")}</option>
                      <option value="2000">{t("title.AGRO_INSPECTOR")}</option>
                      <option value="1001">{t("title.FARM_OWNER")}</option>
                      <option value="1002">{t("title.DIRECTOR")}</option>
                    </select>
                    {errors.userRoleId && (
                      <p className="warning-input-msg">{errors.userRoleId}</p>)}
                  </div>
                </div>
                :
                <div className="col-md-6">
                  <div className="m-b-16">
                    <label htmlFor="exampleFormControlSelect1">{t("title.STATUS")}</label>
                    <select disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                            value={values.status || ''}
                            className={`form-control ${errors.status && "warning-input"}`}
                            id="exampleFormControlSelect1" name="status">
                      <option hidden value={""}>{t("placeHolder.SELECT_USER_STATUS")}</option>
                      <option>{t("title.ACTIVE")}</option>
                      <option>{t("title.INACTIVE")}</option>
                    </select>
                    {errors.status && (
                      <p className="warning-input-msg">{errors.status}</p>)}
                  </div>
                </div>
              }
            </div>


            {!isUpdateAvailable && !selectedUser &&
              <div className={"advance-m table-container-toggle mb-2 " + (isTable ? "" : "table-toggle-hide")}>
                <hr className="table-toggle-line"/>
                <div className="table-toggle-heading">{t("title.ADVANCE")}</div>
                <div onClick={() => {
                  setTable(!isTable)
                  setValue({isTable: !isTable})
                }} className="table-dropdown-icon"><i
                  className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
              </div>}

            <div className="sa-popup-content" hidden={!isTable}>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.USERNAME")}</label>
                  <input type="text" value={values.username || ''} onChange={handleChange}
                         onBlur={handleOnBlur} autoComplete="off"
                         className={errors.username ? "form-control warning-input" : "form-control"}
                         name="username" placeholder={t('placeHolder.ENTER_YOUR_USERNAME')}/>
                  {errors.username && (<p className="warning-input-msg">{errors.username}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-6">
                  <label htmlFor="pwd">{t("title.PASSWORD")}</label>
                  <input type={!showText ? "password" : "text"} value={values.password || ''}
                         onChange={handleChange} autoComplete="off"
                         onBlur={handleOnBlur}
                         className={errors.password ? "form-control warning-input" : "form-control"}
                         name="password" placeholder="Enter your password"/>
                  <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                    className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                  {errors.password && (<p className="warning-input-msg">{errors.password}</p>)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-6">
                  <label htmlFor="pwd">{t("title.CONFIRM_PASSWORD")}</label>
                  <input type={!showText ? "password" : "text"} value={values.confirmPassword || ''}
                         onBlur={handleOnBlur} autoComplete="off"
                         onChange={handleChange}
                         className={errors.confirmPassword ? "form-control warning-input" : "form-control"}
                         name="confirmPassword" placeholder={t("placeHolder.ENTER_YOUR_PASSWORD")}/>
                  <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                    className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                  {errors.confirmPassword && (<p className="warning-input-msg">{errors.confirmPassword}</p>)}
                  {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}
                </div>
              </div>

            </div>
            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={resetFormData}>{t("button.CANCEL")}
              </button>
              <button className="sa-popup-secondary-btn-style">
                {!isUpdateAvailable ? t("button.ADD") : t("button.UPDATE")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};
export default FarmUser
