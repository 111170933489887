import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import * as _ from "underscore";
import axios from "axios";
import {hasPermission,} from "../../../utils/Authentication";
import {toast} from "react-toastify";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import Graph from "react-graph-vis";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import {formatDisplayEnumValue, toCapsFirst} from "../../../utils/Utils";
import {getFarmActuators, getFarmMqtt} from "../../../actions/farm";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";
import {onValue, ref} from "firebase/database";
import {database} from "../../../utils/firebaseConfig";


const ValveMap = (props) => {
  const {t, i18n} = useTranslation();

  const [isModelVisible, setIsModalVisible] = useState(false);
  const [valveCode, setValveCode] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [errors, setError] = useState(null);
  const [updateValve, setUpdateValve] = useState(false);
  const dispatch = useDispatch();
  const [loadMore, setLoadMore] = useState(true);
  const [dateRange, setDataRange] = useState(null);
  const [valveHistory, setHistory] = useState([]);
  const [isTable, setTable] = useState(false);
  const [update, setUpdate] = useState(false);

  const farmMqtt = useSelector(state => {
    return state.farmList.farmMqttStatus;
  });

  const farmActuators = useSelector(state => {
    return state.farmList.farmActuators
  });

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + '/force' + '/history' + '?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setHistory(response.data.content);
        setLoadMore(false);
      })
      .catch(function (error) {
        setLoadMore(false);
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [loadMore]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + '/valve')
      .then(response => {
        dispatch(getFarmActuators(response.data.content));
        dispatch(toggleLoader(false));
      })
      .catch(error => console.log(`Error ${error}`))
  }, []);


  useEffect(() => {
    if (!farmActuators || farmActuators.length === 0) {
      setGraphData({});
      setUpdateValve(false);
      return;
    }
    setUpdateValve(true);
  }, [farmActuators]);

  useEffect(() => {
    if (!props.farmId) {
      return
    }
    const query = ref(database, 'valves/' + props.farmId + '/');

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        const arr = Object.values(data);
        dispatch(getFarmActuators(arr));
      }
    });
  }, [props.farmId]);


  useEffect(() => {
    if (!updateValve) {
      return
    }
    let node = [];
    let edges = [];
    let valves = _.pluck(farmActuators, 'code');
    valves.forEach(valve => {
      const actuator = _.findWhere(farmActuators, {'code': valve});
      const color = actuator.synchronized ? (actuator.open ? 'green' : actuator.wake ? "orange" : 'red') : 'yellow';

      node.push({id: valve, label: valve, color: color});
      if (valve.length > 1) {
        if (valves.includes(valve.substring(0, valve.length - 2))) {
          edges.push({from: valve.substring(0, valve.length - 2), to: valve});
        }
      }
    });

    graphData.nodes = _.uniq(node, "id");
    ;
    graphData.edges = edges;
    setGraphData({...graphData});
    setUpdateValve(false);
  }, [updateValve]);


  function updateValveStatus() {
    let status = "";
    if (document.getElementById('open').checked) {
      status = "ON"
    } else {
      status = "OFF"
    }

    let valvesId = _.findWhere(farmActuators, {'code': valveCode[0]}).id;


    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/valve/' + valvesId + '/force/' + status)
      .then(res => {
        setIsModalVisible(false);
        setUpdate(true)
        if (document.getElementById('open').checked) {
          toast.success(t("successMsg.VALVE_OPENED_SUCCESSFULLY"));
        } else {
          toast.success(t("successMsg.VALVE_CLOSED_SUCCESSFULLY"));
        }
        let updatedIndex = farmActuators.findIndex(valve => valve.id === valvesId);
        farmActuators[updatedIndex] = res.data.content;
        setUpdateValve(true);
        setLoadMore(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
        }
      })
      .finally(() => {
        document.getElementById('open').checked = false;
        document.getElementById('close').checked = false;
        dispatch(toggleLoader(false));
        setUpdate(false)
      });
  }

  function onCancel() {
    setIsModalVisible(null);
    setUpdate(null)
    // document.getElementById('open').checked = false;
    // document.getElementById('close').checked = false;
  }

  const options = {
    layout: {
      randomSeed: undefined,
      improvedLayout: false,
      clusterThreshold: 150,
      hierarchical: {
        enabled: true,
        levelSeparation: 150,
        nodeSpacing: 100,
        treeSpacing: 200,
        blockShifting: false,
        edgeMinimization: false,
        parentCentralization: false,
        direction: 'LR',
        sortMethod: 'directed',
      }
    },
    autoResize: true,
    height: "100%",
    nodes: {
      fixed: true,
      font: '12px arial Black',
      scaling: {
        label: true
      },
      shadow: true,
      shape: 'box',
      size: 25,
    }

  };

  const events = {
    select: function (event) {
      var {nodes, edges} = event;
      if (nodes.length > 0) {
        valveCode.pop();
        valveCode.push(nodes[0]);
        setUpdateValve(valveCode);
        setIsModalVisible(true);
        setUpdate(true)
        setError(null);
        if (_.findWhere(farmActuators, {'code': nodes[0]}).open) {
          document.getElementById("open").checked = true
        } else {
          document.getElementById("close").checked = true
        }

      }
    }
  };

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
    setLoadMore(true);

  }

  useEffect(() => {
    if (!farmMqtt) {
      return
    }
    if (farmActuators.length > 0) {
      let index = farmActuators.findIndex(actuator => actuator.id === farmMqtt.id);
      farmActuators[index] = farmMqtt;
      setUpdateValve(true);
    }
    dispatch(getFarmMqtt(null));
  }, [farmMqtt]);

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("tableValveChangeHistory");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);

  const legends = [
    { color: 'green', label: 'Open'},
    { color: 'red', label: 'Close'},
    { color: 'yellow', label: 'Requested'},
    { color: 'orange', label: 'Wake'},
  ];

  return (

    <div className={"ValveMap"}>
      {/*<div className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>*/}
      {/*    <hr className="table-toggle-line"/>*/}
      {/*    <div className="table-toggle-heading">Valve Changes History</div>*/}
      {/*    <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i*/}
      {/*        className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>*/}
      {/*</div>*/}


      {farmActuators && farmActuators.length > 0 && graphData.nodes &&
        <div>

          <div className={"tree-comp-container"}>
            <div className={"TreeComp"} id="treeWrapper" style={{width: '100%', height: '20em'}}>
              {!update && <Graph
                graph={{...graphData}}
                options={options}
                events={events}
              />}
            </div>
            <div className="legends-container">
              {legends.map((legend, index) => (
                <div key={index} className="legend-item">
                  <div className="legend-color" style={{ backgroundColor: legend.color }} />
                  <div className="legend-label">{legend.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      {hasPermission("WRITE") &&
        <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
          <div className="sa-popup">
            <div className="sa-modal-box-style">
              <div className="sa-popup-header">
                <span className={'sa-model-heading'}>{t("title.CHANGE_VALVE_STATUS_OF")}{valveCode[0]}:</span>
                <div className="sa-popup-close-icon" onClick={() => onCancel()}>
                  <FeatherIcon
                    className={"sa-modal-close-icon"} icon={"x"}/>
                </div>
              </div>
              <div className="sa-popup-content">
                <div className="col-md-12">
                  <div className="form-group m-b-16">
                    <div className={"row"}>
                      <div className="col-md-6">
                        <div className={"vm-modal-title"}>{t("title.VALVE_STATUS")}</div>
                      </div>
                      <div className="col-md-6">
                        <div className={"vm-modal-title2"}>
                          <label className={""}>{t("title.OPEN")} :</label>
                          <label className="container-vm">
                            <input id={"open"} onClick={() => {
                              document.getElementById('close').checked = false
                            }} type="radio" name="radio"/>
                            <span className="checkmark"></span>
                          </label>
                          {/*<input id={"open"} onClick={() => {document.getElementById('close').checked = false}} type={"radio"}/>*/}
                        </div>
                        <div className={"vm-modal-title3"}>
                          <label className={""}>{t("title.CLOSE")} :</label>
                          <label className="container-vm">
                            <input id={"close"} onClick={() => {
                              document.getElementById('open').checked = false
                            }} type="radio" name="radio"/>
                            <span className="checkmark"></span>
                          </label>
                          {/*<input id={"close"} onClick={() => {document.getElementById('open').checked = false}} type={"radio"}/>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sa-popup-btn">
                <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        onClick={onCancel}>{t("button.CANCEL")}
                </button>
                <button className="sa-popup-secondary-btn-style" onClick={updateValveStatus}>
                  {t("button.UPDATE")}
                </button>
                {errors && (<div className={"login-warning-msg"}>{errors}</div>)}
              </div>
            </div>
          </div>

        </div>
      }

      <div className={"tree-comp-title"}>
        <hr className="tree-comp-title-line"/>
        <div className="table-toggle-heading">{t("title.VALVE_CHANGE_HISTORY")}</div>
      </div>
      <div id={"tableValveChangeHistory"} className={"table-container table-bg-table"}>
        <div className={"sa-table-daterange-hide sa-table-float-left"} style={{marginBottom: 16}}>
          <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                           oneTap={false} onOk={(e) => onCheck(e)}
                           showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                           ranges={[{
                             label: 'Today',
                             value: [new Date(), new Date()]
                           }, {
                             label: 'Yesterday',
                             value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                           }, {
                             label: 'Last 7 days',
                             value: [dateFns.subDays(new Date(), 6), new Date()]
                           }, {
                             label: 'Last 30 days',
                             value: [dateFns.subDays(new Date(), 30), new Date()]
                           }]}
          />
        </div>
        <div className={"sa-table-container"}>
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.USER")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.DATE_AND_TIME")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.ACTION")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.VALVE_CODE")}</th>
            </tr>
            </thead>
            <tbody>
            {valveHistory.length > 0 && (valveHistory.map((history, index) =>
              <tr key={index}>
                <td className={'sa-table-data'}>{history.user ? history.user.username : 'Unknown'}</td>
                <td className={'sa-table-data'}>{toBrowserTimeZone(history.actionAt)}</td>
                <td className={'sa-table-data'}>
                  {formatDisplayEnumValue(history.action)}
                </td>
                <td className={'sa-table-data'}>{history.valveCode}</td>
              </tr>
            ))}
            </tbody>
          </table>
          {valveHistory.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_FORCE_VALVE_UPDATE_HISTORIES")}</div>
            </div>)
          }
        </div>
      </div>


    </div>

  )
};
export default ValveMap;
