import React, {useEffect, useState} from 'react';
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as _ from "underscore";
import {addDays, getIcon, toCapsFirst} from "../../utils/Utils";
import {isEmpty} from "../../shared/utils/utils";
import {getUserId} from "../../shared/utils/SharedAuthentication";


export default function CashFlowStages(props) {


  const dispatch = useDispatch();
  const {farmId} = useParams();

  const [plotData, setPlotData] = useState([]);
  const [kitProperties, setKitProperties] = useState([]);
  const [kitPropertiesData, setKitPropertiesData] = useState([]);
  const [scoutDiseases, setScoutDiseases] = useState([]);
  const [cropAgronomyData, setCropAgronomyData] = useState({});


  useEffect(() => {
    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(res => {
        console.log(res.data.content);
        setPlotData(res.data.content);
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => dispatch(toggleLoader(false)));
  }, [farmId]);

  useEffect(() => {
    if (plotData.length === 0 || !plotData[0].kitId || kitProperties.length === 0 || cropAgronomyData?.stages?.length === 0 || isEmpty(cropAgronomyData)) {
      console.log("no")
      return
    }
    console.log(cropAgronomyData)
    dispatch(toggleLoader(true))
    // for (let i =0; i<plotData.length; i++){
    let getPromiseList = [];
    let dateDuration = 0
    let startDate = props.startDate
    for (let y = 0; y < cropAgronomyData.stages.length; y++) {
      startDate = addDays(startDate, dateDuration)
      dateDuration = dateDuration + cropAgronomyData.stages[y].duration
      for (let i = 0; i < kitProperties.length; i++) {
        getPromiseList.push(axios.get(process.env.REACT_APP_HOST + `/core/kit/` + plotData[0].kitId + `/graph-kit-history/` + kitProperties[i].number + `/summary?from=` + startDate + `&to=` + addDays(props.startDate, dateDuration) + `&aggregation=MAX&granularity=DAY`));
      }
    }
    console.log(getPromiseList)

    dispatch(toggleLoader(true));
    Promise.all(getPromiseList).then(res => {
      console.log("aasd")
      let data = []

      console.log(res)
      let c = 0
      let duration = 0
      let date = props.startDate
      for (let y = 0; y < cropAgronomyData.stages.length; y++) {
        let values = {}
        values.startDate = addDays(date, duration)
        duration = duration + cropAgronomyData.stages[y].duration
        values.cropAgronomy = cropAgronomyData.stages[y]
        console.log(cropAgronomyData.stages[y].duration)
        values.duration = duration
        values.dataSet = []
        for (let i = 0; i < kitProperties.length; i++) {
          // values.data = res[i].data.content
          let dataLocal = {}
          dataLocal.kitProperties = {...kitProperties[i]}
          dataLocal.kitProperties.data = res[i + c].data.content.valueY[0]
          dataLocal.kitProperties.startDate = addDays(props.startDate, duration)
          values.dataSet.push(dataLocal)
          // console.log(kitProperties)
          // console.log(i)
          console.log(i + c)
        }
        c = values.dataSet.length + c;
        console.log(data)
        data.push(values)
      }
      console.log(data)
      setKitPropertiesData(data);
      dispatch(toggleLoader(false));
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        // setFormErr(error.response.data.message);

      }
      dispatch(toggleLoader(false));

    }).finally(() => dispatch(toggleLoader(false)))


  }, [kitProperties, cropAgronomyData])

  console.log(kitPropertiesData)

  useEffect(() => {
    if (cropAgronomyData?.stages?.length === 0 || isEmpty(cropAgronomyData)) {
      return
    }

    let getPromiseList = [];
    let dateDuration = 0
    let startDate = props.startDate
    dispatch(toggleLoader(true));
    for (let y = 0; y < cropAgronomyData.stages.length; y++) {
      startDate = addDays(startDate, dateDuration)
      dateDuration = dateDuration + cropAgronomyData.stages[y].duration
      getPromiseList.push(axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/scout-report' + "?from=" + startDate + "&to=" + addDays(props.startDate, dateDuration)));

    }
    dispatch(toggleLoader(true));
    Promise.all(getPromiseList).then(res => {
      let data = []
      for (let y = 0; y < cropAgronomyData.stages.length; y++) {
        let values = {}
        values.diseases = res[y].data.content
        data.push(values)
      }
      setScoutDiseases(data)
      dispatch(toggleLoader(false))
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        // setFormErr(error.response.data.message);

      }
      dispatch(toggleLoader(false));

    }).finally(() => dispatch(toggleLoader(false)))

  }, [cropAgronomyData]);


  console.log(scoutDiseases)

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`)
      .then((res) => {
        let data = _.find(res.data.content, function (e) {
          return e.id === "62e8cba76a9ce259f411fa91";
        });
        setCropAgronomyData(data);
        console.log(props.cropAgronomyId)
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);


  useEffect(() => {
    if (plotData.length === 0 || !plotData[0].kitId) {
      return;
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + plotData[0].kitId)
      .then(res => {
        setKitProperties(res.data.content.properties);
        dispatch(toggleLoader(false))
      }).catch(error => {
        // if (error.response && error.response.status === 422) {
        //   toast.error(error.response.data.message);
        // }

      }
    ).finally(() => dispatch(toggleLoader(false)));

  }, [plotData]);
  return (
    <div hidden={kitPropertiesData.length === 0}>
      <p className={"crop-hading mb-3"}>Parameters maintained at each stage</p>
      {kitPropertiesData.length > 0 && kitPropertiesData.map((data, index) => (
        <div key={index} hidden={new Date() < new Date(data.startDate)}>
          <p className={"crop-hading text-left mt-5"}>{`0${index + 1}. ${toCapsFirst(data.cropAgronomy.name)}`}</p>

          <div className={"row"}>
            {data.dataSet.map((value, num) => (<div key={"sensor" + num} className={"col-sm-3"}>
              <div className={"sensor-details "}>
                {/*<div className="sensor-icon sensor-icon-size-40 padding-top-0"><FeatherIcon*/}
                {/*    icon="clipboard"/>*/}
                {/*</div>*/}
                {getIcon(value.kitProperties.code)}
                <div className="sensor-data sensor-data-p">
                  <div
                    className="crop-view-card-label">{value.kitProperties.data && value.kitProperties.data.toFixed(3)}</div>
                  <div className="crop-view-card-value">{value.kitProperties.displayName}</div>
                </div>
              </div>
            </div>))}
          </div>

          {scoutDiseases[index].diseases.length > 0 && <div className={"mt-5"}>
            <p className={"crop-hading font-14 text-left"}>Diseases at Seeding Stage</p>
            <ul className={"pl-3"}>
              {scoutDiseases && scoutDiseases[index].diseases.length > 0 && scoutDiseases[index].diseases.map((disease, key) => (
                <li key={key + "disease"} className={""}>{disease.note}</li>))}
            </ul>
          </div>}
          <div className={"dotted-divider mt-3"}/>
        </div>))}
    </div>
  );
}

