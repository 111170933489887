import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateSenzagroVisionKitForm} from "../../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {editAddIcon} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {getPlotList, getSelectedPlot} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const SenzagroVisionKitSetting = (props) => {
  const {t, i18n} = useTranslation();
  const {farmId} = useParams();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isDeleteKit, setIsDeleteKit] = useState(false);
  const dispatch = useDispatch();
  const [isEditKit, setIsEditKit] = useState(null);
  const [error, setError] = useState(null);
  const [kit, setKit] = useState(null);
  const [refresh, setRefresh] = useState(false);


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    initForm,
    handleOnBlur,
  } = FormHandler(submitKit, validateSenzagroVisionKitForm);

  let selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  useEffect(() => {
      if (!props.plotId) {
        return;
      }

      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId)
        .then(function (response) {

          setIsEditKit(false)
          dispatch(getSelectedPlot(response.data.content));
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
  }, [props.plotId])

  function deleteKit() {
    setIsDeleteKit(true);
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOR_SURE_YOU_WANT_DELETE_THIS_KIT"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_KIT_FROM_THE_PLOT")
    }));
  }

  function editValve(value) {
    initForm(selectedPlot);
    setIsEditKit(true);
  }

  function submitKit() {
    dispatch(toggleLoader(true));
    if (isEditKit) {
        axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId, {
          senzagroVisionKitId: values.senzagroVisionKitId,
          name: selectedPlot.name,
          farmId: selectedPlot.farmId
        })
          .then(function (response) {
            if (!selectedPlot.senzagroVisionKitId) {
              toast.success("Kit Added Successfully")
            } else {
              toast.success("Kit Successfully Updated")
            }
            selectedPlot.senzagroVisionKitId = response.data.content.senzagroVisionKitId;
            setIsEditKit(false);
            setError(null);
            setRefresh(true)
          })
          .catch(function (error) {
            if (error.response && error.response.status === 422) {
              setError(error.response.data.message);
              // toast.error(error.response.data.message);
            }

          })
          .finally(() => {
            dispatch(toggleLoader(false));
            setRefresh(false)
          })
    }
  }

  function cancelForm(event) {
    event.preventDefault();
    setIsEditKit(false);
    setError(null);
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }

    if (isDeleteKit) {
      dispatch(toggleLoader(true));
        axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/kit/' + selectedPlot.senzagroVisionKitId)
          .then(res => {
            if (res.status === 200) {
              setIsDeleteKit(false);
              toast.success("Kit successfully Deleted");
              setKit(null);
              selectedPlot.senzagroVisionKitId = null;
              getSelectedPlot(selectedPlot);
              forceUpdate();
              setRefresh(true)
            }
          })
          .finally(() => {
            dispatch(toggleLoader(false));
            setRefresh(false)
          })
    }
  }, [confirmationDialog]);

  useEffect(() => {

    if (!refresh) {
      return;
    }
    dispatch(toggleLoader(true));

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(res => {
        dispatch(getPlotList(res.data.content));
        dispatch(toggleLoader(false));
      });
  }, [refresh]);

  return (
    <div className="">
      <Container>
        {!isEditKit ?
          <div>
              <>
                {!selectedPlot.senzagroVisionKitId ? (
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
                  </div>
                ) : (
                  <div className={""}>
                    {selectedPlot.senzagroVisionKitId && (
                      <div>
                        <div className={"farm-info-title"}>{t("title.SENZAGRO_VISION_KIT_ID")}</div>
                        <div className={"farm-info-data"}>{selectedPlot.senzagroVisionKitId}</div>
                      </div>
                    )}
                  </div>
                )}
              </>
          </div>

          :
          <form onSubmit={handleSubmit} noValidate>
            <div className={"border-0"}>
              <div className={"max-w-320 m-b-30"}>
                <div className={"farm-info-title"}>{t("title.SENZAGRO_VISION_KIT_ID")}</div>
                <input type={"text"} name="senzagroVisionKitId"
                       value={values.senzagroVisionKitId ? values.senzagroVisionKitId : ""} onBlur={handleOnBlur} onChange={handleChange}
                       className={`form-control ${errors.senzagroVisionKitId && "warning-input"}`}
                       placeholder={t("placeHolder.ENTER_KIT_ID")}>
                </input>
                {errors.senzagroVisionKitId && (
                  <p className="warning-input-msg">{errors.senzagroVisionKitId}</p>)}
              </div>
            </div>
            <div className="pull-right m-t-32 hide-768">
              {error && (<div className={"login-warning-msg"}>{error}</div>)}
              <button className="btn btn-sa-text w-140p m-r-8" onClick={cancelForm}>{t("button.CANCEL")}</button>
              <button className="btn btn-sa-secondary w-140p">{t("button.SAVE")}</button>
            </div>
            <div className="show-768 m-t-16">
              <button className="btn btn-sa-secondary w-100pe">{t("button.SAVE")}</button>
              <button className="btn btn-sa-text w-100pe m-t-5" onClick={cancelForm}>{t("button.CANCEL")}</button>
              {error && (<div className={"login-warning-msg"}>{error}</div>)}
            </div>
          </form>
        }

        {hasPermission("WRITE") &&
          <div> 
            <div className="pull-right hide-768 m-r_-19">
              <button
                id={'kitSettingDelete'}
                className="btn btn-sa-icon"
                hidden={!selectedPlot.senzagroVisionKitId || isEditKit}
                onClick={deleteKit}
              >
                <FeatherIcon className={"text-red icon-setting"} icon={"trash-2"}/>
                <span className={'hide-425'}>Delete</span>
              </button>
              <button
                id={"kitSettingAddOrEdit"}
                hidden={isEditKit}
                onClick={editValve}
                className={'white-background'}
              >
                {editAddIcon(selectedPlot.senzagroVisionKitId ? t("button.EDIT") : t("button.ADD"))}
              </button>
            </div>
          </div>

        }

      </Container>


    </div>
  )
};

export default SenzagroVisionKitSetting