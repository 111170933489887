import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateKitForm} from "../../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {editAddIcon} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {VALVES_TYPE} from "../../../utils/Enum";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId, isSuper} from "../../../shared/utils/SharedAuthentication";
import * as _ from "underscore";

const ValveSetting = (props) => {
  const {t, i18n} = useTranslation();
  const [irrigation, setIrrigation] = useState({});
  const [isEditValve, setIsEditValve] = useState(false);
  const dispatch = useDispatch();
  const [isDeleteValve, setIsDeleteValve] = useState(false);
  const [error, setError] = useState(null);
  const [actuator, setActuator] = useState([]);
  const [loadValves, setLoadValves] = useState(true);
  const {farmId} = useParams();

  const {values, errors, handleChange, handleSubmit, initForm, handleOnBlur} =
    FormHandler(submitKit, validateKitForm);

  const confirmationDialog = useSelector((state) => {
    return state.setting.confirmationDialog;
  });

  function deleteValve() {
    setIsDeleteValve(true);
    dispatch(
      toggleConfirmationDialog({
        isVisible: true,
        confirmationHeading: t(
          "confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VALVE"
        ),
        confirmationDescription: t(
          "confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_VALVE_FROM_THE_PLOT"
        ),
      })
    );
  }

  function submitKit() {
    dispatch(toggleLoader(true));
    if (!isEmpty(irrigation)) {
      axios
        .put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + "/irrigation", {valveCode: values.valveCode})
        .then((res) => {
          setIsEditValve(false);
          setIrrigation(values);
          setLoadValves(true);
          toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
          setError(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    } else {
      axios
        .post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + "/irrigation", {valveCode: values.valveCode})
        .then((res) => {
          setIsEditValve(false);
          setIrrigation(values);
          setLoadValves(true);
          if (irrigation.valveCode) {
            toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
          } else {
            toast.success(t("successMsg.VALVE_CODE_ADDED_SUCCESSFULLY"));
          }
          setError(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    }
  }

  function editValve() {
    setIsEditValve(true);
    initForm(irrigation);
  }

  function cancelForm(event) {
    event.preventDefault();
    setIsEditValve(false);
    setError(null);
  }

  useEffect(() => {
    if (!props.plotId) {
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + "/irrigation")
      .then(function (response) {
        setIrrigation(response.data.content ? response.data.content : {});
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.plotId]);

  useEffect(() => {
    if (!loadValves) {
      return;
    }
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + "/valve" + "?category=" + VALVES_TYPE.UN_ASSIGNED)
      .then((response) => {
        setActuator(response.data.content);
        setLoadValves(false);
      })
      .catch((error) => console.log(`Error ${error}`))
  }, [loadValves, props.plotId]);

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    if (isDeleteValve) {
      axios
        .delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + "/irrigation/valve/" + irrigation.valveCode)
        .then((res) => {
          if (res.status === 200) {
            irrigation.valveCode = null;
            setIsDeleteValve(false);
            setIrrigation(irrigation);
            setLoadValves(true);
            toast.success(t("successMsg.VALVE_SUCCESSFULLY_DELETED"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    }
  }, [confirmationDialog]);

  function getKitId(code) {
    let valve = _.findWhere(actuator, {code: code});
    console.log(actuator);
    console.log(code);
    console.log(valve);
    if (valve) {
      return valve.kitId;
    }
  }

  function actuatorOptions(actuator) {
    const filteredActuator = [];

    actuator.map((item) => {
      if (actuator.filter(item2 => (item.code !== item2.code && item2.code.startsWith(item.code + '-'))).length === 0) {
        filteredActuator.push(item)
      }
    })
    return filteredActuator;
  }

  return (
    <Container>
      {!isEditValve ? (
        <div>
          {!irrigation.valveCode ? (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>
                {t("emptyMsg.VALUE_NOT_CONFIGURED")}
              </div>
            </div>
          ) : (
            <div className={""}>
              <div className={"farm-info-title"}>{t("title.CODE")}</div>
              <div className={"farm-info-data"}>{irrigation.valveCode}</div>
              {isSuper() &&
                <div className={"farm-info-data"}>{getKitId(irrigation.valveCode)}</div>
              }
            </div>
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit} noValidate>
          <div className="pull-right m-t-32 hide-768">
            <button
              className="btn btn-sa-text w-140p m-r-8"
              onClick={cancelForm}
            >
              {t("button.CANCEL")}
            </button>
            <button className="btn btn-sa-secondary w-140p">
              {t("button.SAVE")}
            </button>
          </div>
          <div className={"border-0"}>
            <div className={"max-w-320"}>
              <div className={"farm-info-title"}>{t("title.CODE")}</div>
              <select
                value={values.valveCode}
                className={`form-control`}
                onChange={handleChange}
                onBlur={handleOnBlur}
                id="exampleFormControlSelect1"
                name="valveCode"
              >
                <option hidden value={""}>
                  {t("instructions.SELECT_A_VALVE")}
                </option>
                {irrigation.valveCode && (
                  <option key={"asd"} value={irrigation.valveCode}>
                    {irrigation.valveCode}
                  </option>
                )}
                {actuator.length > 0 &&
                  actuatorOptions(actuator)
                    .filter(
                      (actuator) => actuator.type !== "BRANCH" && !actuator.open
                    )
                    .map((actuator) => (
                      <option key={actuator.id} value={actuator.code}>
                        {actuator.code}
                      </option>
                    ))}
                ;
              </select>
              {errors.valveCode && (
                <p className="warning-input-msg">{errors.valveCode}</p>
              )}
            </div>
          </div>
          {error && <div className={"login-warning-msg"}>{error}</div>}
          <div className="show-768 m-t-16">
            <button className="btn btn-sa-secondary w-100pe">
              {t("button.SAVE")}
            </button>
            <button
              className="btn btn-sa-text w-100pe m-t-5"
              onClick={cancelForm}
            >
              {t("button.CANCEL")}
            </button>
          </div>
        </form>
      )}

      {hasPermission("WRITE") && (
        <div className="pull-right hide-768 m-r_-19">
          <button
            className="btn btn-sa-icon"
            hidden={!irrigation.valveCode || isEditValve}
            onClick={deleteValve}
          >
            <FeatherIcon className={"text-red icon-in-btn"} icon={"trash-2"}/>{" "}
            {t("button.DELETE_VALVE")}
          </button>
          <button
            hidden={isEditValve}
            className={"white-background"}
            onClick={editValve}
          >
            {editAddIcon(
              irrigation.valveCode ? t("button.EDIT") : t("button.ADD")
            )}
          </button>
        </div>
      )}
      <div className="show-768 float-r m-b-9 m-r_-26">
        <button
          hidden={isEditValve}
          className={"white-background"}
          onClick={() => editValve("valve")}
        >
          {editAddIcon(
            irrigation.valveCode ? t("button.EDIT") : t("button.ADD")
          )}
        </button>
        <button
          className="btn btn-sa-icon"
          hidden={!irrigation.valveCode || isEditValve}
          onClick={deleteValve}
        >
          <FeatherIcon className={"text-red icon-setting"} icon={"trash-2"}/>{" "}
          <span className={"hide-425"}>{t("button.DELETE")}</span>
        </button>
      </div>
    </Container>
  );
};

export default ValveSetting;
