import React from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PlotTab = (props) => {
  const {t, i18n} = useTranslation();
  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  return (
    <div className="text-center tab-container d-flex">
      {selectedPlot.kitId && (
        <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/sensor'} activeClassName="plot-tog-active">
          <div id={'tabSensor'} className="plots-header-toggles"><FeatherIcon
            className={"icon-in-tab plot-tab-icon-color"} icon={"radio"}/><span
            className={"plot-tab"}> {t("title.SENSORS")}</span></div>
        </NavLink>
      )
      }
      <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/schedule'} activeClassName="plot-tog-active">
        <div id={'tabIrrigation'} className="plots-header-toggles"><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"clock"}/><span
          className={"plot-tab"}> {t("title.IRRIGATION")}</span></div>
      </NavLink>
      {!selectedPlot.kitId && (
        <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/sensor'} activeClassName="plot-tog-active">
          <div id={'tabSensor'} className="plots-header-toggles"><FeatherIcon
            className={"icon-in-tab plot-tab-icon-color"} icon={"radio"}/><span
            className={"plot-tab"}> {t("title.SENSORS")}</span></div>
        </NavLink>
      )
      }
      <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/crop'} activeClassName="plot-tog-active">
        <div id={'tabReport'} className="plots-header-toggles" ><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"feather"}/><span
          className={"plot-tab"}> {t("title.CROPS")}</span></div>
      </NavLink>
      <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/report'} activeClassName="plot-tog-active">
        <div id={'tabReport'} className="plots-header-toggles"><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"clipboard"}/><span
          className={"plot-tab"}> {t("title.REPORTS")}</span></div>
      </NavLink>
      {/* <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/reportHandler'} activeClassName="plot-tog-active"> */}
      {/* <div id={'tabReport'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab plot-tab-icon-color"} icon={"clipboard"}/><span className={"plot-tab"}> Reports</span></div> */}
      {/* </NavLink> */}
      {/*<NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/info'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabInfo'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"info"}/><span className={"mobile-hide"}> Plot Info</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/user'} activeClassName="plot-tog-active">*/}
      {/*    <div id={'tabUser'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"users"}/><span className={"mobile-hide"}> Users</span></div>*/}
      {/*</NavLink>*/}
      <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/config'} activeClassName="plot-tog-active">
        <div id={'tabSetting'} className="plots-header-toggles"><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"settings"}/><span
          className={"plot-tab"}> {t("title.SETTINGS")}</span></div>
      </NavLink>
      <NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/devices'} activeClassName="plot-tog-active">
        <div id={'tabDevices'} className="plots-header-toggles"><FeatherIcon
          className={"icon-in-tab plot-tab-icon-color"} icon={"anchor"}/><span
          className={"plot-tab"}>{t("title.DEVICES")}</span></div>
      </NavLink>
    </div>
  )
};


export default PlotTab
