import React, {useEffect, useState} from "react";
import * as _ from "underscore";
import FeatherIcon from "feather-icons-react";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import DatePicker from "react-datepicker";
import {isValidDate, validatePlotUrl} from "../../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateCropAddFarmer} from "../../../utils/FormValidationRules";
import {getFarmList} from "../../../actions/farm"
import {
  changeView,
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader,
} from "../../../shared/actions/setting";
import axios from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

import {UNITS, VIEW} from "../../../utils/Enum";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../../shared/utils/utils";
import {hasPermission} from "../../../utils/Authentication";
import {Typeahead} from "react-bootstrap-typeahead";
import {findWhere} from "underscore";
import {useLocation, useParams} from "react-router-dom";

const Crops = (props) => {
  const {t, i18n} = useTranslation();
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [cropList, setCropList] = useState([]);
  const [cropNameList, setCropNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState([]);
  const dispatch = useDispatch();
  const confirmationDialog = useSelector((state) => {
    return state.setting.confirmationDialog;
  });
  const farmList = useSelector(state => {
    return state.farmList.farmList
  });

  const {plotId} = useParams()
  const {pathname}  = useLocation()




  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    handleKeyDown,
    setValue,
    initForm,
    deleteErrors,
  } = FormHandler(cropAdd, validateCropAddFarmer);

  function cropAdd() {
    setIsLoading(true);
  }

  function handleOnchangeDate(dateValue, type) {
    let event = {target: {}};
    event.target.name = type;
    event.target.value = dateFormat(dateValue);
    handleChange(event);
  }

  useEffect(() => {
    dispatch(changeView(VIEW.FARM_LIST));
    // dispatch(getSelectedFarm({}));
    dispatch(toggleLoader(true));
    let mounted = true;
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm`)
      .then(res => {
        if (mounted) {
          dispatch(getFarmList([...res.data.content]));
          // dispatch(getSelectedPlot({}));
          // dispatch(getPlotList([]));
          dispatch(toggleLoader(false));
        }
      });

    return () => mounted = false;
  }, []);

  function handleDeleteTask(id) {
    dispatch(
      toggleConfirmationDialog({
        isVisible: true,
        confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CROP"),
        confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_CROP")
      })
    );
    setDeleteId(id);
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess || !deleteId) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios
      .delete(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/harvest/` +
        deleteId
      )
      .then((res) => {
        setCropList(cropList.filter((item) => item.id !== deleteId));
        toast.success("Crop deleted successfully");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
          setFormErr(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setDeleteId(null);
      });
  }, [confirmationDialog]);

  ///user/{user}/harvest

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/harvest?` +
        (props.type === "farmerId" ? "farmerId=" : "farmId=") +
        props.id
      )
      .then((res) => {
        let data = res.data.content.reverse()
        if (validatePlotUrl(pathname)) {
          data = data.filter(item => item.plotId === plotId)
        }
        setCropList(data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [isUpdate]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`
      )
      .then((res) => {

        setCropNameList(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach((key) => delete values[key]);
    deleteErrors(errors);
    setIsModalUpdateVisible(false);
    setFormErr(null);
    setSelectedCrop([])
  }

  function hasValidFarm() {
    let farms = _.intersection(props.data.farms, _.pluck(farmList, "id"));

    return farms.length > 0;
  }


  useEffect(() => {
    if (!isLoading) {
      return;
    }

    values[props.type] = props.id;
    values.plotId = plotId;
    dispatch(toggleLoader(true));
    if (!isModelUpdateVisible) {
      axios
        .post(
          process.env.REACT_APP_HOST + `/user/` + getUserId() + `/harvest`,
          values
        )
        .then((res) => {
          initForm({})
          setIsModalVisible(false);
          // setCropList((oldArray) => [...oldArray, res.data.content]);
          toast.success("Crop created successfully");
          setIsUpdate(!isUpdate);
          resetForm();
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
        });
    } else {
      axios
        .put(
          process.env.REACT_APP_HOST +
          `/user/` +
          getUserId() +
          `/harvest/` +
          values.id,
          values
        )
        .then((res) => {
         setIsModalVisible(false);
          setIsUpdate(!isUpdate);
          toast.success("Crop updated successfully");
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
          resetForm();
        });
    }
  }, [isLoading]);

  return (
    <div>
      <div className={"sa-table-flex justify-content-end pdf-fide"}>
        {!props.print && hasPermission("FMS.FARMER.CROPS", "ADD") && (
          <button
            className="sa-table-btn-secondary sa-table-float-right"
            onClick={() => setIsModalVisible(true)}
          >
            <FeatherIcon icon={"plus"} className={"sa-table-icon-size"}/>{" "}
            <span className={"sa-table-icon"}>{t("button.ADD")}</span>
          </button>
        )}
      </div>
      <div className="sa-table-container cash-flow-crop-table-calc-height mt-3">
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>
              {t("title.CROP_NAME")}
            </th>
            <th className={"sa-table-head-sticky"}>
              {t("title.PLANTED_DATE")}
            </th>
            <th className={"sa-table-head-sticky"}>
              {t("title.EXPECTED_HARVEST")}
            </th>
            <th className={"sa-table-head-sticky"}>
              {t("title.EXPECTED_HARVEST_AMOUNT")}
            </th>
            {!props.print && (
              <th className="sa-table-item-align sa-table-head-sticky">
              </th>
            )}
          </tr>
          </thead>
          <tbody>
          {cropList.length > 0 &&
            cropList.map((crop, index) => (
              <tr key={index}>
                <td className={'sa-table-data'}>
                  {_.findWhere(cropNameList, {id: crop.cropAgronomyId})
                    ? _.findWhere(cropNameList, {id: crop.cropAgronomyId})
                      .cropName
                    : "-"}
                </td>
                <td className={'sa-table-data'}>{crop.plantedDate}</td>
                <td className={'sa-table-data'}>{crop.expectedHarvestStartDate}</td>
                {/* <td>{crop.expectedHarvestEndDate}</td> */}
                <td className={'sa-table-data'}>
                  {crop.expectedHarvest + " " + (crop.unit ? crop.unit : "")}
                </td>

                {!props.print && (
                  <td className="sa-table-item-align">
                    <div className={'sa-table-action-flex'}>
                      <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title="View"
                      >
                        <FeatherIcon
                          onClick={() => {
                            props.view(crop, cropNameList)

                            // onViewClick(groupProp, index);
                          }}
                          className="table-action"
                          icon={"eye"}
                        />
                      </div>
                      {hasPermission("FMS.FARMER.CROPS", "EDIT") && <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title="Edit"
                      >
                        <FeatherIcon
                          onClick={() => {
                            setIsModalVisible(true);
                            setIsModalUpdateVisible(true);
                            initForm(crop);
                            setSelectedCrop(findWhere(cropNameList, {id: crop.cropAgronomyId}) ? [findWhere(cropNameList, {id: crop.cropAgronomyId})] : [])
                          }}
                          className="table-action"
                          icon={"edit"}
                        />
                      </div>}
                      {hasPermission("FMS.FARMER.CROPS", "DELETE") && <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <FeatherIcon
                          onClick={() => handleDeleteTask(crop.id)}
                          className="text-red"
                          icon={"trash-2"}
                        /> 
                      </div> }
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {cropList.length === 0 && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>
              {t("emptyMsg.THERE_ARE_NO_CROPS")}
            </div>
          </div>
        )}
        {isModelVisible && (
          <div className="sa-popup-bg ">
            <div className="sa-popup">
              <form
                onSubmit={handleSubmit}
                noValidate
                className={"sa-modal-box-style"}
              >
                <div className="sa-popup-header">
                  <span className={"sa-model-heading"}>
                    {isModelUpdateVisible ? t("title.EDIT_CROP") : t("title.ADD_CROP")}
                  </span>
                  <div
                    className="sa-popup-close-icon"
                    onClick={() => {
                      setIsModalVisible(false);
                      resetForm();
                    }}
                  >
                    <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
                  </div>
                </div>
                <div className="sa-popup-content">
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.CROP_NAME")}</label>
                      <Typeahead
                          id="basic-typeahead-single"
                          className={`${errors.cropAgronomyId && "warning-input br-25"}`}
                          labelKey={option => `${option.cropName}`}
                          onChange={(selected) => {
                            console.log(selected)
                            if (selected.length > 0) {
                              setValue({"cropAgronomyId": selected[0].id})
                              setSelectedCrop(selected)
                            } else {
                              setValue({"cropAgronomyId": ""})
                              setSelectedCrop([])
                            }
                          }}
                          options={cropNameList}
                          placeholder={t("placeHolder.SELECT_CROP_NAME")}
                          selected={selectedCrop}
                      />

                      {errors.cropAgronomyId && (
                        <p className="warning-input-msg ">
                          {errors.cropAgronomyId}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.PLANTED_DATE")}</label>
                      <br/>
                      <DatePicker
                        maxDate={new Date()}
                        disabledDate={(date) => date > new Date()}
                        className={`form-control ${
                          errors.plantedDate && "warning-input"
                        }`}
                        onChange={(date) =>
                          handleOnchangeDate(
                            isValidDate(date) ? date : new Date(),
                            "plantedDate"
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                          values.plantedDate && new Date(values.plantedDate)
                        }
                        name={"date"}
                        onBlur={handleOnBlur}
                        placeholderText={t("placeHolder.ENTER_PLANTED_DATE")}
                      />
                      {errors.plantedDate && (
                        <p className="warning-input-msg ">
                          {errors.plantedDate}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.EXPECTED_HARVEST_START_DATE")}</label>
                      <br/>
                      <DatePicker
                        minDate={values.plantedDate}
                        disabledDate={(date) => date < new Date()}
                        className={`form-control ${
                          errors.expectedHarvestStartDate && "warning-input"
                        }`}
                        onChange={(date) =>
                          handleOnchangeDate(
                            isValidDate(date) ? date : new Date(),
                            "expectedHarvestStartDate"
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                          values.expectedHarvestStartDate &&
                          new Date(values.expectedHarvestStartDate)
                        }
                        name={"expectedHarvestStartDate"}
                        onBlur={handleOnBlur}
                        placeholderText={t("placeHolder.ENTER_EXPECTED_HARVEST_START_DATE")}
                      />
                      {errors.expectedHarvestStartDate && (
                        <p className="warning-input-msg ">
                          {errors.expectedHarvestStartDate}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.EXPECTED_HARVEST_END_DATE")}</label>
                      <br/>
                      <DatePicker
                        minDate={values.plantedDate}
                        disabledDate={(date) => date < new Date()}
                        className={`form-control ${
                          errors.expectedHarvestEndDate && "warning-input"
                        }`}
                        onChange={(date) =>
                          handleOnchangeDate(
                            isValidDate(date) ? date : new Date(),
                            "expectedHarvestEndDate"
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                          values.expectedHarvestEndDate &&
                          new Date(values.expectedHarvestEndDate)
                        }
                        name={"expectedHarvestEndDate"}
                        onBlur={handleOnBlur}
                        placeholderText={t(
                          "placeHolder.ENTER_EXPECTED_HARVEST_END_DATE"
                        )}
                      />
                      {errors.expectedHarvestEndDate && (
                        <p className="warning-input-msg ">
                          {errors.expectedHarvestEndDate}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">
                        {" "}
                        {t("title.EXPECTED_HARVEST_AMOUNT")}
                      </label>
                      {/*<input type="number"*/}
                      {/*       onBlur={handleOnBlur} onChange={handleChange}*/}
                      {/*       onKeyDown={handleKeyDown}*/}
                      {/*       value={values.expectedHarvest || ''}*/}
                      {/*       className={`form-control ${errors.expectedHarvest && "warning-input"}`}*/}
                      {/*       name="expectedHarvest"*/}
                      {/*       min={0}*/}
                      {/*       placeholder="Enter Expected Harvest Amount"/>*/}

                      <div className={"row col-md-12"}>
                        <input
                          type="number"
                          className={`form-control col-md-7 add-yield-quantity ${
                            errors.expectedHarvest && "warning-input"
                          }`}
                          name="expectedHarvest"
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          value={values.expectedHarvest || ""}
                          min={0}
                          placeholder={t("placeHolder.ENTER_EXPECTED_HARVEST_AMOUNT")}
                        />
                        <select
                          className={`form-control col-md-4 add-yield-unit`}
                          name="unit"
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.unit || UNITS.Nuts}
                        >
                          <option value={UNITS.Nuts}>{UNITS.Nuts}</option>
                          <option value={UNITS.Kg}>{UNITS.Kg}</option>
                          <option value={UNITS.L}> {UNITS.L}</option>
                        </select>
                      </div>

                      {errors.expectedHarvest && (
                        <p className="warning-input-msg ">
                          {errors.expectedHarvest}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">
                        {t("title.AREA_IN_ACRE")}
                      </label>
                      <input
                        type="number"
                        className={`form-control`}
                        name="area"
                        onBlur={handleOnBlur}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={values.area || ""}
                        min={0}
                        placeholder={t("placeHolder.ENTER_AREA")}
                      />
                    </div>
                  </div>
                  <div>

                  </div>
                  {props.type === "farmerId" && props.data.farms && hasValidFarm() &&
                    <div className="col-md-6">
                      <div className="form-group m-b-16">
                        <label>{t("title.FARM_NAME")}</label>
                        <select
                          className={
                            errors.farms
                              ? "form-control warning-input"
                              : "form-control"
                          }
                          name="farms"
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.farms || ""}
                          placeholder={"Select Yield/Crop"}
                        >
                          {props.data.farms.length > 1 &&
                            <option value={""}>Select Farm</option>
                          }
                          {props.data.farms.length > 0 && props.data.farms.map((item, index) => (
                            <option value={item} key={index}>
                              {_.find(farmList, {id: item}) ? _.find(farmList, {id: item}).name : ''}
                            </option>
                          ))}
                        </select>

                        {errors.farms && (
                          <p className="warning-input-msg ">
                            {errors.farms}
                          </p>
                        )}
                      </div>
                    </div>
                  }

                </div>
                {formErr && (
                  <div className={"login-warning-msg"}>{formErr}</div>
                )}
                <div className="sa-popup-btn">
                  <button
                    id="btnCancel"
                    type={"button"}
                    className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                    onClick={() => {
                      setIsModalVisible(false);
                      resetForm();
                    }}
                  >
                    {t("button.CANCEL")}
                  </button>
                  <button
                    type="submit"
                    className="sa-popup-secondary-btn-style"
                  >
                    {!isModelUpdateVisible
                      ? t("button.ADD")
                      : t("button.UPDATE")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Crops;
