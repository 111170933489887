import React, {useEffect, useState} from "react";
import axios from "axios";
import {toggleLoader} from "../shared/actions/setting";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {getUserId} from "../shared/utils/SharedAuthentication";
import {formatDisplayEnumValue} from "../utils/Utils";

const IrrigationStatus = (props) => {
  const {t, i18n} = useTranslation();
  const [plotIrrigationStatus, setPlotIrrigationStatus] = useState({});


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + `/irrigation` + `/status`)
      .then(function (response) {
        if (response.data.content) {
          setPlotIrrigationStatus(response.data.content);
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, []);

  useEffect(() => {
    if (!props.mqttPlotStatus) {
      return
    }
    if (props.mqttPlotStatus.topic.includes(props.plotId)) {
      setPlotIrrigationStatus(props.mqttPlotStatus);
    }
  }, [props.mqttPlotStatus]);


  return (
    <div className="text-center mr-lg-auto mx-lg-auto">
      <div className="card-content-heading">{t("title.IRRIGATION_STATUS")}</div>
      {plotIrrigationStatus.status === "ON" &&
        <div className="card-content-status text-green w-124 m-rl-a"
             title={plotIrrigationStatus.current}>{formatDisplayEnumValue(plotIrrigationStatus.status)}</div>
      }
      {plotIrrigationStatus.status !== "ON" &&
        <div title={plotIrrigationStatus.description}
          className="card-content-status text-red w-124 m-rl-a">{formatDisplayEnumValue(plotIrrigationStatus.status ? plotIrrigationStatus.status : "NA")}</div>
      }
    </div>
  )
};

export default IrrigationStatus;
